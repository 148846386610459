import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeSadecv'
})
export class RemoveSadecvPipe implements PipeTransform {

  transform(value: string): string {
    if (typeof value !== 'string') {
      return '';
    }

    value = this.capitalizeFirstLetter(value.toLowerCase());
    value = value.replace(/[\s,]+/g, ' ');
    value = value.replace(/(,? )?[Ss]\.?\s?[Aa]\.?\s?[Pp]\.?\s?[Ii]\.?\s? [Dd][Ee] [Cc]\.?\s?[Vv]\.?\s?/g, '');
    value = value.replace(/(,? )?[Ss]\.?\s? [Dd][Ee] [Rr]\.?\s?[Ll]\.?\s? [Dd][Ee] [Cc]\.?\s?[Vv]\.?\s?/g, '');
    value = value.replace(/(,? )?[Ss]\.?[Dd][Ee][Rr]\.?[Ll]\.?[Dd][Ee][Cc][Vv]?/g, '');
    value = value.replace(/(,? )?[Ss]\.?\s?[Aa]\.?\s? [Dd][Ee] [Cc]\.?\s?[Vv]\.?\s?/g, '');
    value = value.replace(/(,? )?[Ss]\.?\s?[Cc]\.?\s? [Dd][Ee] [Rr]\.?\s?[Ss]\.?\s?/g, '');
    value = value.replace(/(,? )?[Ss]\.?\s? [Dd][Ee] [Rr]\.?\s?[Ll]\.?\s?/g, '');
    value = value.replace(/(,? )?[Ss]\.?\s?[Aa]\.?\s? [Cc]\.?\s?[Vv]\.?\s?/g, '');
    value = value.replace(/(,? )?[Ss]\.\s?[Aa]\.?/g, '');
    
    return value;
  }

  private capitalizeFirstLetter(str: string): string {
    return str
      .toLowerCase()
      .split(' ') // Divide la cadena en palabras
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitaliza la primera letra de cada palabra
      .join(' '); // Une las palabras nuevamente en una cadena
  }

}
