import { Component, Input, OnDestroy } from '@angular/core';
import { combineLatest, Subject, Subscription, takeUntil } from 'rxjs';
import { GasprecioService } from '../../../gasprecio/services/gasprecio.service';
import { SharedService } from '../../../gasprecio/services/shared.service';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import Highcharts from 'highcharts';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';
import moment from 'moment';

@Component({
  selector: 'app-chart-sales-hour',
  templateUrl: './chart-sales-hour.component.html',
  styleUrls: ['./chart-sales-hour.component.scss']
})
export class ChartSalesHourComponent implements OnDestroy {

  @Input() variable = 'transactions';
  @Input() type = 'Autos';
  @Input() station: any = false;
  chartOptions: Highcharts.Options;
  data = [];
  date = moment().month(10).date(1).format('YYYY-MM-DD');
  event$!: Subscription;
  Highcharts = Highcharts;
  hours = [];
  idGroup: GroupIds | string = GroupIds.Pa;
  loading = false;
  private destroy$ = new Subject<void>();
  productType = 'regular';
  reqst = 0;
  rol;
  route;
  stationId: any;
  zone;
  changeZone = false;

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });

    combineLatest([
      this.sharedService.sharedGroup,
      this.sharedService.sharedZone,
      this.sharedService.sharedStationSelected,
      this.sharedService.sharedSelectedMonth,
      this.sharedService.sharedProductTypeTotal,
    ]).pipe(takeUntil(this.destroy$)).subscribe(([idGroup, zone, stationId, period, productType]) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
      }

      if (this.zone !== zone) {
        if(this.zone !== undefined) {
          this.changeZone = true;
        }
        this.zone = zone;
      }

      if (this.stationId !== stationId) {
        this.stationId = stationId;
      }

      if (period !== this.date) {
        this.date = period;
      }

      if (this.productType !== productType) {
        this.productType = productType;
      }

      if (!this.loading) {
        this.getHeatMapHour();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getHeatMapHour() {
    this.loading = true;
    this.reqst = 0;
    this.hours = [];
    this.data = [];
    setTimeout(() => {
      const url =this.station ?  this.gasprecioService.getHeatMapHour(this.idGroup, this.stationId, this.variable, this.productType, this.date, true):  this.gasprecioService.getHeatMapHour(this.idGroup, this.stationId, this.variable, this.productType, this.date);
      url.subscribe({
        next: (data: any) => {
          data.map((x: any) => {
            this.data.push(+x['value']?.toFixed(0));
            this.hours.push(x['hour']);
          });          
          this.setData();
        }, complete: () => {
          this.reqst = 1;
        }, error: () => {
          this.loading = false;
          this.reqst = 2;
        }
      });
    }, 400);
  }

  setData() {
    const min = this.gasprecioService.getArrRange(this.data, 'min', 'array');
    const max = this.gasprecioService.getArrRange(this.data, 'max', 'array');
    const barColors = [];
    this.data.map((x: any) => {
      barColors.push(this.gasprecioService.changeColor(x, min, max, true))
    });  

    this.chartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        type: 'column'  // Gráfico de barras
      },
      title: {
        text: `${this.type} por Hora del Día`,
        style: {
          fontSize: '0'
        }
      },
      exporting: {
        filename:  `${this.type} por Hora del Día`,
        sourceWidth: 1200,
        sourceHeight: 800,
      },
      navigation: {
        buttonOptions: {
          align: 'right',
          y: -10
        }
      },
      xAxis: {
        categories: this.hours,  // Genera las horas del día como categorías
        gridLineWidth: 1,
        gridLineDashStyle: 'LongDash',
        title: {
          text: 'Horas del Día',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          style: {
            fontSize: "10px",
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: `Cantidad de ${this.type}`,
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: this.type === 'Autos' ? '{value:,.0f}' : '{value:,.0f} lts',
          style: {
            fontSize: '11px'
          }
        },
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      },
      tooltip: {
        pointFormat: this.type === 'Autos' ? `${this.type}: <b>{point.y}</b>` : `${this.type}: <b>{point.y} lts</b>`
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'top',
        itemDistance: 10,
        y: 1,
        padding: 1,
        itemMarginTop: 1,
        itemMarginBottom: 1,
        itemHoverStyle: {
          color: '#00E07B',
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          colorByPoint: true,
        }
      },
      series: [{
        type: 'column',
        name: `${this.type}`,
        data: this.data, // Datos de autos para cada hora
        colorByPoint: true,
        colors: barColors  // Personaliza el color de las barras
      }]
    };

    setTimeout(() => {
      this.loading = false;
    }, 100);
  }
}
