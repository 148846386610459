<div class="row jss1">
    <div class="col-md-6 jss2 d-none d-md-block " style="background-image:url(assets/images/login/img_login.svg)">

    </div>
    <div class="col-md-6 align-middle">

        <div class="container">
            <form class="form-login form-horizontal form-material full mb-5" [formGroup]="loginForm"
                (ngSubmit)="onSubmit()">


                <div class="row justify-content-md-center row-mobile">
                    <div class="col-lg-12">
                        <a href="javascript:void(0)" class="text-center db">
                            <img src="https://crm-angular.s3.us-east-2.amazonaws.com/assets/images/password/logo-v.png"
                                height="250px" alt="Home" />
                        </a>
                        <h2 class="welcome mb-4">Bienvenido</h2>
                    </div>
                    <div class="col-lg-8">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="validatedInputGroupPrepend">
                                        <img src="assets/images/svgs/user.svg" alt="U" srcset="">
                                    </span>
                                </div>
                                <input type="text" class="form-control" id="email" name="email"
                                    placeholder="Correo Electrónico" formControlName="email" #email>

                                <span class="text-danger" *ngIf="submitted && f.email.errors">
                                    El correo electrónico que ingresaste no coincide con ninguna cuenta.
                                </span>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-8">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="validatedInputGroupPrepend">
                                        <img src="assets/images/svgs/key.svg" alt="C" srcset="">
                                    </span>
                                </div>
                                <input class="form-control pw" id="pwd" [type]="showPassword ? 'text' : 'password'"
                                    name="pwd" placeholder="Contraseña" formControlName="password" #password>

                                <i class="fa" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                                    (click)="togglePasswordVisibility()"
                                    style="position: absolute; right: 10px; top: 10px; cursor: pointer;"></i>


                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <span class="text-danger" *ngIf="submitted && f.password.errors">
                                        La contraseña que ingresaste es incorrecta.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <p class="text-center text-secondary mb-2">
                            <a href="javascript:void(0)" class="text-secondary olvide " (click)="recoverPassword()">
                                <i class="fa fa-lock m-r-5"></i>
                                Olvide mi contraseña
                            </a>
                        </p>
                    </div>
                    <div class="col-lg-8 col-sm-12">
                        <button class="btn btn-info btn-red btn-lg btn-rounded" color="primary" type="submit"
                            value="submit" [disabled]="!loginForm.valid">
                            Iniciar Sesión
                        </button>
                    </div>
                </div>
            </form>
        </div>

    </div>
</div>