import { Component, OnDestroy, OnInit } from '@angular/core';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-table-sales-quartil',
  templateUrl: './table-sales-quartil.component.html',
  styleUrls: ['./table-sales-quartil.component.scss']
})
export class TableSalesQuartilComponent implements OnInit, OnDestroy {

  daysAgo = 180
  daysAgo$!: Subscription;
  idGroup = '';
  idGroupSub$!: Subscription;
  market = '';
  productType = 'regular';
  productType$!: Subscription;
  reqst = 0;
  rol;
  rowsFcst: any;
  rowsCurrent: any;
  rowsLength: number;
  rowsRecord: any;
  showSplitZones = false;
  showTable = false;
  showSubsubdivision = 0;
  showSubsubdivision$!: Subscription;
  showSubdivision = 0;
  showSubdivision$!: Subscription;
  showMegagas = false;
  splitZones = false;
  variableSelected = 'sales';
  variableSelected$!: Subscription;
  simulation = false;
  extraColumnIndex = -1;
  simPrice!: string | null;
  simDifPrice!: string | null;
  type!: string;
  idStation!: string;
  simulationData!: any;
  mostrar = true;

  constructor(
    private sharedService: SharedService,
    public gasprecioService: GasprecioService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '60afa99655f4ee369d1df409' : localStorage.getItem('group');
    this.showMegagas = this.idGroup === '666cb6528c251f2c5d6204b5' ? true : false;
    this.idGroupSub$ = this.sharedService.sharedGroup.subscribe(idGroup => {
      if (idGroup !== this.idGroup && idGroup !== '') {
        this.idGroup = idGroup;
        this.showMegagas = this.idGroup === '666cb6528c251f2c5d6204b5' ? true : false;
      }
    });

    this.productType$ = this.sharedService.sharedProductType.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 0) {
          this.getTableSalesQuartil();
        }
      }
    });

    this.variableSelected$ = this.sharedService.sharedVariablePxOpt.subscribe(variableSelected => {
      if (this.variableSelected !== variableSelected) {
        this.variableSelected = variableSelected;
        if (this.reqst > 0) {
          this.getTableSalesQuartil();
        }
      }
    });

    this.daysAgo$ = this.sharedService.sharedDaysOptimumPx.subscribe(daysAgo => {
      if (this.daysAgo !== daysAgo) {
        this.daysAgo = daysAgo;
        if (this.reqst > 0) {
          this.getTableSalesQuartil();
        }
      }
    });

    this.showSubdivision$ = this.sharedService.sharedShowSubdivison.subscribe(showSubdivision => {
      if (this.showSubdivision !== showSubdivision) {
        this.showSubdivision = showSubdivision;
        if (this.reqst > 0) {
          this.getTableSalesQuartil();
        }
      }
    });

    this.showSubsubdivision$ = this.sharedService.sharedShowSubsubdivison.subscribe(showSubsubdivision => {
      if (this.showSubsubdivision !== showSubsubdivision) {
        this.showSubsubdivision = showSubsubdivision;
        if (this.reqst > 0) {
          this.getTableSalesQuartil();
        }
      }
    });

    this.idStation = localStorage.getItem('idStation');
  }

  ngOnInit(): void {
    this.getSalesQuartilSplitZones();
    this.getTableSalesQuartil();
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.variableSelected$.unsubscribe();
    this.daysAgo$.unsubscribe();
  }

  changeShowTable() {
    this.showTable = !this.showTable;
  }

  getRowHeight(row: any) {
    return row.height;
  }

  numberWithCommas(x: any) {
    x = x.toFixed(0);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  changeSplitZones() {
    this.splitZones = !this.splitZones;
    this.showSubdivision = this.showSubdivision === 1 ? 0 : 1;
    if(this.showSubsubdivision === 1){
      this.showSubsubdivision = 0;
      this.showSubdivision = 0;
      this.sharedService.nextShowSubsubdivison = this.showSubsubdivision;
    }
    this.sharedService.nextShowSubdivison = this.showSubdivision;
    this.getTableSalesQuartil();
  }

  changeSubSplitZones() {
    this.showSubsubdivision = this.showSubsubdivision === 0 ? 1 : 0;
    this.showSubdivision = this.showSubsubdivision === 1 ? 0 : 1;
    this.sharedService.nextShowSubsubdivison = this.showSubsubdivision;
    this.sharedService.nextShowSubdivison = this.showSubdivision;
    this.getTableSalesQuartil();
  }

  getSalesQuartilSplitZones() {
    this.gasprecioService.getSalesQuartilSplitZones(this.productType, this.daysAgo, this.showSubsubdivision).subscribe((data: any) => {
      this.showSplitZones = data;
    })
  }

  prepareSimulation(index: number, market: string, type: 'diff' | 'sale'): void {
    if (!this.simulation) {
      this.removeAt(this.extraColumnIndex + 1);
      return;
    }

    this.extraColumnIndex = index;
    this.type = type;
    const element = {
      idx: index + 100,
      diff_price_competitors: '-',
      earning: '-',
      flag_obs: null,
      margin: '-',
      n_obs: '-',
      quotient: '-',
      sales: '-',
      sales_change: '-',
      sales_pct: '-',
      selling_price: '-',
      has_subdivisions: false,
      has_subsubdivisions: false,
      market: `sim_${market}`,
      flag_actual: false,
      showExpand: false
    };
    if (type === 'sale') this.simPrice = this.rowsCurrent[index].selling_price;
    else this.simDifPrice = this.rowsCurrent[index].diff_price_competitors;
    this.insertAt(index + 1, element);
  }

  getTableSalesQuartil() {
    this.reqst = 0;
    this.rowsCurrent = [];
    this.rowsRecord = [];
    setTimeout(() => {
      this.gasprecioService.getTableSalesQuartil(this.productType, this.variableSelected, this.daysAgo, this.showSubdivision, this.showSubsubdivision).subscribe((data: any) => {
        if (data['record']) {
          this.rowsLength = (73 / data['record'].length);
          data['record'].map((element: any, index) => {
            if (element['flag_actual']) {
              this.market = element['market'];
            }
            data['current'][index]['showExpand'] = element['n_obs'] >= 60 ? true : false;
            element['showExpand'] = element['n_obs'] >= 60 ? true : false;
            element['n_obs'] = element['n_obs'] !== null ? element['n_obs'] : '-';
            element['diff_price_competitors'] = element['diff_price_competitors'] !== null ? element['diff_price_competitors'] : '-';
            element['selling_price'] = element['selling_price'] !== null ? element['selling_price'] : '-';
            element['sales'] = element['sales'] !== null ? element['sales'] : '-';
            element['earning'] = element['earning'] !== null ? element['earning'] : '-';
            element['sales_change'] = element['sales_change'] !== null ? element['sales_change'] : '-';
            element['quotient'] = element['quotient'] !== null ? element['quotient'] : '-';
            element['margin'] = element['margin'] !== null ? element['margin'] : '-';
            element['sales_pct'] = element['sales_pct'] !== null ? element['sales_pct'] * 100 : '-';
          });

          data['current'].map((element: any, _: number) => {
            if (element['flag_actual']) {
              this.market = element['market'];
            }
            element['diff_price_competitors'] = element['diff_price_competitors'] !== null ? element['diff_price_competitors'] : '-';
            element['price_change'] = element['price_change'] !== null ? element['price_change'] : '-';
            element['selling_price'] = element['selling_price'] !== null ? element['selling_price'] : '-';
            element['sales7'] = element['sales7'] !== null ? element['sales7'] : '-';
            element['earning'] = element['earning'] !== null ? element['earning'] : '-';
            element['supply_price'] = element['supply_price'] !== null ? element['supply_price'] : '-';
            element['quotient'] = element['quotient'] !== null ? element['quotient'] : '-';
            element['margin'] = element['margin'] !== null ? element['margin'] : '-';
          });

          if(this.showMegagas) {
            data['current'].map((element: any, _: number) => {
              if (element['flag_actual']) {
                this.market = element['market'];
              }
              element['diff_price_competitors'] = element['diff_price_competitors'] !== null ? element['diff_price_competitors'] : '-';
              element['price_change'] = element['price_change'] !== null ? element['price_change'] : '-';
              element['selling_price'] = element['selling_price'] !== null ? element['selling_price'] : '-';
              element['sales7'] = element['sales7'] !== null ? element['sales7'] : '-';
              element['earning'] = element['earning'] !== null ? element['earning'] : '-';
              element['supply_price'] = element['supply_price'] !== null ? element['supply_price'] : '-';
              element['quotient'] = element['quotient'] !== null ? element['quotient'] : '-';
              element['margin'] = element['margin'] !== null ? element['margin'] : '-';
            });
          }
          
          this.rowsFcst = data['current'];
          this.rowsCurrent = data['current'];
          this.rowsRecord = data['record'];
        }
        this.reqst = 1;
      });
    }, 300);
  
  }

  px(price: string): void {
    this.reqst = 0;
    this.gasprecioService.showLoader('Generando Simulación', 'Los datos tardan un poco en generarse, aguarda un momento...');
    this.gasprecioService.getPxOptimo(price, this.type, this.productType, this.idStation).subscribe({
      next: (res) => {
        this.simulationData = res.data.markets.simulation;
        this.rowsCurrent[this.extraColumnIndex + 1] = this.simulationData;
        this.gasprecioService.displayMessage('success', '¡Simulación hecha con éxito!');
        this.reqst = 1;
      },
      error: (_: HttpErrorResponse) => {
        this.gasprecioService.displayMessage('error', 'Hubo un error al hacer la simulación. Intenta de nueevo más tarde.')
        this.reqst = 1;
      }
    })
  }

  insertAt(index: number, value: any): void {  
    this.rowsRecord.splice(index, 0, value);
    this.rowsCurrent.splice(index, 0, { ...value, simulation: true });
  }

  removeAt(index: number): void {
    this.rowsRecord.splice(index, 1);  
    this.rowsCurrent.splice(index, 1);
    this.simPrice = null;
    this.simDifPrice = null;
    this.simulationData = null;
  }

}
