import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { SharedService } from "src/app/modules/gasprecio/services/shared.service";

@Component({
  selector: 'app-select-discount-historical-tad-report',
  templateUrl: './select-discount-historical-tad-report.component.html',
  styleUrls: ['./select-discount-historical-tad-report.component.scss']
})
export class SelectDiscountHistoricalTadReportComponent implements OnDestroy {
  public items: any[] = [
    // { value: "", name: "Indicador" },
    { value: 80, name: "80%" },
    { value: 100, name: "100%" },
  ];

  public discountSelected: any = 80;
  discountSelected$: Subscription;
  constructor(private sharedService: SharedService) {
    this.discountSelected$ = sharedService.sharedHistoricalTadReportDiscount.subscribe(
      (discountSelected: string) => {
        if (this.discountSelected !== discountSelected) {
          this.discountSelected = discountSelected;
        }
      }
    );
  }

  ngOnDestroy() {
    this.discountSelected$.unsubscribe();
  }

  changeDiscount(event: any) {
    this.discountSelected = event;
    this.sharedService.nextHistoricalTadReportDiscount = this.discountSelected;
  }

}
