declare const OneSignal: any;
import * as fs from 'file-saver';
import Swal from 'sweetalert2';
import * as moment from 'moment-timezone';
import { AuthService } from '../../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Station } from 'src/app/models/Station';
import { lastValueFrom, Observable, Subject, throwError } from 'rxjs';
import { environment } from '../../../../environments/environments';
import { catchError, map, retry, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NumberFormat } from 'xlsx';
import { Rentabilidad } from '../components/panel/profitability/profitability.component';

// const url = environment.developmentUrl
const url = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})

export class GasprecioService {

  private endpoint = url + '/v1/gasprecio';
  private endpoint1 = url + '/v1';
  private endpoint2 = '/v1/gasprecio';
  public idStation;
  public idGroup;
  public _currentStation: any = {};
  public get currentStation() { return this._currentStation; }
  public currentStationSource = new Subject<any>();
  public currentStation$ = this.currentStationSource.asObservable();
  private competitorsSource = new Subject<Array<any>>();
  private _competitors: Array<Station> = [];
  public competitors$ = this.competitorsSource.asObservable();
  get competitors() { return this._competitors; }
  private clustersCache: { [key: string]: Observable<any> } = {};
  public date: Observable<any> | undefined;
  public group: Observable<any> | undefined;
  public groups: Observable<any> | undefined;
  public dashboardPrice: Observable<any> | undefined;
  public dashboardComparative: Observable<any> | undefined;
  public dashboardNewPrices: Observable<any> | undefined;
  public variables: Observable<any> | undefined;
  public variablesMargin: Observable<any> | undefined;
  public weeks: Observable<any> | undefined;
  public markets: Observable<any> | undefined;
  public zones: Observable<any> | undefined;
  public marginEvolution: Observable<any> | undefined;
  public marginDays: Observable<any> | undefined;
  public maxSales: Observable<any> | undefined;
  public sales: Observable<any> | undefined;
  public saleEarning: Observable<any> | undefined;
  public brands: Observable<any> | undefined;
  public entities: Observable<any> | undefined;
  public listStations: Observable<any> | undefined;
  public listStationsZone: Observable<any> | undefined;
  public difMin: Observable<any> | undefined;
  public difMax: Observable<any> | undefined;
  public lastProduct;
  public lastVariable;
  public lastDays;
  public reasons: Observable<any> | undefined;
  public rol;
  listDemo = ['650b1770c61e9acd325c8df8', '650b1771c61e9acd325c8e18', '650b1772c61e9acd325c8e38', '650b1773c61e9acd325c8e58', '650b1774c61e9acd325c8e78']
  public colors = [
    { r: 212, g: 175, b: 55, a: 1 }, // gold
    { r: 205, g: 33, b: 32, a: 0.8 },  // red
    { r: 40, g: 218, b: 109, a: 0.8 }, // green
    { r: 99, g: 63, b: 156, a: 0.8 },  // purple
    { r: 0, g: 150, b: 210, a: 0.8 },  // blue
    { r: 75, g: 192, b: 192, a: 0.8 }, // blue-green
    { r: 138, g: 75, b: 8, a: 0.8 },   // brown
    { r: 222, g: 76, b: 138, a: 0.8 }, // pink
    { r: 16, g: 44, b: 84, a: 0.8 },   // dark blue
    { r: 132, g: 132, b: 132, a: 0.8 }, // gray
    { r: 220, g: 220, b: 220, a: 0.8 }, // light gray
    { r: 155, g: 89, b: 182, a: 0.8 }, // violet
    { r: 241, g: 204, b: 177, a: 0.8 }, // light orange
    { r: 26, g: 188, b: 156, a: 0.8 },  // turquoise
    { r: 241, g: 196, b: 15, a: 0.8 },  // yellow
    { r: 46, g: 204, b: 113, a: 0.8 },  // green
    { r: 230, g: 126, b: 34, a: 0.8 },  // dark orange
    { r: 204, g: 255, b: 153, a: 0.8 }, // light green
    { r: 255, g: 0, b: 255, a: 0.8 },   // magenta
    { r: 255, g: 0, b: 0, a: 0.8 },     // red
    { r: 190, g: 162, b: 125, a: 0.8 }, // beige
    { r: 99, g: 63, b: 156, a: 0.6 },  // purple
    { r: 0, g: 150, b: 210, a: 0.6 },  // blue
    { r: 75, g: 192, b: 192, a: 0.6 }, // blue-green
    { r: 138, g: 75, b: 8, a: 0.6 },   // brown
    { r: 222, g: 76, b: 138, a: 0.6 }, // pink
    { r: 16, g: 44, b: 84, a: 0.6 },   // dark blue
    { r: 132, g: 132, b: 132, a: 0.6 }, // gray
    { r: 220, g: 220, b: 220, a: 0.6 }, // light gray
    { r: 155, g: 89, b: 182, a: 0.6 }, // violet
    { r: 241, g: 204, b: 177, a: 0.6 }, // light orange
    { r: 26, g: 188, b: 156, a: 0.6 },  // turquoise
    { r: 241, g: 196, b: 15, a: 0.6 },  // yellow
    { r: 46, g: 204, b: 113, a: 0.6 },  // green
    { r: 230, g: 126, b: 34, a: 0.6 },  // dark orange
    { r: 204, g: 255, b: 153, a: 0.6 }, // light green
    { r: 255, g: 0, b: 255, a: 0.6 },   // magenta
    { r: 255, g: 0, b: 0, a: 0.6 },     // red
    { r: 190, g: 162, b: 125, a: 0.6 }, // beige
    { r: 205, g: 33, b: 32, a: 0.4 },  // red
    { r: 40, g: 218, b: 109, a: 0.4 }, // green
    { r: 99, g: 63, b: 156, a: 0.4 },  // purple
    { r: 0, g: 150, b: 210, a: 0.4 },  // blue
    { r: 75, g: 192, b: 192, a: 0.4 }, // blue-green
    { r: 138, g: 75, b: 8, a: 0.4 },   // brown
    { r: 222, g: 76, b: 138, a: 0.4 }, // pink
    { r: 16, g: 44, b: 84, a: 0.4 },   // dark blue
    { r: 132, g: 132, b: 132, a: 0.4 }, // gray
    { r: 220, g: 220, b: 220, a: 0.4 }, // light gray
    { r: 155, g: 89, b: 182, a: 0.4 }, // violet
    { r: 241, g: 204, b: 177, a: 0.4 }, // light orange
    { r: 26, g: 188, b: 156, a: 0.4 },  // turquoise
    { r: 241, g: 196, b: 15, a: 0.4 },  // yellow
    { r: 46, g: 204, b: 113, a: 0.4 },  // green
    { r: 230, g: 126, b: 34, a: 0.4 },  // dark orange
    { r: 204, g: 255, b: 153, a: 0.4 }, // light green
    { r: 255, g: 0, b: 255, a: 0.4 },   // magenta
    { r: 255, g: 0, b: 0, a: 0.4 },     // red
    { r: 190, g: 162, b: 125, a: 0.4 }, // beige
    { r: 99, g: 63, b: 156, a: 0.2 },  // purple
    { r: 0, g: 150, b: 210, a: 0.2 },  // blue
    { r: 75, g: 192, b: 192, a: 0.2 }, // blue-green
    { r: 138, g: 75, b: 8, a: 0.2 },   // brown
    { r: 222, g: 76, b: 138, a: 0.2 }, // pink
    { r: 16, g: 44, b: 84, a: 0.2 },   // dark blue
    { r: 132, g: 132, b: 132, a: 0.2 }, // gray
    { r: 220, g: 220, b: 220, a: 0.2 }, // light gray
    { r: 155, g: 89, b: 182, a: 0.2 }, // violet
    { r: 241, g: 204, b: 177, a: 0.2 }, // light orange
    { r: 26, g: 188, b: 156, a: 0.2 },  // turquoise
    { r: 241, g: 196, b: 15, a: 0.2 },  // yellow
    { r: 46, g: 204, b: 113, a: 0.2 },  // green
    { r: 230, g: 126, b: 34, a: 0.2 },  // dark orange
    { r: 204, g: 255, b: 153, a: 0.2 }, // light green
    { r: 255, g: 0, b: 255, a: 0.2 },   // magenta
    { r: 255, g: 0, b: 0, a: 0.2 },     // red
    { r: 190, g: 162, b: 125, a: 0.2 }, // beige
  ];

  public colorsEnerser = [{
    Enerser: { r: 0, g: 50, b: 161, a: 0.8 },
    Chevron: { r: 0, g: 50, b: 161, a: 0.4 },
    Arco: { r: 150, g: 214, b: 255, a: 0.8 },
    Bp: { r: 153, g: 204, b: 1, a: 0.8 },
    Pemex: { r: 0, g: 128, b: 38, a: 0.8 },
    Rendichicas: { r: 227, g: 32, b: 134, a: 0.8 },
    Shell: { r: 251, g: 206, b: 5, a: 0.8 },
    Oxxogas: { r: 232, g: 120, b: 40, a: 0.8 },
    Akron: { r: 238, g: 47, b: 33, a: 0.8 },
  }];

  public colorsProducts = [
    { r: 23, g: 148, b: 70, a: 1 }, // green
    { r: 205, g: 33, b: 32, a: 1 },  // red
    { r: 30, g: 34, b: 40, a: 1 }, // black
    { r: 0, g: 95, b: 189, a: 1 }, // blue
    { r: 255, g: 143, b: 7, a: 1 } // orange
  ];

  public colorsProductsLight = [
    { r: 162, g: 212, b: 181, a: .6 }, // green
    { r: 235, g: 166, b: 165, a: .6 },  // red
    { r: 165, g: 166, b: 169, a: .6 }, // black
    { r: 150, g: 214, b: 255, a: 1 }, // blue
    { r: 255, g: 143, b: 7, a: .6 } // orange
  ];

  public colorsMinMax = [
    { r: 249, g: 188, b: 2, a: .9 },  // yellow
    { r: 251, g: 120, b: 1, a: .9 } // orange
  ];

  public colorsSuggested = [
    { r: 14, g: 70, b: 155, a: .9 }
  ];

  constructor(
    private http: HttpClient,
    public authenticationService: AuthService,
    public router: Router
  ) {
    this.rol = localStorage.getItem('rol');
    this.idStation = localStorage.getItem('idStation') ?? this.authenticationService.idStation;
    if (this.listDemo.includes(this.idStation)) {
      this.rol = 'Gaspre';
    }
  }

  // Obtener el margen por nivel de los competidores

  async init(marginType) {
    if (marginType === 'Margen TAR') {
      marginType = '0';
    }

    await lastValueFrom(this.getMonitor(+marginType)).then((station: any) => {
      this._currentStation = { ...this.currentStation, ...station['client'] };
      this._currentStation['name'] = this.removeSADECV(this.currentStation['name']);
      this._currentStation['regular_price'] = this._currentStation['regular_selling_price'];
      this._currentStation['premium_price'] = this._currentStation['premium_selling_price'];
      this._currentStation['diesel_price'] = this._currentStation['diesel_selling_price'];
      this._currentStation['old_name'] = this._currentStation['name'];
      this._currentStation['cre_id'] = this._currentStation['cre_id'] && this.rol !== 'Gaspre' ? this._currentStation['cre_id'] : 'PL/30000/EXP/ES/2023';
      this._currentStation['tar_name'] = this._currentStation['tar_name'] ? this.capitalizeFirstLetter(this._currentStation['tar_name']) : '';
      this._currentStation['brand'] = this._currentStation['brand'] ? this.removeSADECV(this.currentStation['brand']) : '';
      this._currentStation['brand'] = this._currentStation['brand'] === 'Bp' ? 'BP' : this.currentStation['brand'];
      this.currentStationSource.next(this.currentStation);
      this._competitors = station['competitors'];
      this._competitors.forEach((element: any, index) => {
        const pos = index + 1;
        element['name'] = element['name'] && this.rol !== 'Gaspre' ? this.removeSADECV(element['name']) : 'Competidor ' + pos;
        element['old_name'] = element['name'];
        element['tar_name'] = element['tar_name'] ? this.capitalizeFirstLetter(element['tar_name']) : '';
        element['regular_price'] = element['regular_selling_price'] ? element['regular_selling_price'] : null;
        element['premium_price'] = element['premium_selling_price'] ? element['premium_selling_price'] : null;
        element['diesel_price'] = element['diesel_selling_price'] ? element['diesel_selling_price'] : null;
        element['brand'] = element['brand'] ? this.removeSADECV(element['brand']) : '';
        element['brand'] = element['brand'] === 'Bp' ? 'BP' : element['brand'];
        element['brand'] = element['brand'] === 'Permer Gas' ? 'Permer' : element['brand'];
        element['brand'] = element['brand'] === 'Ferche Gas' ? 'Ferche' : element['brand'];
      });
      this.competitorsSource.next(this.competitors);
    });
  }

  // Mandar player Id de onesignal para poder enviar notificaciones web

  setPlayerId(idUser: any) {
    OneSignal.getUserId((id: any) => {
      if (id) {
        OneSignal.push(() => {
          if (idUser) {
            OneSignal.setExternalUserId(idUser);
          }
        });
      }
    });
  }

  // Obtener competidores de CorpoGas

  getCompetitorsCG(idStation: string): Observable<any> {
    if (!idStation || typeof idStation !== 'string' || idStation.trim().length === 0) {
      throw new Error('idStation debe ser una cadena no vacía');
    }

    return this.http.get(`${this.endpoint1}/crm/stations/${idStation}/competitors`, { headers: { skip: 'skip' } }).pipe(
      map((x: any) => x?.competitors),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener competidores

  getCompetitors(idStation: string): Observable<any> {
    if (!idStation || typeof idStation !== 'string' || idStation.trim().length === 0) {
      throw new Error('idStation debe ser una cadena no vacía');
    }

    return this.http.get(`${this.endpoint1}/crm/stations/${idStation}/competitors`).pipe(
      map((x: any) => x?.competitors),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener grupos gasolineros (clientes)

  getGroup(): Observable<any> {
    return this.group ??= this.http.get(`${this.endpoint1}/crm/stations/${this.idStation}/group`).pipe(
      map((x: any) => x?.group),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener grupos gasolineros (clientes)

  getGroups(): Observable<any> {
    return this.groups ??= this.http.get(`${this.endpoint1}/crm/groups`).pipe(
      map((x: any) => x?.data?.groups),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener la Estaciones Grupo de un grupo gasolinero

  getStationsGroup(idGroup: string): Observable<any> {
    if (this.listStations) {
      return this.listStations;
    } else {
      this.listStations = this.http.get(`${this.endpoint1}/crm/groups/${idGroup}/stations`).pipe(
        map((x: any) => x?.data?.stations),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.listStations;
    }
  }

  getStationsGroupZone(idGroup: string, zone: string): Observable<any> {
    if (this.listStationsZone) {
      return this.listStationsZone;
    } else {
      this.listStationsZone = zone === null ? this.http.get(`${this.endpoint1}/crm/groups/${idGroup}/stations?zone=`).pipe(
        map((x: any) => x?.data?.stations),
        shareReplay(1),
        catchError(this.processError)
    ) :
    this.http.get(`${this.endpoint1}/crm/groups/${idGroup}/stations?zone=${zone}`).pipe(
        map((x: any) => x?.data?.stations),
        shareReplay(1),
        catchError(this.processError)
    );
      return this.listStationsZone;
    }
  }

  createCluster(idGroup: string, data) {
    return this.http.put(`${this.endpoint}/general/clusters?group=${idGroup}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  deleteCluster(idGroup: string, data: any) {
    return this.http.delete<any>(`${this.endpoint}/general/clusters?group=${idGroup}`, { body: data })
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Obtener la última actualización de información

  getLastUpdate(): Observable<any> {
    if (this.date) {
      return this.date;
    } else {
      this.date = this.http.get<string>(`${this.endpoint}/last_update`).pipe(
        map((x: any) => x?.last_update),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.date;
    }
  }

  // Obtener lista de notificaciones por cada grupo

  getAlerts(): Observable<any> {
    return this.http.get(`${this.endpoint}/alerts`).pipe(
      map((x: any) => x?.alerts),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Envia una bandera de que le dieron click o leyeron una notificación

  alertRead(idAlert: string) {
    const data = { 'click': true };
    return this.http.put(`${this.endpoint}/alerts/${idAlert}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Obtener lista de notificaciones del usuario

  getUserNotifications(idStation): Observable<any> {
    return this.http.get(`${this.endpoint}/competence_price/monitor/notifications?station=${idStation}`).pipe(
      map((x: any) => x?.notification_config),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  updateUserNotifications(idStation, data): Observable<any> {
    return this.http.post(`${this.endpoint}/competence_price/monitor/notifications?station=${idStation}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Seccion monitor

  // Guardar precios modificados

  setSellingPricesManual(data) {
    return this.http.post(`${this.endpoint}/selling_price/assignment/manual/bulk`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Obtener los niveles de descuentos

  getDiscountsLevels(clusterId) {
    return this.http.get(`${this.endpoint}/tars/current_discounts_values?cluster_id=${clusterId}`).pipe(
      map((x: any) => x?.data?.discounts),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener Precio Actual y margen actual para mostrar en el monitor y mapa

  getMonitor(marginType): Observable<any> {
    return this.http.get(`${this.endpoint}/selling_price/monitor?station=${this.idStation}&margin_type=${marginType}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener Comparativo de precios entre ayer y hoy

  getMapsMonitorTodayYesterday(limit, station?:any): Observable<any> {
    return this.http.get(`${this.endpoint}/selling_price/today_yesterday_prices?station=${station?station:this.idStation}&competitors_limit=${limit}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMapsMonitorPricesHistory(limit, station?:any): Observable<any> {
    return this.http.get(`${this.endpoint}/selling_price/evolution?station=${station?station:this.idStation}&competitors_limit=${limit}&n=7&period=day`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener el Margen Actual de una estación

  getMargin(): Observable<any> {
    return this.http.get<Array<object>>(`${this.endpoint}/monitor/margin?station=${this.idStation}`).pipe(
      map((x: any) => x?.margin),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener el precio minimo por zona de todas las estaciones

  getPricesZoneT(idStation): Observable<any> {
    return this.http.get<Array<object>>(`${this.endpoint}/selling_price/report_zone?station=${idStation}`).pipe(
      map((x: any) => x?.data?.all_products_formated),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener el precio minimo por zona de una estación

  getPricesZone(): Observable<any> {
    return this.http.get<Array<object>>(`${this.endpoint}/selling_price/report_zone?station=${this.idStation}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener lista de competidores para poder seleccionar los que influyen a tu precio

  getListCompetitors(distance: number): Observable<any> {
    return this.http.get(`${this.endpoint}/stations/${this.idStation}/competitors/list?distance=${distance}`).pipe(
      map((x: any) => x?.data?.list),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Guardar la lista de competidores seleccionados en el monitor

  saveListCompetitors(data: any) {
    return this.http.post(`${this.endpoint}/stations/${this.idStation}/competitors/selection`, data)
      .pipe(
        map((x: any) => { return x; }),
        shareReplay(1),
        catchError(this.processError)
      );
  }

  // Obtener lista de competidores de correlaciones para poder seleccionar los que influyen a tu precio

  getListCorrelationCompetitors(distance: number): Observable<any> {
    return this.http.get(`${this.endpoint}/stations/${this.idStation}/correlation-competitors/list?distance=${distance}`).pipe(
      map((x: any) => x?.data?.list),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Guardar la lista de competidores de correlaciones seleccionados en el monitor

  saveListCorrelationCompetitors(data: any) {
    return this.http.post(`${this.endpoint}/stations/${this.idStation}/correlation-competitors/selection`, data)
      .pipe(
        map((x: any) => { return x; }),
        shareReplay(1),
        catchError(this.processError)
      );
  }

  // Guardar el horario en que quieres recibir las notificaciones de cambios de precio

  scheduleNotifications(from: any, until: any) {

    const data = { 'from': from, 'until': until };

    return this.http.put(`${this.endpoint}/competence_price/monitor/schedule?station=${this.idStation}`, data)
      .pipe(map(station => {
        return station;
      })
      );
  }

  // Obtener competidores a los cuales puedes editar su precio manual

  getEditStations(): Observable<any> {
    return this.http.get(`${this.endpoint}/stations/${this.idStation}/enable_edit_selling_prices`).pipe(
      map((x: any) => x?.data?.stations),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Guardar los nuevos precios por producto de una estación

  setNewPrice(creId: any, product: any, price: any, date: any) {

    const data = {
      'cre_id': creId,
      'product': product,
      'price': price,
      'application_date': date
    };

    return this.http.post<any>(`${this.endpoint}/selling_price/assignment/manual`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Activar/desactivar notificaciones de una estación

  activeNotifications(creId: any, status: any, type: any) {

    const data = { 'competitor': creId, 'active': status, 'type': type };

    return this.http.put(`${this.endpoint}/selling_price/competitor_notifications?station=${this.idStation}`, data)
      .pipe(map(station => {
        return station;
      })
      );
  }

  // Guardar el alias de una estación

  saveNewAlias(data) {
    return this.http.post<any>(`${this.endpoint}/stations/station/alias`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Guardar la marca de una estación

  saveNewBrand(data) {
    return this.http.post<any>(`${this.endpoint}/stations/station/brands`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Obtener info de la Bitacora de cambios y generar excel

  getChangesBinnacle(type?: number, startDate?: any, endDate?: any): Observable<any> {
    if (startDate && endDate) {
      return this.http.get(`${this.endpoint}/selling_price/competitor_changes?station=${this.idStation}&show_competitors=${type}&start_date=${startDate}&end_date=${endDate}`).pipe(
        map((x: any) => x?.data?.changes),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/selling_price/competitor_changes?station=${this.idStation}&show_competitors=${type}`).pipe(
        map((x: any) => x?.data?.changes),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Seccion competencia

  // Obtener info de la Evolución de precios

  getPriceHistory(daysAgo: any, month?: any, period = 'day') {
    if (month) {
      return this.http.get(`${this.endpoint}/selling_price/evolution?station=${this.idStation}&month=${month}&period=${period}`).pipe(
        map((x: any) => x?.data?.evolution),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/selling_price/evolution?station=${this.idStation}&n=${daysAgo}&period=${period}`).pipe(
        map((x: any) => x?.data?.evolution),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getTracking(idStation: string, product: string, period: number, startDate: string, endDate: string): Observable<any> {
    return this.http.get(`${this.endpoint}/control_panel/station/tracking_price_changes?station=${idStation}&product=${product}&period=${period}&start_date=${startDate}&end_date=${endDate}`).pipe(
      retry(2)
    );
  }
  
  getTrackingChanges(group: string, products: string, period: number, startDate: string, endDate: string, zone = '', magnitude = 0): Observable<any> {
    let query = `${this.endpoint}/control_panel/tracking_price_changes?group=${group}&zone=${zone}&${products}period=${period}&start_date=${startDate}&end_date=${endDate}`;
    if (magnitude !== 0) query += `&magnitude=${magnitude}`;
    return this.http.get(query).pipe(
      retry(2)
    );
  }

  changeTrackingPriceComment(id: number, comment: string): Observable<any> {
    return this.http.post(`${this.endpoint}/control_panel/tracking_price_changes/comment/${id}`, { comment }).pipe(
      retry(2)
    );
  }

  // Obtener info de la Evolución de margenes

  getMarginHistory(daysAgo: any, marginType: any, level?: any, startDate?: any, endDate?: any) {
    if (marginType === 'Margen TAR') {
      marginType = '0';
    }

    if (level === 'Nivel 9') {
      level = '9';
    }

    if (startDate && endDate) {
      return this.http.get(`${this.endpoint}/margin/tar/evolution?station=${this.idStation}&margin_type=${marginType}&level=${level}&start_date=${startDate}&end_date=${endDate}`).pipe(
        map((x: any) => x?.data?.evolution),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/margin/tar/evolution?station=${this.idStation}&days_ago=${daysAgo}&margin_type=${marginType}&level=${level}`).pipe(
        map((x: any) => x?.data?.evolution),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Excel de Evolución de precios

  getReportPriceHistory(startDate?: any, endDate?: any) {
    return this.http.get(`${this.endpoint}/selling_price/evolution?station=${this.idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.evolution),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener info Top 10 Competidores

  getTopTen() {
    return this.http.get(`${this.endpoint}/competence_price/competence/top10?station=${this.idStation}`).pipe(
      map((x: any) => x?.top10_price),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener info de la Grafica Top 10 Competidores

  getTopTenEvolution(daysAgo: any, variable: any, month?: any) {
    if (month) {
      return this.http.get(`${this.endpoint}/competence_price/competence/top10_evolution?station=${this.idStation}&variable=${variable}&month=${month}`).pipe(
        map((x: any) => x?.prices_evolution),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/competence_price/competence/top10_evolution?station=${this.idStation}&variable=${variable}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x?.prices_evolution),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Obtener info Municipio

  getTown() {
    return this.http.get(`${this.endpoint}/competence_price/competence/municipio?station=${this.idStation}`).pipe(
      map((x: any) => x?.municipio_prices),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener info Grafica Municipio

  getTownEvolution(daysAgo: any, variable: any, month?: any) {
    if (month) {
      return this.http.get(`${this.endpoint}/competence_price/competence/municipio_evolution?station=${this.idStation}&variable=${variable}&month=${month}`).pipe(
        map((x: any) => x?.prices_evolution),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/competence_price/competence/municipio_evolution?station=${this.idStation}&variable=${variable}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x?.prices_evolution),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Obtener lista de las Pipas compradas

  getPurchases(idGroup?: string, source?: string) {
    if (source) {
      return this.http.get(`${this.endpoint}/purchases?station=${this.idStation}&group=${idGroup}&source=${source}`).pipe(
        map((x: any) => x?.data?.purchases),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/purchases?station=${this.idStation}`).pipe(
        map((x: any) => x?.data?.purchases),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Obtener ultima Pipa comprada por cada producto

  getLastPurchases(idGroup?: string, source?: string) {
    if (source) {
      return this.http.get(`${this.endpoint}/purchases/last_purchases?station=${this.idStation}&group=${idGroup}&source=${source}`).pipe(
        map((x: any) => x?.data?.purchases),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/purchases/last_purchases?station=${this.idStation}`).pipe(
        map((x: any) => x?.data?.purchases),
        shareReplay(1),
        catchError(this.processError)
      );
    }

  }

  // Registrar Pipas compradas por cada producto

  registerPurchase(date: any, product: any, liters: any, cost: any, suplyPrice: any, sourcePurchase?: any, shipper?: any) {
    let data;
    if (sourcePurchase) {
      data = {
        'data': {
          'station': this.idStation,
          'date': date,
          'product': product,
          'liters': liters,
          'cost': cost,
          'supply_price': suplyPrice,
          'source_purchase': sourcePurchase,
          'shipper': shipper
        }
      };
    } else {
      data = {
        'data': {
          'station': this.idStation,
          'date': date,
          'product': product,
          'liters': liters,
          'cost': cost,
          'supply_price': suplyPrice
        }
      };
    }

    return this.http.post<any>(`${this.endpoint}/purchases/purchase`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Registrar Pipas compradas de PETRUM por cada producto

  registerPurchasePetrum(date: any, product: any, liters: any, cost: any, suplyPrice: any, adjust: any, purchaseSupplyAdjust: any, issuing: any, folio: any) {

    const data = {
      'data': {
        'issuing': issuing,
        'folio': folio,
        'station': this.idStation,
        'date': date,
        'product': product,
        'liters': liters,
        'cost': cost,
        'supply_price': suplyPrice,
        'adjust': adjust,
        'supply_price_adjust': purchaseSupplyAdjust
      }
    };

    return this.http.post<any>(`${this.endpoint}/purchases/purchase`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Actualizar Pipas de una estación

  updatePurchases(idPurchases: any, data: any, idGroup?: string, source?: string) {
    if (source) {
      return this.http.put<any>(`${this.endpoint}/purchases/${idPurchases}&group=${idGroup}&source=${source}`, data)
        .pipe(map(resp => {
          return resp;
        })
        );
    } else {
      return this.http.put<any>(`${this.endpoint}/purchases/${idPurchases}`, data)
        .pipe(map(resp => {
          return resp;
        })
        );
    }
  }

  // Borrar compras de una estación

  deletePurchase(idPurchase: any, idGroup?: string, source?: string) {
    if (source) {
      return this.http.delete<any>(`${this.endpoint}/purchases/${idPurchase}&group=${idGroup}&source=${source}`)
        .pipe(map(resp => {
          return resp;
        })
        );
    } else {
      return this.http.delete<any>(`${this.endpoint}/purchases/${idPurchase}`)
        .pipe(map(resp => {
          return resp;
        })
        );
    }
  }

  // Obtener info del Margen Actual

  getCurrentMargin() {
    return this.http.get(`${this.endpoint}/buying_price/margin/actual_margin?station=${this.idStation}`).pipe(
      map((x: any) => x?.actual_margin),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener info del Margen Actual

  getLayeredMargin(startDate, endDate) {
    return this.http.get(`${this.endpoint}/purchase_price/layer/detail?station=${this.idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener info del Margen Final y %

  getFinalMargin(daysAgo: any, month?: any) {
    if (month) {
      this.marginEvolution = this.http.get(`${this.endpoint}/buying_price/margin/margin_evolution?station=${this.idStation}&month=${month}`).pipe(
        map((x: any) => x?.margin_evolution),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.marginEvolution;
    } else {
      this.marginEvolution = this.http.get(`${this.endpoint}/buying_price/margin/margin_evolution?station=${this.idStation}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x?.margin_evolution),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.marginEvolution;
    }
  }

  // Obtener info del Margen Final y %

  getFinalEvol(daysAgo: number) {
    this.marginDays = this.http.get(`${this.endpoint}/buying_price/margin/margin_evolution?station=${this.idStation}&days_ago=${daysAgo}`).pipe(
      map((x: any) => x?.margin_evolution),
      shareReplay(1),
      catchError(this.processError)
    );
    return this.marginDays;
  }


  // Obtener precio y tráfico

  getTrafficHistoric(type: string, startDate, endDate) {
    this.marginDays = this.http.get(`${this.endpoint}/traffic/data?station=${this.idStation}&type=${type}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
    return this.marginDays;
  }

  // Obtener precio y tráfico

  getTrafficComparative() {
    this.marginDays = this.http.get(`${this.endpoint}/traffic/price_changes_data?station=${this.idStation}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
    return this.marginDays;
  }

  // Obtener info del Margen Periodo

  getMarginPeriod() {
    return this.http.get(`${this.endpoint}/buying_price/margin/margin_period?station=${this.idStation}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener info de la Seccion compra

  getLastUpdateBuy() {
    return this.http.get(`${this.endpoint}/purchase_price/tar/diffs/last_update`).pipe(
      map((x: any) => x?.data?.last_update),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener info del Precio Recomendado

  getPriceRecommendation(creId = null) {
    let url = `${this.endpoint}/purchase_price/tar/diffs/today_and_tomorrow`;
    if (!creId) url += `?station=${this.idStation}`;
    else url += `?creId=${creId}`;
    return this.http.get(url).pipe(
      map((x: any) => x?.data?.prices_and_diffs),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener info del Diferencial Promedio

  getAverageDifferential(daysAgo: any, month?: any) {
    if (month) {
      return this.http.get(`${this.endpoint}/purchase_price/tar/diffs/record?station=${this.idStation}&month=${month}`).pipe(
        map((x: any) => x?.data?.record),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/purchase_price/tar/diffs/record?station=${this.idStation}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x?.data?.record),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Precio Compra vs Precio Venta

  getVersus(daysAgo: any, month?: any) {
    if (month) {
      return this.http.get(`${this.endpoint}/buying_price/supply/versus?station=${this.idStation}&month=${month}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/buying_price/supply/versus?station=${this.idStation}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Precio Recomendado

  // Registrar ventas por producto

  registerSale(date: any, regular: any, premium: any, diesel: any) {

    regular = +regular === 0 ? null : +regular;
    premium = +premium === 0 ? null : +premium;
    diesel = +diesel === 0 ? null : +diesel;

    const data = {
      'date': date,
      'products': [
        { 'name': 'regular', 'liters': regular },
        { 'name': 'premium', 'liters': premium },
        { 'name': 'diesel', 'liters': diesel }
      ]
    };

    return this.http.post<any>(`${this.endpoint}/optimum_price/recommended_price/sale?station=${this.idStation}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Registrar las ventas mediante la plantilla excel

  uploadSalesRecord(record: any) {

    const formData = new FormData();
    formData.append('sales', record);

    return this.http.post<any>(`${this.endpoint}/optimum_price/recommended_price/sales?station=${this.idStation}`, formData)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Sobrescribir las ventas mediante la plantilla excel

  overwriteSalesRecord(record: any) {

    const formData = new FormData();
    formData.append('sales', record);

    return this.http.put<any>(`${this.endpoint}/optimum_price/recommended_price/sales?station=${this.idStation}`, formData)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Obtener las ventas de una estación

  getSales(startDate: any, endDate: any) {
    return this.http.get(`${this.endpoint}/optimum_price/recommended_price/sales?station=${this.idStation}&startdate=${startDate}&lastdate=${endDate}`).pipe(
      map((x: any) => x?.sales),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Actualizar ventas de una estación

  updateSale(idDiscount: any, date: any, regular: any, premium: any, diesel: any) {

    regular = +regular === 0 ? null : +regular;
    premium = +premium === 0 ? null : +premium;
    diesel = +diesel === 0 ? null : +diesel;

    const data = {
      'date': date,
      'products': [
        { 'name': 'regular', 'liters': regular },
        { 'name': 'premium', 'liters': premium },
        { 'name': 'diesel', 'liters': diesel }
      ]
    };

    return this.http.put<any>(`${this.endpoint}/optimum_price/recommended_price/sale/${idDiscount}?station=${this.idStation}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Borrar venta

  deleteSale(idDiscount: any) {
    return this.http.delete<any>(`${this.endpoint}/optimum_price/recommended_price/sale/${idDiscount}?station=${this.idStation}`)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Precio Ponderado de la Competencia

  getPpc(product: any) {
    return this.http.get(`${this.endpoint}/optimum_price/correlations_and_ppc?station=${this.idStation}&product=${product}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Indice de Precio

  getQuotient(product: any) {
    return this.http.get(`${this.endpoint}/optimum_price/current_price_index?station=${this.idStation}&product=${product}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Curva gráfica

  getDemandCurveEvolution(product: any) {
    return this.http.get(`${this.endpoint}/optimum_price/demand_curve_graph?station=${this.idStation}&product=${product}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Ganancias gráfica

  getEarningCurveEvolution(product: any) {
    return this.http.get(`${this.endpoint}/optimum_price/earning_curve_graph?station=${this.idStation}&product=${product}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Vista Mensual

  getMonth(indicator: any, period: any) {
    return this.http.get(`${this.endpoint}/optimum_price/earning/month?station=${this.idStation}&indicator=${indicator}&period=${period}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Seccion General (Tablero)

  // Actualizar preferencias variables precio

  setVariables(type: string, data: any) {
    return this.http.put(`${this.endpoint}/control_panel/custom/variables/${type}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Obtener preferencias variables precio

  getVariables(type: string) {
    return this.http.get(`${this.endpoint}/control_panel/custom/variables/${type}`).pipe(
      map((x: any) => x?.data?.vars),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardEarningZone(zone: any, group?: any, cluster?: string, cluster2?: string, cluster3?: any, cluster4?: any) {
    let clusterArray = cluster != null ? `&cluster_filter=${cluster}` : '';
    let clusterArray2 = cluster2 != null ? `&importants=${cluster2}` : '';
    let clusterArray3 = cluster3 != null ? `&cluster_3_filter=${cluster3}` : '';
    let clusterArray4 = cluster4 != null ? `&cluster_4_filter=${cluster4}` : '';
    if (group) {
      return this.http.get(`${this.endpoint}/control_panel/earning?group=${group}&zone=${zone}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/earning?zone=${zone}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardSaleZone(zone: any, group?: any, cluster?: string, cluster2?: string, cluster3?: any, cluster4?: any) {
    let clusterArray = cluster != null ? `&cluster_filter=${cluster}` : '';
    let clusterArray2 = cluster2 != null ? `&importants=${cluster2}` : '';
    let clusterArray3 = cluster3 != null ? `&cluster_3_filter=${cluster3}` : '';
    let clusterArray4 = cluster4 != null ? `&cluster_4_filter=${cluster4}` : '';
    if (group) {
      return this.http.get(`${this.endpoint}/control_panel/sale?group=${group}&zone=${zone}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/sale?zone=${zone}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardBudgetZone(group: any, idZone: any, products: any, period: string, date: string, closing: string, budget?: string) {
    let zone = '';
    if (idZone !== '' && idZone !== null) {
      zone = `&zones[]=${idZone}`
    }
    if (budget) {
      return this.http.get(`${this.endpoint}/control_panel/sales_and_budgets?group=${group}${zone}&products_filter=${products}&period=${period}&date=${date}&closing=${closing}&budget=${budget}`).pipe(
        map((x: any) => x?.data?.sales_and_budgets),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/sales_and_budgets?group=${group}${zone}&products_filter=${products}&period=${period}&date=${date}&closing=${closing}`).pipe(
        map((x: any) => x?.data?.sales_and_budgets),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardBudgetComparisonZone(group: any, idZone: any, products: any, budgets: string, period: string, date: string, closing: string) {
    let zone = '';
    if (idZone !== '' && idZone !== null) {
      zone = `&zones[]=${idZone}`
    }
    return this.http.get(`${this.endpoint}/control_panel/budgets_comparison?group=${group}${zone}&products_filter=${products}&period=${period}&date=${date}&${budgets}&closing=${closing}`).pipe(
      map((x: any) => x?.data?.comparation),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardTrackingZone(product: any, period: any, complete: any, zone: any, calculation: any, group?: any) {
    if (complete) {
      complete = 1;
    } else {
      complete = 0;
    }
    if (group) {
      return this.http.get(`${this.endpoint}/control_panel/sales/liters_avg?group=${group}&product=${product}&type=${period}&complete_period=${complete}&calculation=${calculation}&zone=${zone}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/sales/liters_avg?product=${product}&type=${period}&complete_period=${complete}&calculation=${calculation}&zone=${zone}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardAlerts(product: any, complement: any, period: any, group?: any) {
    if (group) {
      return this.http.get(`${this.endpoint}/trade_alerts?group=${group}&product=${product}${complement}${period}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/trade_alerts?product=${product}${complement}${period}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardAlertsZone(product: any, complement: any, zone: any, group?: any) {
    if (group) {
      return this.http.get(`${this.endpoint}/trade_alerts?group=${group}&product=${product}${complement}&zone=${zone}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/trade_alerts?product=${product}${complement}&zone=${zone}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardBinaccleAlerts(complement: any, daysAgo: any, period: any, group?: any) {
    if (group) {
      return this.http.get(`${this.endpoint}/trade_alerts/record?group=${group}&${complement}${daysAgo}${period}`).pipe(
        map((x: any) => x?.data?.alerts),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/trade_alerts/record?${complement}${daysAgo}${period}`).pipe(
        map((x: any) => x?.data?.alerts),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardBinaccleNewPrices(clusters: any, complement: any, daysAgo: any, group?: any) {
    clusters = clusters !== 'clusters_ids[]=' && clusters !== undefined ? clusters : '';
    if (group) {
      return this.http.get(`${this.endpoint}/selling_price/assignment/new_selling_prices/record?group=${group}&${clusters}${complement}${daysAgo}`).pipe(
        map((x: any) => x?.data?.new_selling_prices),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/selling_price/assignment/new_selling_prices/record?${clusters}${complement}${daysAgo}`).pipe(
        map((x: any) => x?.data?.new_selling_prices),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardBinaccleGeneral(clusters: any, complement: any, daysAgo: any, group?: any) {
    clusters = clusters !== 'clusters_ids[]=' && clusters !== undefined ? clusters : '';
    if (group) {
      return this.http.get(`${this.endpoint}/selling_price/assignment/new_selling_prices/plannings/record?group=${group}&${clusters}&${complement}${daysAgo}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/selling_price/assignment/new_selling_prices/plannings/record?${clusters}${complement}${daysAgo}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  commentPlanning(data: any, idPlanning: any) {
    return this.http.put(`${this.endpoint}/selling_price/assignment/new_selling_prices/plannings/${idPlanning}`, data)
      .pipe(map(station => {
        return station;
      })
      );
  }

  updateNewPrice(data: any, group: any, idPlanning: string, zones?: any, clusters?: any) {
    clusters = clusters !== 'clusters_ids[]=' && clusters !== undefined ? clusters.slice(0, -1) : '';
    zones = zones !== 'zones[]=' && zones !== undefined ? zones.slice(0, -1) : '';
    return this.http.put(`${this.endpoint}/selling_price/assignment/new_selling_prices?group=${group}&planning_id=${idPlanning}&${zones}&${clusters}`, data)
      .pipe(map(station => {
        return station;
      })
      );
  }

  updateChangeFx(data: any, group?: any) {
    return this.http.put(`${this.endpoint}/selling_price/assignment/fx?group=${group}`, data)
      .pipe(map(station => {
        return station;
      })
      );
  }

  cancelNewPrice(data: any, group: any) {
    return this.http.put(`${this.endpoint}/selling_price/assignment/new_selling_prices/cancel?group=${group}`, data)
      .pipe(map(station => {
        return station;
      })
      );
  }

  getDashboardAnalysis(date: any, zone: any, group?: any) {
    if (group) {
      return this.http.get(`${this.endpoint}/control_panel/selling_price_margin_purchase_price?group=${group}&date=${date}&zone=${zone}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/selling_price_margin_purchase_price?date=${date}&zone=${zone}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardAnalysisLegal(station: any, period: any, date: any) {
    return this.http.get(`${this.endpoint}/reports/legal_report/esmexico?station=${station}&type=${period}&date=${date}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  updateDashboardAnalysisLegal(idReport: any, data: any) {
    return this.http.put(`${this.endpoint}/reports/legal_report/esmexico?report=${idReport}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  getDashboardAnalysisLegalPurchases(station: any, date: any) {
    return this.http.get(`${this.endpoint}/purchases/accumulated/manual?station=${station}&date=${date}T06:00Z`).pipe(
      map((x: any) => x?.data?.purchases),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardAnalysisLegalSales(station: any, date: any) {
    return this.http.get(`${this.endpoint}/sales/accumulated/manual?station=${station}&date=${date}T06:00Z`).pipe(
      map((x: any) => x?.data?.sales),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  updateDashboardAnalysisLegalPurchases(data: any) {
    return this.http.put(`${this.endpoint}/purchases/accumulated/manual`, data).pipe(map(resp => {
      return resp;
    })
    );
  }

  updateDashboardAnalysisLegalSales(data: any) {
    return this.http.put(`${this.endpoint}/sales/accumulated/manual`, data).pipe(map(resp => {
      return resp;
    })
    );
  }

  getDashboardNationalPrice(group: any, calculationType: string, zones: any, clusters: any) {
    let zonesId, clustersId;
    if (zones === 'zones[]=' || zones === undefined || zones === '') {
      zonesId = '';
    } else {
      zonesId = `&${zones}`
    }

    if (clusters === 'clusters_ids[]=' || clusters === undefined) {
      clustersId = '';
    } else {
      clustersId = `&${clusters}`
    }
    return this.http.get(`${this.endpoint}/control_panel/analysis/selling_price/actual_and_proposition_v2?group=${group}&calculation=${calculationType}${zonesId}${clustersId}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener tablero directorio Enerser

  getDashboardDirectory(idGroup: string, zones: any, clusters: any) {
    let zonesId, clustersId;
    if (zones === 'zones[]=' || zones === undefined || zones === '') {
      zonesId = '';
    } else {
      zonesId = `&${zones}`
    }

    if (clusters === 'clusters_ids[]=' || clusters === undefined) {
      clustersId = '';
    } else {
      clustersId = `&${clusters}`
    }
    return this.http.get(`${this.endpoint}/control_panel/email/directory?group=${idGroup}${zonesId}${clustersId}`).pipe(
      map((x: any) => x?.data?.directory),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Guardar directorio Enerser

  saveDirectory(idGroup: string, data: any) {
    return this.http.post(`${this.endpoint}/control_panel/email/directory?group=${idGroup}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  getDashboardNationalFx(group: any, calculationType: string, zones: any, clusters: any) {
    let zonesId, clustersId;
    if (zones === 'zones[]=' || zones === undefined || zones === '') {
      zonesId = '';
    } else {
      zonesId = `&${zones}`
    }

    if (clusters === 'clusters_ids[]=' || clusters === undefined) {
      clustersId = '';
    } else {
      clustersId = `&${clusters}`
    }
    return this.http.get(`${this.endpoint}/control_panel/analysis/selling_price/actual_and_proposition_v2?group=${group}&calculation=${calculationType}${zonesId}${clustersId}&products[]=cambio_fx`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  checkPlanningExist(group: any, date: any, products?: string) {
    if (products) {
      return this.http.get(`${this.endpoint}/selling_price/assignment/new_selling_prices/plannings/exists?group=${group}&date=${date}&products=${products}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/selling_price/assignment/new_selling_prices/plannings/exists?group=${group}&date=${date}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }

  }

  sendPricePropositions(group: any, date: any, data: any, zones?: any, clusters?: any) {
    clusters = clusters !== 'clusters_ids[]=' && clusters !== undefined ? clusters.slice(0, -1) : '';
    zones = zones !== 'zones[]=' && zones !== undefined ? zones.slice(0, -1) : '';
    if (zones && clusters) {
      return this.http.put(`${this.endpoint}/selling_price/assignment/selling_prices_propositions/status?group=${group}&date=${date}&${zones}&${clusters}`, data).pipe(map(resp => {
        return resp;
      })
      );
    } else if (zones) {
      return this.http.put(`${this.endpoint}/selling_price/assignment/selling_prices_propositions/status?group=${group}&date=${date}&${zones}`, data).pipe(map(resp => {
        return resp;
      })
      );
    } else if (clusters) {
      return this.http.put(`${this.endpoint}/selling_price/assignment/selling_prices_propositions/status?group=${group}&date=${date}&${clusters}`, data).pipe(map(resp => {
        return resp;
      })
      );
    } else {
      return this.http.put(`${this.endpoint}/selling_price/assignment/selling_prices_propositions/status?group=${group}&date=${date}`, data).pipe(map(resp => {
        return resp;
      })
      );
    }
  }

  sendPricePropositionsFx(group: any, date: any, data: any, zones?: any, clusters?: any) {
    clusters = clusters !== 'clusters_ids[]=' && clusters !== undefined ? clusters.slice(0, -1) : '';
    zones = zones !== 'zones[]=' && zones !== undefined ? zones.slice(0, -1) : '';
    if (zones && clusters) {
      return this.http.put(`${this.endpoint}/selling_price/assignment/selling_prices_propositions/status?group=${group}&date=${date}&${zones}&${clusters}&products[]=cambio_fx`, data).pipe(map(resp => {
        return resp;
      })
      );
    } else if (zones) {
      return this.http.put(`${this.endpoint}/selling_price/assignment/selling_prices_propositions/status?group=${group}&date=${date}&${zones}&products[]=cambio_fx`, data).pipe(map(resp => {
        return resp;
      })
      );
    } else if (clusters) {
      return this.http.put(`${this.endpoint}/selling_price/assignment/selling_prices_propositions/status?group=${group}&date=${date}&${clusters}&products[]=cambio_fx`, data).pipe(map(resp => {
        return resp;
      })
      );
    } else {
      return this.http.put(`${this.endpoint}/selling_price/assignment/selling_prices_propositions/status?group=${group}&date=${date}&products[]=cambio_fx`, data).pipe(map(resp => {
        return resp;
      })
      );
    }
  }

  setPricePropositions(data: any) {
    return this.http.post(`${this.endpoint}/selling_price/assignment/selling_prices_propositions`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  deletePricePropositions(data: any, group: any) {
    return this.http.delete(`${this.endpoint}/selling_price/assignment/selling_prices_propositions?group=${group}`, { body: data })
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  deletePricePropositionsFx(data: any, group: any) {
    return this.http.delete(`${this.endpoint}/selling_price/assignment/selling_prices_propositions?group=${group}&products[]=cambio_fx`, { body: data })
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  getDashboardOptimumPrice(product: any, change: any, group: any, zone: any, data: any) {
    return this.http.post(`${this.endpoint}/control_panel/optimum_price?group=${group}&product=${product}&change=${change}&zone=${zone}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  getDashboardOptimumPriceSimulation(product: any, change: any, station: any, zone?: any) {
    return this.http.get(`${this.endpoint}/control_panel/optimum_price/simulator?station=${station}&product=${product}&change=${change}&zone=${zone}`).pipe(
      map((x: any) => x?.data?.changes),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardIndexPrice(product, variable, group: any, zone: any, period?: any) {
    if (zone === null) {
      zone = '';
    }

    if (period) {
      return this.http.get(`${this.endpoint}/dashboard/index_price?group=${group}&product=${product}&variable=${variable}&zone=${zone}&period=${period}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/dashboard/index_price?group=${group}&product=${product}&variable=${variable}&zone=${zone}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getBinaccleAlerts(complement: any, daysAgo: any, period: any) {
    return this.http.get(`${this.endpoint}/trade_alerts/record?station=${this.idStation}&${complement}${daysAgo}${period}`).pipe(
      map((x: any) => x?.data?.alerts),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getTiers(group: any, zone: any) {
    return this.http.get(`${this.endpoint}/general/tiers?group=${group}&zone=${zone}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getReasons(add?: any) {
    if (this.reasons && !add) {
      return this.reasons;
    } else {
      this.reasons = this.http.get(`${this.endpoint}/trade_alerts/causes`).pipe(
        map((x: any) => x?.data?.causes),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.reasons;
    }
  }

  setReason(data: any) {
    return this.http.put(`${this.endpoint}/trade_alerts/causes`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  setComment(data: any, idAlert: any) {
    return this.http.put(`${this.endpoint}/trade_alerts/${idAlert}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  deleteComment(idAlert: any) {
    return this.http.delete(`${this.endpoint}/trade_alerts/${idAlert}`)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  getTableAlertVariables(date: any, idStation: any, product: any) {
    return this.http.get(`${this.endpoint}/trade_alerts/variables?date=${date}&station=${idStation}&product=${product}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getChartSales(idStation: any) {
    return this.http.get(`${this.endpoint}/sales/record?station=${idStation}&forecast=1&adjusted=1&quotient=0&n=30&scatter=0`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getChartSalesSeasonally(idStation: any) {
    return this.http.get(`${this.endpoint}/sales/seasonally_adjusted?station=${idStation}&n=30`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getChartSalesOptimum(idStation: any, days: number, scatter?: number) {
    if (this.sales) {
      return this.sales;
    } else {
      return this.http.get(`${this.endpoint}/sales/record?station=${idStation}&forecast=0&adjusted=0&quotient=1&n=${days}&scatter=${scatter}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getChartTraffic(idStation: any) {
    return this.http.get(`${this.endpoint}/traffic/record?station=${idStation}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getChartQuotient(idStation: any, days: number) {
    return this.http.get(`${this.endpoint}/selling_price/quotient/record?station=${idStation}&n=${days}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getChartSellingPrice(idStation: any, days: number) {
    return this.http.get(`${this.endpoint}/selling_price/record/self?station=${idStation}&n=${days}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getEvolPriceHistory(idStation: any) {
    return this.http.get(`${this.endpoint}/selling_price/evolution?station=${idStation}&days_ago=30`).pipe(
      map((x: any) => x?.data?.evolution),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardMarginZone(zone: any, type: string, group?: any, cluster?: string, cluster2?: string, cluster3?: any, cluster4?: any) {
    let clusterArray = cluster != null ? `&cluster_filter=${cluster}` : '';
    let clusterArray2 = cluster2 != null ? `&importants=${cluster2}` : '';
    let clusterArray3 = cluster3 != null ? `&cluster_3_filter=${cluster3}` : '';
    let clusterArray4 = cluster4 != null ? `&cluster_4_filter=${cluster4}` : '';
    if (group) {
      return this.http.get(`${this.endpoint}/control_panel/margin?group=${group}&zone=${zone}&margin_type=${type}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
        map((x: any) => x?.data_format),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/margin&zone=${zone}&margin_type=${type}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
        map((x: any) => x?.data_format),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardPriceZone(zone: any, group?: any, cluster?: string, cluster2?: string, cluster3?: any, cluster4?: any) {
    let clusterArray = cluster != null ? `&cluster_filter=${cluster}` : '';
    let clusterArray2 = cluster2 != null ? `&importants=${cluster2}` : '';
    let clusterArray3 = cluster3 != null ? `&cluster_3_filter=${cluster3}` : '';
    let clusterArray4 = cluster4 != null ? `&cluster_4_filter=${cluster4}` : '';
    if (group) {
      return this.dashboardPrice = this.http.get(`${this.endpoint}/control_panel/price?group=${group}&zone=${zone}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.dashboardPrice = this.http.get(`${this.endpoint}/control_panel/price&zone=${zone}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardNewPrices(zones: any, date: any, clusters: any, group: any) {
    let zonesId, clustersId;
    if (zones === 'zones[]=' || zones === undefined || zones === '') {
      zonesId = '';
    } else {
      zonesId = `&${zones}`
    }

    if (clusters === 'clusters_ids[]=' || clusters === undefined) {
      clustersId = '';
    } else {
      clustersId = `&${clusters}`
    }
    return this.http.get(`${this.endpoint}/selling_price/assignment/new_selling_prices/detail?group=${group}${zonesId}${clustersId}&date=${date}&products[]=regular&products[]=premium&products[]=diesel&empty=1`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardNewChangeFx(zones: any, date: any, clusters: any, group: any) {
    let zonesId, clustersId;
    if (zones === 'zones[]=' || zones === undefined || zones === '') {
      zonesId = '';
    } else {
      zonesId = `&${zones}`
    }

    if (clusters === 'clusters_ids[]=' || clusters === undefined) {
      clustersId = '';
    } else {
      clustersId = `&${clusters}`
    }
    return this.http.get(`${this.endpoint}/selling_price/assignment/new_selling_prices/detail?group=${group}${zonesId}${clustersId}&date=${date}&products[]=cambio_fx&empty=1`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardNewPricesByPlanning(idPlanning: any, group: any, zones: any, clusters: any) {
    let zonesId, clustersId;
    if (zones === 'zones[]=' || zones === undefined || zones === '') {
      zonesId = '';
    } else {
      zonesId = `&${zones}`
    }

    if (clusters === 'clusters_ids[]=' || clusters === undefined) {
      clustersId = '';
    } else {
      clustersId = `&${clusters}`
    }
    return this.http.get(`${this.endpoint}/selling_price/assignment/new_selling_prices/detail?group=${group}&planning_id=${idPlanning}${zonesId}${clustersId}&products[]=regular&products[]=premium&products[]=diesel&empty=0`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardNewChangeFxByPlanning(idPlanning: any, group: any, zones: any, clusters: any) {
    let zonesId, clustersId;
    if (zones === 'zones[]=' || zones === undefined || zones === '') {
      zonesId = '';
    } else {
      zonesId = `&${zones}`
    }

    if (clusters === 'clusters_ids[]=' || clusters === undefined) {
      clustersId = '';
    } else {
      clustersId = `&${clusters}`
    }
    return this.http.get(`${this.endpoint}/selling_price/assignment/new_selling_prices/detail?group=${group}&planning_id=${idPlanning}${zonesId}${clustersId}&products[]=cambio_fx&empty=0`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getKeyIndicators(group: any, zone?: any, variable?: any, period?: any, type?: any, order?: any, stations?: boolean, showZones?: boolean, top?: any){
    let zoneVariable = zone != null ? `${zone}` : '';
    let periodVariable = period != null ? `${period}` : '';
    let typeVariable = type != null ? `${type}` : '';
    let topVariable = top != null ? `${top}` : '';
    let variableVariable = variable != null ? `${variable}` : '';
    let orderVariable = order != null ? `${order}` : '';

    return this.http.get(`${this.endpoint}/control_panel/one_page?group=${group}&zone=${zoneVariable}&period=${periodVariable}&type=${typeVariable}&top=${topVariable}&variable=${variableVariable}&order_variable=${orderVariable}&show_all_stations=${stations}&show_all_zones=${showZones}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }


  getEntityPrice(group: any, date: any){
    let url = `${this.endpoint}/reports/entities-resume?group=${group}&date=${date}`;
    return this.http.get(url).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getSellingNewPrices(zones: any, date: any, clusters: any, group?: any) {
    let zonesId, clustersId;
    if (zones === 'zones[]=' || zones === undefined || zones === '') {
      zonesId = '';
    } else {
      zonesId = `&${zones}`
    }

    if (clusters === 'clusters_ids[]=' || clusters === undefined) {
      clustersId = '';
    } else {
      clustersId = `&${clusters}`
    }
    return this.http.get(`${this.endpoint}/reports/selling_prices_new_changes/groups/${group}${zonesId}${clustersId}&date=${date}&products[]=regular&products[]=premium&products[]=diesel`).pipe(
      map((x: any) => x?.data?.formatted),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getSellingNewChangeFx(zones: any, date: any, clusters: any, group?: any) {
    let zonesId, clustersId;
    if (zones === 'zones[]=' || zones === undefined || zones === '') {
      zonesId = '';
    } else {
      zonesId = `&${zones}`
    }

    if (clusters === 'clusters_ids[]=' || clusters === undefined) {
      clustersId = '';
    } else {
      clustersId = `&${clusters}`
    }
    return this.http.get(`${this.endpoint}/reports/selling_prices_new_changes/groups/${group}${zonesId}${clustersId}&date=${date}&products[]=cambio_fx`).pipe(
      map((x: any) => x?.data?.formatted),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getSellingNewPricesByPlanning(idPlanning: any, group: any, typeOrder: string, order: string) {
    return this.http.get(`${this.endpoint}/reports/selling_prices_new_changes/groups/${group}?planning_id=${idPlanning}&order_by=${typeOrder}&order=${order}&products[]=regular&products[]=premium&products[]=diesel`).pipe(
      map((x: any) => x?.data?.formatted),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getSellingNewChangeFxByPlanning(idPlanning: any, group: any, typeOrder: string, order: string) {
    return this.http.get(`${this.endpoint}/reports/selling_prices_new_changes/groups/${group}?planning_id=${idPlanning}&order_by=${typeOrder}&order=${order}&products[]=cambio_fx`).pipe(
      map((x: any) => x?.data?.formatted),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getStatusCheckerByPlanning(idPlanning: any, group?: any) {
    return this.http.get(`${this.endpoint}/selling_price/final_selling_prices/check?group=${group}&planning_id=${idPlanning}`).pipe(
      map((x: any) => x?.data?.checker),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  updateStatusCheckerByPlanning(group: any, idPlanning: any, status: number, data: any) {
    return this.http.post(`${this.endpoint}/selling_price/final_selling_prices/check?group=${group}&status=${status}&planning_id=${idPlanning}`, data = { data })
      .pipe(map(resp => {
        return resp['data']['checker'];
      })
      );
  }

  getDashboardMap(group?: any) {
    if (group) {
      return this.http.get(`${this.endpoint}/control_panel/map?group=${group}`).pipe(
        map((x: any) => x?.data?.station_info),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/map`).pipe(
        map((x: any) => x?.data?.station_info),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardMapZone(zone: any, group?: any) {
    if (zone === null) {
      zone = '';
    }
    if (group) {
      return this.http.get(`${this.endpoint}/control_panel/map?group=${group}&zone=${zone}`).pipe(
        map((x: any) => x?.data?.station_info),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/map&zone=${zone}`).pipe(
        map((x: any) => x?.data?.station_info),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardComparative(group: any, variable: any, product: any, period: any, zone: any, importants: any, typeValue: any, n?: any, cluster?: any, cluster2?: any, cluster3?: any, cluster4?: any) {
    let clusterArray = cluster != null ? `&cluster_filter=${cluster}` : '';
    let clusterArray2 = cluster2 != null ? `&importants=${cluster2}` : '';
    let clusterArray3 = cluster3 != null ? `&cluster_3_filter=${cluster3}` : '';
    let clusterArray4 = cluster4 != null ? `&cluster_4_filter=${cluster4}` : '';
    if (zone !== '6178671b46818a57510e921d' && importants > 1) {
      importants = null;
    }
    if (importants !== null) {
      return this.dashboardComparative = this.http.get(`${this.endpoint}/dashboard/comparative_zones?group=${group}&variable=${variable}&product=${product}&period=${period}&type=${typeValue}&n=${n}&importants=${importants}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.dashboardComparative = this.http.get(`${this.endpoint}/dashboard/comparative_zones?group=${group}&variable=${variable}&product=${product}&period=${period}&type=${typeValue}&n=${n}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Variables vista mercado

  setMarketVariables(data) {
    return this.http.put(`${this.endpoint}/control_panel/custom/variables/market/section`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Variables vista mercado

  getMarketVariables() {
    return this.http.get(`${this.endpoint}/control_panel/custom/variables/market/section`).pipe(
      map((x: any) => x?.data?.categories),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Vista mercado

  getMarketGeneral(product: any, group: any, zone?: string) {
    if (zone === null) {
      zone = '';
    }
    return this.http.get(`${this.endpoint}/control_panel/market/general?product=${product}&group=${group}&zone=${zone}`).pipe(
      map((x: any) => x?.data?.general),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Vista mercado ubicacion

  getMarketLocation(group?: any, zone?: string) {
    if (zone === null) {
      zone = '';
    }
    return this.http.get(`${this.endpoint}/control_panel/market/location?group=${group}&zone=${zone}`).pipe(
      map((x: any) => x?.data?.location),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Vista mercado marca

  getMarketBrand(group?: any, zone?: string) {
    if (zone === null) {
      zone = '';
    }
    return this.http.get(`${this.endpoint}/control_panel/market/brand?group=${group}&zone=${zone}`).pipe(
      map((x: any) => x?.data?.brand),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Vista mercado precio

  getMarketSellingPrice(product: any, group?: any, zone?: string) {
    if (zone === null) {
      zone = '';
    }
    return this.http.get(`${this.endpoint}/control_panel/market/selling_price?product=${product}&group=${group}&zone=${zone}`).pipe(
      map((x: any) => x?.data?.selling_price),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Vista mercado margen

  getMarketMargin(product: any, group?: any, zone?: string) {
    if (zone === null) {
      zone = '';
    }
    return this.http.get(`${this.endpoint}/control_panel/market/margin?product=${product}&group=${group}&zone=${zone}`).pipe(
      map((x: any) => x?.data?.margin),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Vista Dashboard

  setSellingPrices(group: any, data: any, date?: any, zones?: any, clusters?: any) {
    clusters = clusters !== 'clusters_ids[]=' && clusters !== undefined ? clusters.slice(0, -1) : '';
    zones = zones !== 'zones[]=' && zones !== undefined ? zones.slice(0, -1) : '';
    if (zones && clusters && date) {
      return this.http.post(`${this.endpoint}/selling_price/assignment/final_prices?group=${group}&date=${date}&${zones}&${clusters}`, data)
        .pipe(map(resp => {
          return resp;
        })
        );
    } else if (date && clusters) {
      return this.http.post(`${this.endpoint}/selling_price/assignment/final_prices?group=${group}&date=${date}&${clusters}`, data)
        .pipe(map(resp => {
          return resp;
        })
        );
    } else if (date && zones) {
      return this.http.post(`${this.endpoint}/selling_price/assignment/final_prices?group=${group}&date=${date}&${zones}`, data)
        .pipe(map(resp => {
          return resp;
        })
        );
    } else if (date) {
      return this.http.post(`${this.endpoint}/selling_price/assignment/final_prices?group=${group}&date=${date}`, data)
        .pipe(map(resp => {
          return resp;
        })
        );
    } else {
      return this.http.post(`${this.endpoint}/selling_price/assignment/final_prices?group=${group}`, data)
        .pipe(map(resp => {
          return resp;
        })
        );
    }
  }

  deleteNewPrices(data: any, group) {
    return this.http.delete(`${this.endpoint}/selling_price/assignment/final_selling_prices?group=${group}`, { body: data })
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  setPriceVolumetric(data: any, group, date: any) {
    return this.http.post(`${this.endpoint}/selling_price/assignment/new_selling_prices/enerser?group=${group}&date=${date}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  updateFlagPriceVolumetric(group: any, status: any, planningId: any) {
    return this.http.put(`${this.endpoint}/selling_price/assignment/new_selling_prices/send_to_cvi?group=${group}&status=${status}&planning_id=${planningId}`, {})
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  setAdjust(data: any) {

    return this.http.put(`${this.endpoint}/purchases/adjust/upsert`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }


  savePurchases(data: any) {
    return this.http.put(`${this.endpoint}/purchases`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }
  // Vista bomba

  getStationsBomb(date: any, type: any) {
    return this.http.get(`${this.endpoint}/purchase/register_purchases?date=${date}&type=${type}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Vista Dashboard

  setPriceBomb(data: any) {
    return this.http.post(`${this.endpoint}/purchase/register_purchases`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // // Vista Dashboard

  // Vista Dashboard

  getPriceBomb() {
    return this.http.get(`${this.endpoint}/dashboard/price_recommended`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Panel Métricas

  getZonesDashboard(idGroup: any) {
    return this.http.get(`${this.endpoint}/dashboard/zones?group=${idGroup}`).pipe(
      map((x: any) => x?.data?.zones),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardSellingPricePurchase(idGroup: any, zone: any, period: any, importants: any, typeValue: any, n?: any, cluster?: any, cluster2?: any, cluster3?: any, cluster4?: any) {
    let clusterArray = cluster != null ? `&cluster_filter=${cluster}` : '';
    let clusterArray2 = cluster2 != null ? `&importants=${cluster2}` : '';
    let clusterArray3 = cluster3 != null ? `&cluster_3_filter=${cluster3}` : '';
    let clusterArray4 = cluster4 != null ? `&cluster_4_filter=${cluster4}` : '';
    if (zone !== '6178671b46818a57510e921d' && importants > 1) {
      importants = null;
    }
    if (zone === null || zone === 'null' || zone === '') {
      if (importants !== null) {
        return this.http.get(`${this.endpoint}/dashboard/selling_price_purchase_price?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}&importants=${importants}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/selling_price_purchase_price?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    } else {
      if (importants !== null) {
        return this.http.get(`${this.endpoint}/dashboard/selling_price_purchase_price?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}&importants=${importants}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/selling_price_purchase_price?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    }
  }

  getDashboardSellingPricePurchaseStation(period: any, typeValue: any, n: any) {
    return this.http.get(`${this.endpoint}/dashboard/selling_price_purchase_price?station=${this.idStation}&period=${period}&type=${typeValue}&n=${n}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardVolumenPurchaseMargin(idGroup: any, zone: any, period: any, importants: any, typeValue: any, typeVolume: string, n?: any, cluster?: any, cluster2?: any, cluster3?: any, cluster4?: any) {
    let clusterArray = cluster != null ? `&cluster_filter=${cluster}` : '';
    let clusterArray2 = cluster2 != null ? `&importants=${cluster2}` : '';
    let clusterArray3 = cluster3 != null ? `&cluster_3_filter=${cluster3}` : '';
    let clusterArray4 = cluster4 != null ? `&cluster_4_filter=${cluster4}` : '';
    if (zone !== '6178671b46818a57510e921d' && importants > 1) {
      importants = null;
    }
    if (zone === null || zone === 'null' || zone === '') {
      if (importants !== null) {
        return this.http.get(`${this.endpoint}/dashboard/volume_purchase_margin?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}&importants=${importants}&type_volume=${typeVolume}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/volume_purchase_margin?group=${idGroup}&period=${period}&type=${typeValue}&type_volume=${typeVolume}&n=${n}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    } else {
      if (importants !== null) {
        return this.http.get(`${this.endpoint}/dashboard/volume_purchase_margin?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&importants=${importants}&type_volume=${typeVolume}&n=${n}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/volume_purchase_margin?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&type_volume=${typeVolume}&n=${n}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    }
  }

  getDashboardVolumenPurchaseMarginStation(period: any, typeValue: any, typeVolume: string, n: any) {
    return this.http.get(`${this.endpoint}/dashboard/volume_purchase_margin?station=${this.idStation}&period=${period}&type=${typeValue}&type_volume=${typeVolume}&n=${n}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardTransactions(idGroup: any, zone: any, period: any, importants: any, typeValue: any, n?: any) {
    if (zone !== '6178671b46818a57510e921d' && importants > 1) {
      importants = null;
    }
    if (zone === null || zone === 'null' || zone === '') {
      if (importants !== null) {
        return this.http.get(`${this.endpoint}/dashboard/transactions?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}&importants=${importants}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/transactions?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    } else {
      if (importants !== null) {
        return this.http.get(`${this.endpoint}/dashboard/transactions?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}&importants=${importants}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/transactions?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    }
  }

  getDashboardTransactionsStation(period: any, typeValue: any, n: any) {
    return this.http.get(`${this.endpoint}/dashboard/transactions?station=${this.idStation}&period=${period}&type=${typeValue}&n=${n}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardAvgTransactions(idGroup: any, zone: any, period: any, importants: any, typeValue: any, n?: any) {
    if (zone !== '6178671b46818a57510e921d' && importants > 1) {
      importants = null;
    }
    if (zone === null || zone === 'null' || zone === '') {
      if (importants !== null) {
        return this.http.get(`${this.endpoint}/dashboard/avg_transactions?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}&importants=${importants}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/avg_transactions?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    } else {
      if (importants !== null) {
        return this.http.get(`${this.endpoint}/dashboard/avg_transactions?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}&importants=${importants}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/avg_transactions?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    }
  }

  getDashboardAvgTransactionsStation(period: any, typeValue: any, n: any) {
    return this.http.get(`${this.endpoint}/dashboard/avg_transactions?station=${this.idStation}&period=${period}&type=${typeValue}&n=${n}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardTarPricePurchasePrice(idGroup: any, zone: any, period: any, importants: any, typeValue: any, n?: any, cluster?: any, cluster2?: any, cluster3?: any, cluster4?: any) {
    let clusterArray = cluster != null ? `&cluster_filter=${cluster}` : '';
    let clusterArray2 = cluster2 != null ? `&importants=${cluster2}` : '';
    let clusterArray3 = cluster3 != null ? `&cluster_3_filter=${cluster3}` : '';
    let clusterArray4 = cluster4 != null ? `&cluster_4_filter=${cluster4}` : '';
    if (zone !== '6178671b46818a57510e921d' && importants > 1) {
      importants = null;
    }
    if (zone === null || zone === 'null' || zone === '') {
      if (importants !== null) {
        return this.http.get(`${this.endpoint}/dashboard/tar_price_purchase_price?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}&importants=${importants}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/tar_price_purchase_price?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    } else {
      if (importants !== null) {
        return this.http.get(`${this.endpoint}/dashboard/tar_price_purchase_price?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}&importants=${importants}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/tar_price_purchase_price?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    }
  }

  getDashboardTarPricePurchasePriceStation(period: any, typeValue: any, n: any) {
    return this.http.get(`${this.endpoint}/dashboard/tar_price_purchase_price?station=${this.idStation}&period=${period}&type=${typeValue}&n=${n}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardSellingPricePpc(idGroup: any, zone: any, period: any, importants: any, typeValue: any, n?: any, cluster?: any, cluster2?: any, cluster3?: any, cluster4?: any) {
    let clusterArray = cluster != null ? `&cluster_filter=${cluster}` : '';
    let clusterArray2 = cluster2 != null ? `&importants=${cluster2}` : '';
    let clusterArray3 = cluster3 != null ? `&cluster_3_filter=${cluster3}` : '';
    let clusterArray4 = cluster4 != null ? `&cluster_4_filter=${cluster4}` : '';
    if (zone !== '6178671b46818a57510e921d' && importants > 1) {
      importants = null;
    }
    if (zone === null || zone === 'null' || zone === '') {
      if (importants !== null) {
        return this.http.get(`${this.endpoint}/dashboard/selling_price_ppc?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}&importants=${importants}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/selling_price_ppc?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    } else {
      if (importants !== null) {
        return this.http.get(`${this.endpoint}/dashboard/selling_price_ppc?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}&importants=${importants}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/selling_price_ppc?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    }
  }

  getDashboardSellingPricePpcStation(period: any, typeValue: any, n: any) {
    return this.http.get(`${this.endpoint}/dashboard/selling_price_ppc?station=${this.idStation}&period=${period}&type=${typeValue}&n=${n}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardQuotientSellingPrice(idGroup: any, zone: any, period: any, importants: any, typeValue: any, n: any, cluster?: any, cluster2?: any, cluster3?: any, cluster4?: any) {
    let clusterArray = cluster != null ? `&cluster_filter=${cluster}` : '';
    let clusterArray2 = cluster2 != null ? `&importants=${cluster2}` : '';
    let clusterArray3 = cluster3 != null ? `&cluster_3_filter=${cluster3}` : '';
    let clusterArray4 = cluster4 != null ? `&cluster_4_filter=${cluster4}` : '';
    if (zone !== '6178671b46818a57510e921d' && importants > 1) {
      importants = null;
    }
    if (zone === null || zone === 'null' || zone === '') {
      if (importants !== null) {
        return this.http.get(`${this.endpoint}/dashboard/quotient_diff_selling_price?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}&importants=${importants}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/quotient_diff_selling_price?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    } else {
      if (importants !== null) {
        return this.http.get(`${this.endpoint}/dashboard/quotient_diff_selling_price?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}&importants=${importants}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/quotient_diff_selling_price?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    }
  }

  getDashboardQuotientSellingPriceStation(period: any, typeValue: any, n: any) {
    return this.http.get(`${this.endpoint}/dashboard/quotient_diff_selling_price?station=${this.idStation}&period=${period}&type=${typeValue}&n=${n}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardEarning(idGroup: any, zone: any, period: any, importants: any, typeValue: any, n: any, cluster?: any, cluster2?: any, cluster3?: any, cluster4?: any) {
    let clusterArray = cluster != null ? `&cluster_filter=${cluster}` : '';
    let clusterArray2 = cluster2 != null ? `&importants=${cluster2}` : '';
    let clusterArray3 = cluster3 != null ? `&cluster_3_filter=${cluster3}` : '';
    let clusterArray4 = cluster4 != null ? `&cluster_4_filter=${cluster4}` : '';
    if (zone !== '6178671b46818a57510e921d' && importants > 1) {
      importants = null;
    }
    if (zone === null || zone === 'null' || zone === '') {
      if (importants !== null) {
        return this.http.get(`${this.endpoint}/dashboard/earning?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}&importants=${importants}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x.data),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/earning?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x.data),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    } else {
      if (importants !== null) {
        return this.http.get(`${this.endpoint}/dashboard/earning?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}&importants=${importants}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x.data),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/earning?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
          map((x: any) => x.data),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    }
  }

  getDashboardEarningStation(period: any, typeValue: any, n: any) {
    return this.http.get(`${this.endpoint}/dashboard/earning?station=${this.idStation}&period=${period}&type=${typeValue}&n=${n}`).pipe(
      map((x: any) => x.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardPipesDetails(idGroup: string, product: string, zone: any, startDate: any, endDate: any, source?: string) {
    if (source) {
      return this.http.get(`${this.endpoint}/control_panel/purchases_details?group=${idGroup}&${product}&zone=${zone}&start_date=${startDate}&end_date=${endDate}&source=${source}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/purchases_details?group=${idGroup}&${product}&zone=${zone}&start_date=${startDate}&end_date=${endDate}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardBadPurchases(idGroup: string, product: string, zone: any, startDate: any, endDate: any, source?: string) {
    if (source) {
      return this.http.get(`${this.endpoint}/control_panel/bad_purchases?group=${idGroup}&${product}&zone=${zone}&start_date=${startDate}&end_date=${endDate}&source=${source}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/bad_purchases?group=${idGroup}&${product}&zone=${zone}&start_date=${startDate}&end_date=${endDate}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardVolumeQuotient(idGroup: any, zone: any, period: any, importants: any, typeValue: any, n?: any) {
    if (zone !== '6178671b46818a57510e921d' && importants > 1) {
      importants = null;
    }
    if (zone === null || zone === 'null' || zone === '') {
      if (importants !== null) {
        return this.http.get(`${this.endpoint}/dashboard/volume_quotient?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}&importants=${importants}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/volume_quotient?group=${idGroup}&period=${period}&type=${typeValue}&n=${n}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    } else {
      if (importants !== null) {
        return this.http.get(`${this.endpoint}/dashboard/volume_quotient?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}&importants=${importants}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      } else {
        return this.http.get(`${this.endpoint}/dashboard/volume_quotient?group=${idGroup}&zone=${zone}&period=${period}&type=${typeValue}&n=${n}`).pipe(
          map((x: any) => x?.data),
          shareReplay(1),
          catchError(this.processError)
        );
      }
    }
  }

  getDashboardVolumeQuotientStation(period: any, typeValue: any, n?: any) {
    return this.http.get(`${this.endpoint}/dashboard/volume_quotient?station=${this.idStation}&period=${period}&type=${typeValue}&n=${n}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDashboardMacroSellingPrice(idGroup: any, zone: any) {
    if (zone === null || zone === 'null' || zone === '') {
      return this.http.get(`${this.endpoint}/dashboard/selling_price_total_group?group=${idGroup}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/dashboard/selling_price_total_group?group=${idGroup}&zone=${zone}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardMacroMarginTar(idGroup: any, zone: any) {
    if (zone === null || zone === 'null' || zone === '') {
      return this.http.get(`${this.endpoint}/dashboard/margin_tar_total_group?group=${idGroup}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/dashboard/margin_tar_total_group?group=${idGroup}&zone=${zone}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getDashboardMacroWtiNyse() {
    return this.http.get(`${this.endpoint}/dashboard/wti_nyse`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Mapa de Calor

  getHeatMapMonthly(group: any, idStation: string, variable: string, date: string, def?: boolean) {
    if (def) {
      return this.http.get(`${this.endpoint}/heat_maps/month?group=${group}&zone=${idStation}&variable=${variable}&date=${date}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/heat_maps/month?group=${group}&stations[]=${idStation}&variable=${variable}&date=${date}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getHeatMapMonthlyByStation(group: any, variable: string, date: string) {
    return this.http.get(`${this.endpoint}/heat_maps/month?group=${group}&stations[]=${this.idStation}&variable=${variable}&date=${date}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getHeatMapDistribution(group: any, idStation: string, variable: string, date, def?: boolean) {
    if (def) {
      return this.http.get(`${this.endpoint}/heat_maps/total_by_product?group=${group}&zone=${idStation}&variable=${variable}&date=${date}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/heat_maps/total_by_product?group=${group}&stations[]=${idStation}&variable=${variable}&date=${date}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getHeatMapDistributionByStation(group: any, variable: string, date) {
    return this.http.get(`${this.endpoint}/heat_maps/total_by_product?group=${group}&stations[]=${this.idStation}&variable=${variable}&date=${date}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getHeatMapScorecard(group: any, idStation: string, product: string, date, def?: boolean) {
    if (def) {
      return this.http.get(`${this.endpoint}/heat_maps/types_transactions?group=${group}&zone=${idStation}&product=${product}&date=${date}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/heat_maps/types_transactions?group=${group}&stations[]=${idStation}&product=${product}&date=${date}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getHeatMapScorecardByStation(group: any, product: string, date) {
    return this.http.get(`${this.endpoint}/heat_maps/types_transactions?group=${group}&stations[]=${this.idStation}&product=${product}&date=${date}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getHeatMapShift(group: any, idStation: string, variable: string, product: string, shift: number, date: string, def?: boolean) {
    if (def) {
      return this.http.get(`${this.endpoint}/heat_maps?group=${group}&zone=${idStation}&variable=${variable}&product=${product}&shift=shift${shift}&date=${date}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/heat_maps?group=${group}&stations[]=${idStation}&variable=${variable}&product=${product}&shift=shift${shift}&date=${date}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getHeatMapShiftByStation(group: any,  variable: string, product: string, shift: number, date: string) {
    return this.http.get(`${this.endpoint}/heat_maps?group=${group}&stations[]=${this.idStation}&variable=${variable}&product=${product}&shift=shift${shift}&date=${date}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getHeatMapDayWeek(group: any, idStation: string, variable: string, product: string, date, byStation?: boolean) {
    const station = byStation ? this.idStation : idStation;
    return this.http.get(`${this.endpoint}/heat_maps/day_of_week?group=${group}&stations[]=${station}&variable=${variable}&product=${product}&date=${date}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getHeatMapHour(group: any, idStation: string, variable: string, product: string, date, byStation?: boolean) {
    const station = byStation ? this.idStation : idStation;
    return this.http.get(`${this.endpoint}/heat_maps/hours?group=${group}&stations[]=${station}&variable=${variable}&product=${product}&date=${date}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getHeatMapTotalShift(group: any, idStation: string, variable: string, product: string, date, byStation?: boolean) {
    const station = byStation ? this.idStation : idStation;
    return this.http.get(`${this.endpoint}/heat_maps/total_by_shift?group=${group}&stations[]=${station}&variable=${variable}&product=${product}&date=${date}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Vista Cuadrante

  getProfiability(product: any, daysAgo: any) {
    return this.http.get(`${this.endpoint}/dashboard/profiability_graph?product=${product}&days_ago=${daysAgo}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Sección Seguimiento

  // Precio Optimo vs Precio Venta

  getPriceOptimumCurrent(daysAgo: any, month?: any) {
    if (month) {
      return this.http.get(`${this.endpoint}/optimum_price/recommended_price/optimum_current?station=${this.idStation}&month=${month}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/optimum_price/recommended_price/optimum_current?station=${this.idStation}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Diferencia en Utilidad Bruta

  getDiffEarning(daysAgo: any, month?: any) {
    if (month) {
      return this.http.get(`${this.endpoint}/optimum_price/recommended_price/diff_earning?station=${this.idStation}&month=${month}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/optimum_price/recommended_price/diff_earning?station=${this.idStation}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Seccion Precio Recomendado

  getLastUpdateRecommended() {
    return this.http.get(`${this.endpoint}/optimum_price/recommended_price/last_update`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMaxSales() {
    if (this.maxSales) {
      return this.maxSales;
    } else {
      this.maxSales = this.http.get(`${this.endpoint}/sales/period/max?station=${this.idStation}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.maxSales;
    }
  }

  getSalesQuartilSplitZones(product: string, daysAgo: any, subsubdivision: number) {
    return this.http.get(`${this.endpoint}/optimum_price/has_subdivisions?station=${this.idStation}&product=${product}&days=${daysAgo}`).pipe(
      map((x: any) => x?.data?.has_subdivisions),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getChartSalesQuartil(product: string, variable: string, daysAgo: any, subdivision = 0, subsubdivision = 0) {
    let queryParams = `station=${this.idStation}&product=${product}&variable=${variable}&days=${daysAgo}`;
    if (subdivision !== 0) queryParams += `&with_subdivisions=${1}`;
    if (subsubdivision !== 0) queryParams += `&with_subsubdivisions=${1}`;
    return this.http.get(`${this.endpoint}/sales/markets/graph?${queryParams}`).pipe(
      map((x: any) => x?.data?.markets),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getTableSalesQuartil(product: string, variable: string, daysAgo: any, subdivision = 0, subsubdivision = 0) {
    let queryParams = `station=${this.idStation}&product=${product}&variable=${variable}&days=${daysAgo}`;
    if (subdivision !== 0) queryParams += `&with_subdivisions=${1}`;
    if (subsubdivision !== 0) queryParams += `&with_subsubdivisions=${1}`;
    return this.http.get(`${this.endpoint}/sales/markets?${queryParams}`).pipe(
      map((x: any) => x?.data?.markets),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getChartSalesQuartilComparative(product: string) {
    return this.http.get(`${this.endpoint}/optimum_price/markets/graph/180_30?station=${this.idStation}&product=${product}`).pipe(
      map((x: any) => x?.data?.graphs),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getTableSalesQuartilComparative(product: string) {
    return this.http.get(`${this.endpoint}/optimum_price/periods/comparative/180_30?station=${this.idStation}&product=${product}`).pipe(
      map((x: any) => x?.data?.periods),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getTypePxConfig() {
    return this.http.get(`${this.endpoint}/optimum_price/strategy?station=${this.idStation}`).pipe(
      map((x: any) => x?.data?.strategy),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  saveTypePxConfig(typePx, idStation?: string) {
    const station = idStation ? idStation : this.idStation;
    const data = {
      'data': {
        'strategy': typePx
      }
    };

    return this.http.put(`${this.endpoint}/optimum_price/strategy?station=${station}`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  getDiffPxMax(product: string, variable: string, daysAgo: any) {
    return this.http.get(`${this.endpoint}/optimum_price/diff_pmin?station=${this.idStation}&product=${product}&variable=${variable}&type=max&days=${daysAgo}`).pipe(
      map((x: any) => x?.data?.levels),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMaxMarkets(product: string, max: number, variable: string, daysAgo: any) {
    return this.http.get(`${this.endpoint}/optimum_price/markets/min?station=${this.idStation}&product=${product}&min_and_no_min=${max}&variable=${variable}&type=max&days=${daysAgo}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getDiffPxMin(product: string, variable: string, daysAgo: any) {
    return this.http.get(`${this.endpoint}/optimum_price/diff_pmin?station=${this.idStation}&product=${product}&variable=${variable}&type=min&days=${daysAgo}`).pipe(
      map((x: any) => x?.data?.levels),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMinMarkets(product: string, min: number, variable: string, daysAgo: any) {
    return this.http.get(`${this.endpoint}/optimum_price/markets/min?station=${this.idStation}&product=${product}&min_and_no_min=${min}&variable=${variable}&type=min&days=${daysAgo}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getLevelsActual(product: string) {
    return this.http.get(`${this.endpoint}/optimum_price/sales/levels?station=${this.idStation}&product=${product}`).pipe(
      map((x: any) => x?.data?.levels),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getLevelsActualScatter(product: string) {
    return this.http.get(`${this.endpoint}/optimum_price/sale_quotient/scatter_plot?station=${this.idStation}&product=${product}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Sección Reglas

  // Obtener Reglas

  getListPriceRules(idStation: any, product: any) {
    return this.http.get(`${this.endpoint}/price/price_rules?station=${idStation}&product=${product}`).pipe(
      map((x: any) => x?.rules),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Seleccionar Regla

  // Registra una Regla

  registerRule(rule: any, product: any, idStation: any, idRule: any, competitors: any, value: any, unit: any, operator: any, idActiveRule?: any) {

    let data;

    if (rule === 1) {
      data = {
        'product': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'hwmCompetitors': 'all',
              'competitors': [],
              'operations': [],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 2) {
      if (operator === 'equal') {
        operator = 'add';
      }

      if (operator !== 'without') {
        data = {
          'product': product,
          'station': idStation,
          'rule': idRule,
          'composition': {
            'type': 'simple',
            'rules': [
              {
                'rule': idRule,
                'competitors': competitors[0],
                'operations': [
                  { 'type': operator, 'value': value, 'unit': unit, 'operator': 'arithmetic' }
                ],
                'priority': 1
              }
            ]
          },
          'addRule': 'false'
        };
      } else {
        data = {
          'product': product,
          'station': idStation,
          'rule': idRule,
          'composition': {
            'type': 'simple',
            'rules': [
              {
                'rule': idRule,
                'competitors': competitors[0],
                'operations': [],
                'priority': 1
              }
            ]
          },
          'addRule': 'false'
        };
      }
    } else if (rule === 3 || rule === 4) {
      data = {
        'product': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'competitors': competitors,
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 5 || rule === 6) {
      data = {
        'product': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'competitors': competitors,
              'operations': [
                { 'type': 'subtract', 'value': value, 'unit': unit, 'operator': 'arithmetic' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 7) {
      data = {
        'product': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'operations': [
                { 'type': 'equal', 'value': value, 'unit': unit, 'operator': 'comparison' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 8) {
      data = {
        'product': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'operations': [
                { 'type': 'major_equal', 'value': value, 'unit': unit, 'operator': 'comparison' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 9) {
      data = {
        'product': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'operations': [
                { 'type': 'minor_equal', 'value': value, 'unit': unit, 'operator': 'comparison' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 10) {
      data = {
        'product': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'competitors': competitors,
              'operations': [
                { 'type': operator, 'value': value, 'unit': unit, 'operator': 'comparison' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 11) {
      data = {
        'product': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'operations': [
                { 'type': 'add', 'value': value, 'unit': unit, 'operator': 'arithmetic' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 12 && product === 'premium') {
      data = {
        'product': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'operations': [
                { 'type': 'add', 'value': value, 'unit': unit, 'operator': 'arithmetic' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    }

    if (idActiveRule) {
      return this.http.put(`${this.endpoint}/price/price_rules/station_rules/${idActiveRule}`, data = { data })
        .pipe(map(station => {
          return station;
        })
        );
    } else {
      return this.http.post<any>(`${this.endpoint}/price/price_rules/station_rule`, data)
        .pipe(map(resp => {
          return resp;
        })
        );
    }

  }

  // Registra una Segunda Regla

  registerRuleSecondary(rule: any, product: any, idStation: any, idRule: any, competitors: any, value: any, unit: any, operator: any, idActiveRule?: any) {

    let data;

    if (rule === 1) {
      data = {
        'product': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'hwmCompetitors': 'all',
              'competitors': [],
              'operations': [],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 2) {
      if (operator === 'equal') {
        operator = 'add';
      }

      if (operator !== 'without') {
        data = {
          'product': product,
          'station': idStation,
          'rule': idRule,
          'composition': {
            'type': 'simple',
            'rules': [
              {
                'rule': idRule,
                'competitors': competitors[0],
                'operations': [
                  { 'type': operator, 'value': value, 'unit': unit, 'operator': 'arithmetic' }
                ],
                'priority': 1
              }
            ]
          },
          'addRule': 'false'
        };
      } else {
        data = {
          'product': product,
          'station': idStation,
          'rule': idRule,
          'composition': {
            'type': 'simple',
            'rules': [
              {
                'rule': idRule,
                'competitors': competitors[0],
                'operations': [],
                'priority': 1
              }
            ]
          },
          'addRule': 'false'
        };
      }
    } else if (rule === 3 || rule === 4) {
      data = {
        'product': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'competitors': competitors,
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 5 || rule === 6) {
      data = {
        'product': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'competitors': competitors,
              'operations': [
                { 'type': 'subtract', 'value': value, 'unit': unit, 'operator': 'arithmetic' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 7) {
      data = {
        'product': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'operations': [
                { 'type': 'equal', 'value': value, 'unit': unit, 'operator': 'comparison' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 8) {
      data = {
        'product': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'operations': [
                { 'type': 'major_equal', 'value': value, 'unit': unit, 'operator': 'comparison' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 9) {
      data = {
        'product': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'operations': [
                { 'type': 'minor_equal', 'value': value, 'unit': unit, 'operator': 'comparison' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 10) {
      data = {
        'product': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'competitors': competitors,
              'operations': [
                { 'type': operator, 'value': value, 'unit': unit, 'operator': 'comparison' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 11) {
      data = {
        'product': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'operations': [
                { 'type': 'add', 'value': value, 'unit': unit, 'operator': 'arithmetic' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    } else if (rule === 12 && product === 'premium') {
      data = {
        'product': product,
        'station': idStation,
        'rule': idRule,
        'composition': {
          'type': 'simple',
          'rules': [
            {
              'rule': idRule,
              'operations': [
                { 'type': 'add', 'value': value, 'unit': unit, 'operator': 'arithmetic' }
              ],
              'priority': 1
            }
          ]
        },
        'addRule': 'false'
      };
    }

    if (idActiveRule) {
      return this.http.put(`${this.endpoint}/price/price_rules/station_rules/${idActiveRule}?secondary=true`, data = { data })
        .pipe(map(station => {
          return station;
        })
        );
    } else {
      return this.http.post<any>(`${this.endpoint}/price/price_rules/station_rule?secondary=true`, data)
        .pipe(map(resp => {
          return resp;
        })
        );
    }
  }

  // Registrar una nueva regla

  registerNewRuleC(data: any) {
    return this.http.post<any>(`${this.endpoint}/price/price_rules/station_rule`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Registrar una nueva segunda regla

  registerNewRuleCSecondary(data: any) {
    return this.http.post<any>(`${this.endpoint}/price/price_rules/station_rule?secondary=true`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Obtener regla por producto

  getActiveRule(idStation: any, product: any) {
    return this.http.get(`${this.endpoint}/price/price_rules/station_rules?station=${idStation}&product=${product}&status[]=active`).pipe(
      map((x: any) => x?.station_rule),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener segunda regla por producto

  getActiveRuleSecondary(idStation: any, product: any) {
    return this.http.get(`${this.endpoint}/price/price_rules/station_rules?station=${idStation}&product=${product}&status[]=active&secondary=true`).pipe(
      map((x: any) => x?.station_rule),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Activar o Desactivar regla

  statusRule(idRule: any, status: any) {
    const data = {
      'status': status
    };

    return this.http.put(`${this.endpoint}/price/price_rules/station_rules/${idRule}/status`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Activar o Desactivar segunda regla

  statusRuleSecondary(idRule: any, status: any) {
    const data = {
      'status': status
    };

    return this.http.put(`${this.endpoint}/price/price_rules/station_rules/${idRule}/status`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Obtener Reporte Reglas PetroFigues

  getReportPriceRules() {
    return this.http.get(`${this.endpoint}/reports/selling_price_pricing_rules_supply_price`).pipe(
      map((x: any) => x?.data?.formatted),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Subir compras

  uploadPurchases(data: any) {

    return this.http.post(`${this.endpoint}/purchases`, data)
      .pipe(map(purchases => {
        return purchases;
      })
      );
  }

  // Subir sales

  uploadSales(data: any) {

    return this.http.post(`${this.endpoint}/sales?type_register=excel`, data)
      .pipe(map(sales => {
        return sales;
      })
      );
  }

  // Subir inventario

  uploadStock(data: any) {

    return this.http.post(`${this.endpoint}/stock?type_register=excel`, data)
      .pipe(map(stock => {
        return stock;
      })
      );
  }

  // Subir presupuesto

  uploadBudgets(group: any, data: any) {
    return this.http.put(`${this.endpoint}/sales/budgets?group=${group}`, data)
      .pipe(map(budgets => {
        return budgets;
      })
      );
  }

  // Obtener presupuesto

  getBudgets(group: any) {
    return this.http.get(`${this.endpoint}/sales/budgets_logs?group=${group}`).pipe(
      map((x: any) => x?.data?.budgets),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener stock

  getStock() {
    return this.http.get(`${this.endpoint}/stock?station=${this.idStation}`).pipe(
      map((x: any) => x?.data?.stock),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener ult stock

  getLastStock() {
    return this.http.get(`${this.endpoint}/stock/last_stock?station=${this.idStation}`).pipe(
      map((x: any) => x?.data?.stock),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Actualizar stock

  registerStock(date: any, regular: any, premium: any, diesel: any) {

    regular = +regular === 0 ? null : +regular;
    premium = +premium === 0 ? null : +premium;
    diesel = +diesel === 0 ? null : +diesel;

    const data = {
      'data': {
        'stock': {
          'station': this.idStation,
          'date': date,
          'products': [
            {
              'name': 'regular',
              'liters': regular
            },
            {
              'name': 'premium',
              'liters': premium
            },
            {
              'name': 'diesel',
              'liters': diesel
            }
          ]
        }
      }
    };

    return this.http.post(`${this.endpoint}/stock/stock_register`, data)
      .pipe(map(station => {
        return station;
      })
      );
  }

  registerProductStock(data) {
    return this.http.put(`${this.endpoint}/stock/`, data)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Actualizar stock

  updateStock(idStock: any, data: any) {
    return this.http.put(`${this.endpoint}/stock/${idStock}`, data)
      .pipe(map(station => {
        return station;
      })
      );
  }

  // Borrar stock

  deleteStock(idStock: any) {
    return this.http.delete<any>(`${this.endpoint}/stock/${idStock}`)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Obtener evolución stock

  getEvolutionStock(daysAgo: any, month?: any) {
    if (month) {
      return this.http.get(`${this.endpoint}/supply_price/price_stock_hist?station=${this.idStation}&month=${month}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/supply_price/price_stock_hist?station=${this.idStation}&last_days=${daysAgo}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getSuplyPrice(date: any) {
    return this.http.get(`${this.endpoint}/supply_price?station=${this.idStation}&date=${date}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Actualizar regla

  updateRuleC(idRule: any, data: any) {
    return this.http.put(`${this.endpoint}/price/price_rules/station_rules/${idRule}`, data = { data })
      .pipe(map(station => {
        return station;
      })
      );
  }

  // Actualizar segunda regla

  updateRuleCSecondary(idRule: any, data: any) {
    return this.http.put(`${this.endpoint}/price/price_rules/station_rules/${idRule}?secondary=true`, data = { data })
      .pipe(map(station => {
        return station;
      })
      );
  }

  // Obtener histórico de reglas

  getPriceRuleCurrent(daysAgo: any, month?: any) {
    if (month) {
      return this.http.get(`${this.endpoint}/price/price_rules/rule_current?station=${this.idStation}&month=${month}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/price/price_rules/rule_current?station=${this.idStation}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Obtener reporte CORPOCONTA

  getReportCorpoconta(idGroup: string, valeroPurchasesMonth: string) {
    return this.http.get(`${this.endpoint}/reports/corpoconta_report?group=${idGroup}&month=${valeroPurchasesMonth}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte CORPOCONTA 2

  getReportDetailsCorpoconta(idGroup: string, date: string) {
    return this.http.get(`${this.endpoint}/reports/details_corpoconta?group=${idGroup}&date=${date}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte stock CORPOCONTA

  getReportStockCorpoconta(idGroup: string, date: string) {
    return this.http.get(`${this.endpoint}/reports/stock_corpoconta?group=${idGroup}&date=${date}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte stock CORPOCONTA

  getReportSalesCorpoconta(idGroup: string, date: string) {
    return this.http.get(`${this.endpoint}/reports/sales_corpoconta?group=${idGroup}&date=${date}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte ESMEXICO

  getReportCompareSellingPrice(idGroup: string, date: string) {
    return this.http.get(`${this.endpoint}/reports/price_differences?group=${idGroup}&date=${date}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte comparativa TAR

  getComparativeTar(idStation: string, month: string) {
    return this.http.get(`${this.endpoint}/reports/comparative_tar?station_id=${idStation}&month=${month}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte GILGA

  getReportGilga() {
    return this.http.get(`${this.endpoint}/reports/groups/Gilga/margin`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Org Rodriguez

  getReportSellingPriceRodriguez(startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/selling_price/org_rodriguez?start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte cruzado de precios Rendichicas

  getReportCrossRendichicas(idGroup: string, product: string, zone: any) {
    return this.http.get(`${this.endpoint}/reports/cross/groups/${idGroup}?product=${product}&zone=${zone}`).pipe(
      map((x: any) => x?.data?.formatted),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Margen Utilidad

  getReportMarginUtilityRendichicas(date: string) {
    return this.http.get(`${this.endpoint}/reports/rch/utility?date=${date}&station=${this.idStation}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte cruzado de precios Rendichicas

  getReportCrossSalesRendichicas(idGroup: string, zone: any, variable: string, secondVariable: string, productType: string, product: string, statistic: string, cluster?: string, cluster2?: string, cluster3?: any, cluster4?: any) {
    let clusterArray = cluster === null ? '' : `&cluster_filter=${cluster}`;
    let clusterArray2 = cluster2 != null ? `&importants=${cluster2}` : '';
    let clusterArray3 = cluster3 != null ? `&cluster_3_filter=${cluster3}` : '';
    let clusterArray4 = cluster4 != null ? `&cluster_4_filter=${cluster4}` : '';
    let zoneArray = zone === null ? '' : `&zone=${zone}`;
    if (productType === 'products' && product !== 'all') {
      return this.http.get(`${this.endpoint}/control_panel/rch_sales?group=${idGroup}${zoneArray}&variable=${variable}&variable2=${secondVariable}&products_filter=${productType}&product=${product}&year=${statistic}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
        map((x: any) => x?.data?.formatted),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/rch_sales?group=${idGroup}${zoneArray}&variable=${variable}&variable2=${secondVariable}&products_filter=${productType}&year=${statistic}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
        map((x: any) => x?.data?.formatted),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getExcelReportCrossSalesRendichicas(idGroup: string, zone: any, variable: string, secondVariable: string, productType: string, product: string, statistic: string, cluster?: string, cluster2?: string, cluster3?: any, cluster4?: any) {
    let clusterArray = cluster != null ? `&cluster_filter=${cluster}` : '';
    let clusterArray2 = cluster2 != null ? `&importants=${cluster2}` : '';
    let clusterArray3 = cluster3 != null ? `&cluster_3_filter=${cluster3}` : '';
    let clusterArray4 = cluster4 != null ? `&cluster_4_filter=${cluster4}` : '';
    let zoneArray = zone === null ? '' : `&zone=${zone}`;
    if (productType === 'products' && product !== 'all') {
      return this.http.get(`${this.endpoint}/control_panel/rch_sales?group=${idGroup}${zoneArray}&variable=${variable}&variable2=${secondVariable}&products_filter=${productType}&product=${product}&year=${statistic}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}&excel_format=true`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/rch_sales?group=${idGroup}${zoneArray}&variable=${variable}&variable2=${secondVariable}&products_filter=${productType}&year=${statistic}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}&excel_format=true`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Obtener reporte cruzado de precios Rendichicas

  getReportCrossTotalSalesRendichicas(idGroup: string, zone: any) {
    let zoneArray = zone === null ? '' : `&zone=${zone}`;
    return this.http.get(`${this.endpoint}/control_panel/rch_stations_sales?group=${idGroup}${zoneArray}&product=total`).pipe(
      map((x: any) => x?.data?.formatted),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte cruzado de precios Rendichicas

  getExcelReportCrossTotalSalesRendichicas(idGroup: string, zone: any) {
    let zoneArray = zone === null ? '' : `&zone=${zone}`;
    return this.http.get(`${this.endpoint}/control_panel/rch_stations_sales?group=${idGroup}${zoneArray}&product=total&excel_format=true`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte cruzado de precios Rendichicas

  getReportCrossSalesBrandsRendichicas(idGroup: string, zone: any, variable: string, secondVariable: string, productType: string, product: string, statistic: string, showStations: boolean, cluster: any, cluster1?: any, cluster2?: any, cluster3?: any, cluster4?: any) {
    let clusterArray = cluster1 === null ? '' : `&cluster_filter=${cluster1}`;
    let clusterArray2 = cluster2 != null ? `&importants=${cluster2}` : '';
    let clusterArray3 = cluster3 != null ? `&cluster_3_filter=${cluster3}` : '';
    let clusterArray4 = cluster4 != null ? `&cluster_4_filter=${cluster4}` : '';
    let zoneArray = zone === null ? '' : `&zone=${zone}`;
    if (productType === 'products' && product !== 'all') {
      return this.http.get(`${this.endpoint}/control_panel/rch_sales_brand?group=${idGroup}${zoneArray}&variable=${variable}&variable2=${secondVariable}&products_filter=${productType}&product=${product}&year=${statistic}&zone_stations=${showStations}&cluster=${cluster}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
        map((x: any) => x?.data?.formatted),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/rch_sales_brand?group=${idGroup}${zoneArray}&variable=${variable}&variable2=${secondVariable}&products_filter=${productType}&year=${statistic}&zone_stations=${showStations}&cluster=${cluster}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
        map((x: any) => x?.data?.formatted),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Obtener reporte cruzado de precios Rendichicas

  getExcelReportCrossSalesBrandsRendichicas(idGroup: string, zone: any, variable: string, secondVariable: string, productType: string, product: string, statistic: string, showStations: boolean, cluster: any, cluster1?: any, cluster2?: any, cluster3?: any, cluster4?: any) {
    let clusterArray = cluster1 === null ? '' : `&cluster_filter=${cluster1}`;
    let clusterArray2 = cluster2 != null ? `&importants=${cluster2}` : '';
    let clusterArray3 = cluster3 != null ? `&cluster_3_filter=${cluster3}` : '';
    let clusterArray4 = cluster4 != null ? `&cluster_4_filter=${cluster4}` : '';
    let zoneArray = zone === null ? '' : `&zone=${zone}`;
    if (productType === 'products' && product !== 'all') {
      return this.http.get(`${this.endpoint}/control_panel/rch_sales_brand?group=${idGroup}${zoneArray}&variable=${variable}&variable2=${secondVariable}&products_filter=${productType}&product=${product}&year=${statistic}&zone_stations=${showStations}&cluster=${cluster}&cluster=${cluster}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}&excel_format=true`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/rch_sales_brand?group=${idGroup}${zoneArray}&variable=${variable}&variable2=${secondVariable}&products_filter=${productType}&year=${statistic}&zone_stations=${showStations}&cluster=${cluster}&cluster=${cluster}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}&excel_format=true`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Obtener reporte cruzado de precios Rendichicas

  getReportCrossSalesBrandsStationsRendichicas(idGroup: string, zone: any, variable: string, secondVariable: string, product: string, statistic: string, cluster?: any, cluster2?: any, cluster3?: any, cluster4?: any) {
    let clusterArray = cluster != null ? `&cluster_filter=${cluster}` : '';
    let clusterArray2 = cluster2 != null ? `&importants=${cluster2}` : '';
    let clusterArray3 = cluster3 != null ? `&cluster_3_filter=${cluster3}` : '';
    let clusterArray4 = cluster4 != null ? `&cluster_4_filter=${cluster4}` : '';
    let zoneArray = zone === null ? '' : `&zone=${zone}`;
    return this.http.get(`${this.endpoint}/control_panel/rch_sales_brand_product?group=${idGroup}${zoneArray}&variable=${variable}&variable2=${secondVariable}&product=${product}&year=${statistic}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
      map((x: any) => x?.data?.formatted),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte cruzado de precios Rendichicas

  getReportCrossSalesEvolutionRendichicas(idGroup: string, zone: any, product: string, tier: any, period: string, n: any, variable?: any) {
    if (variable) {
      return this.http.get(`${this.endpoint}/control_panel/sales-evolution?group=${idGroup}&zone=${zone}&product=${product}&${tier}&period=${period}&n=${n}&variable=${variable}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/sales-evolution?group=${idGroup}&zone=${zone}&product=${product}&${tier}&period=${period}&n=${n}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }

  }

  // Obtener reporte cruzado de precios Rendichicas

  getExcelReportCrossSalesBrandsStationsRendichicas(idGroup: string, zone: any, variable: string, secondVariable: string, product: string, statistic: string) {
    return this.http.get(`${this.endpoint}/control_panel/rch_sales_brand_product?group=${idGroup}&zone=${zone}&variable=${variable}&variable2=${secondVariable}&product=${product}&year=${statistic}&excel_format=true`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }


  // Obtener reporte cruzado de precios Rendichicas

  getReportTopSalesRendichicas(idGroup: string, zone: any, top: boolean, variable: string, secondVariable: string, productType: string, product: string, statistic: string, cluster?: any, cluster2?: any, cluster3?: any, cluster4?: any) {
    let clusterArray = cluster != null ? `&cluster_filter=${cluster}` : '';
    let clusterArray2 = cluster2 != null ? `&importants=${cluster2}` : '';
    let clusterArray3 = cluster3 != null ? `&cluster_3_filter=${cluster3}` : '';
    let clusterArray4 = cluster4 != null ? `&cluster_4_filter=${cluster4}` : '';
    let zoneArray = zone === null ? '' : `&zone=${zone}`;
    if (productType === 'products' && product !== 'all') {
      return this.http.get(`${this.endpoint}/control_panel/top_rch_sales?group=${idGroup}${zoneArray}&clients=${top}&products_filter=${productType}&product=${product}&variable=${variable}&year=${statistic}&variable2=${secondVariable}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
        map((x: any) => x?.data?.formatted),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/top_rch_sales?group=${idGroup}${zoneArray}&clients=${top}&products_filter=${productType}&variable=${variable}&year=${statistic}&variable2=${secondVariable}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}`).pipe(
        map((x: any) => x?.data?.formatted),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Obtener reporte cruzado de precios Rendichicas

  getExcelReportTopSalesRendichicas(idGroup: string, zone: any, top: boolean, variable: string, secondVariable: string, productType: string, product: string, statistic: string, cluster?: any, cluster2?: any, cluster3?: any, cluster4?: any) {
    let clusterArray = cluster != null ? `&cluster_filter=${cluster}` : '';
    let clusterArray2 = cluster2 != null ? `&importants=${cluster2}` : '';
    let clusterArray3 = cluster3 != null ? `&cluster_3_filter=${cluster3}` : '';
    let clusterArray4 = cluster4 != null ? `&cluster_4_filter=${cluster4}` : '';
    let zoneArray = zone === null ? '' : `&zone=${zone}`;
    if (productType === 'products' && product !== 'all') {
      return this.http.get(`${this.endpoint}/control_panel/top_rch_sales?group=${idGroup}${zoneArray}&clients=${top}&products_filter=${productType}&product=${product}&variable=${variable}&year=${statistic}&variable2=${secondVariable}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}&excel_format=true`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/control_panel/top_rch_sales?group=${idGroup}${zoneArray}&clients=${top}&products_filter=${productType}&variable=${variable}&year=${statistic}&variable2=${secondVariable}${clusterArray}${clusterArray2}${clusterArray3}${clusterArray4}&excel_format=true`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getCrossReport(idGroup: string, product: string, zone: any) {
    return this.http.get(`${this.endpoint}/reports/cross/groups/1?product=${product}&group=${idGroup}&zone=${zone}`).pipe(
      map((x: any) => x?.data?.formatted),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener tablero fotos

  getDashboardPhotosPrices(idGroup: string, zone: any, status: any) {
    if (status === 4) {
      status = null;
    }
    return this.http.get(`${this.endpoint}/control_panel/photo_prices?group=${idGroup}&zone=${zone}&status_image_prices=${status}`).pipe(
      map((x: any) => x?.data?.formatted),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte de precios Rendichicas

  getReportCommunicatingRendichicas(idGroup: string, day: number) {
    return this.http.get(`${this.endpoint}/reports/selling_price_communicating/groups/${idGroup}?current=${day}`).pipe(
      map((x: any) => x?.data?.formatted),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte de margen Rendichicas

  getReportMarginRendichicas(idGroup: string, date: string) {
    return this.http.get(`${this.endpoint}/reports/margin_communicating/groups/${idGroup}?date=${date}`).pipe(
      map((x: any) => x?.data?.formatted),
      shareReplay(1),
      catchError(this.processError)
    );
  }


  // Obtener reporte Precio de venta por Grupo

  getReportSellingPrice(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/selling_price?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte volumen de venta por Grupo

  getReportSalesVolume(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/sales_volume?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte de utilidad por Grupo

  getReportSimpleUtility(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/simple_utility?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Precio de venta por Estación

  getReportSellingPriceByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/selling_price?station=${idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Tipo de Cambio por Grupo

  getReportChangeFxPrice(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/fx?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Tipo de Cambio por Grupo

  getReportChangeFxPriceByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/fx?station=${idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Precio TAR por Grupo

  getReportTarPrice(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/tar?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Precio TAR por Estación

  getReportTarPriceByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/tar?station=${idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Precio TAR por Grupo

  getReportTarDiscountPrice(idGroup: string, startDate: string, endDate: string, level: string) {
    return this.http.get(`${this.endpoint}/reports/tar_discount?group=${idGroup}&start_date=${startDate}&end_date=${endDate}&level=${level}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Precio TAR por Estación

  getReportTarDiscountPriceByStation(idStation: string, startDate: string, endDate: string, level: string) {
    return this.http.get(`${this.endpoint}/reports/tar_discount?station=${idStation}&start_date=${startDate}&end_date=${endDate}&level=${level}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Precios TAR todas las estaciones

  getReportTarPriceAll(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/all_tars?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Precios TAR todas las estaciones

  getReportBurgosExpansion(idStation: string, idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/burgos/margin?station=${idStation}&group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte precios de venta auditoria

  getReportSellingPriceAuditory(idGroup: string) {
    return this.http.get(`${this.endpoint}/reports/selling_price/audit?group=${idGroup}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte ventas mensuales

  getReportSalesMonth(idGroup: string, month: string, closing: number) {
    return this.http.get(`${this.endpoint}/reports/sales?group=${idGroup}&date=${month}-01&closing=${closing}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte utilidad

  getReportUtility(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/utility?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte utilidad por estación

  getReportUtilityByStation(idGroup: string, idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/utility?group=${idGroup}&station=${idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte precio de compra

  getReportSupplyPrice(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/supply_price/zones?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte precio de compra por estación

  getReportSupplyPriceByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/supply_price/zones?station=${idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte de precios municipios

  getReportSellingPriceTown(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/competitors_report?group=${idGroup}&startDate=${startDate}&endDate=${endDate}`).pipe(
      map((x: any) => x?.report),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte de margen

  getReportSellingPriceTownByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/competitors_report?station=${idStation}&startDate=${startDate}&endDate=${endDate}`).pipe(
      map((x: any) => x?.report),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener lista estaciones Rendichicas

  getStationsRendichicas(idGroup: string) {
    return this.http.get(`${this.endpoint}/selling_price/assignment/final_prices/by_zone?group=${idGroup}`).pipe(
      map((x: any) => x?.data?.formatted),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Margen

  getReportMargin(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/margin?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Margen por estación

  getReportMarginByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/margin?station=${idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Margen

  getReportMarginDetail(idGroup: string, startDate: string, endDate: string, type: string) {
    if (type === 'Inventario') {
      return this.http.get(`${this.endpoint}/reports/margin/detail/inventory?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
        map((x: any) => x?.data?.rows),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/reports/margin/detail/rep?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
        map((x: any) => x?.data?.rows),
        shareReplay(1),
        catchError(this.processError)
      );
    }

  }

  // Obtener reporte Margen por estación

  getReportMarginDetailByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/margin?station=${idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Margen Reposición

  getReportMarginActual(idGroup: string) {
    return this.http.get(`${this.endpoint}/reports/gasomax-report?group=${idGroup}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Margen Reposición

  getReportMarginRep(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/margin/reposition?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Margen Reposición por estación

  getReportMarginRepByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/margin/reposition?station=${idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Margen Pct

  getReportMarginPct(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/margin_pct?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Margen Pct por estación

  getReportMarginPctByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/margin_pct?station=${idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte indice de precios

  getReportIndex(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/index?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte indice de precios

  getReportIndexByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/index?station=${idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Precio de Compra

  getReportBuy(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/supply_price?start_date=${startDate}&end_date=${endDate}&group=${idGroup}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Precio de Compra

  getReportBuyByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/supply_price?start_date=${startDate}&end_date=${endDate}&station=${idStation}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }


  // Obtener reporte Precio de Compra

  getReportBuyRep(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/purchase_price/reposition?start_date=${startDate}&end_date=${endDate}&group=${idGroup}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Precio de Compra

  getReportBuyRepByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/purchase_price/reposition?start_date=${startDate}&end_date=${endDate}&station=${idStation}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Precio y Margen

  getReportPriceMargin(idGroup: string) {

    if (idGroup !== null) {
      return this.http.get(`${this.endpoint}/reports/selling_price_margin_weekly?group=${idGroup}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/reports/selling_price_margin_weekly`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Obtener reporte Precio y Margen

  getReportPriceMarginByStation(idStation: string) {

    if (idStation !== null) {
      return this.http.get(`${this.endpoint}/reports/selling_price_margin_weekly?station=${idStation}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/reports/selling_price_margin_weekly`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Obtener reporte Precio y Margen

  getReportPriceMarginMonthly(idGroup: string) {
    if (idGroup !== null) {
      return this.http.get(`${this.endpoint}/reports/selling_price_margin_monthly?group=${idGroup}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/reports/selling_price_margin_monthly`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Obtener reporte Precio y Margen

  getReportPriceMarginMonthlyByStation(idStation: string) {
    if (idStation !== null) {
      return this.http.get(`${this.endpoint}/reports/selling_price_margin_monthly?station=${idStation}`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/reports/selling_price_margin_monthly`).pipe(
        map((x: any) => x?.data),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Obtener reporte GasOnline

  getReportCcRendichicas(startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/cc/groups/Rendichicas?start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Pipas Compradas

  getReportPipes(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/purchases?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Pipas Compradas

  getReportPipesByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/purchases?station=${idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Detalle de Pipas

  getReportPipesDetails(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/purchases_detail?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Detalle de Pipas

  getReportPipesDetailsByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/purchases_detail?station=${idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }


  // Obtener reporte Precio de Compra

  getReportChanges(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/selling_price/changes?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.data_formatted),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Precio de Compra por estación

  getReportChangesByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/selling_price/changes?station=${idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data?.data_formatted),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte de cambios de precios diarios por estación

  getReportDailyChangesByStation(idStation: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/selling_price/daily_changes?station=${idStation}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte de cambios de precios diarios por group

  getReportDailyChangesByStationGroup(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/selling_price/daily_changes?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Analisis TAR Flecha

  getReportFlechaTAR(idGroup: string, start_date: string, end_date: string) {
    return this.http.get(`${this.endpoint}/reports/tar/flecha?group=${idGroup}&start_date=${start_date}&end_date=${end_date}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte compras Flecha

  getReportFlechaPurchases(idGroup: string, startDate: string, endDate: string) {
    return this.http.get(`${this.endpoint}/reports/month_purchases?group=${idGroup}&start_date=${startDate}&end_date=${endDate}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte de resumen Gracoil

  getReportResumeGracoil(idGroup: string, month: string, type: string) {
    return this.http.get(`${this.endpoint}/reports/gracoil/resume?group=${idGroup}&date=${month}${type === "bienestar" ? "&bienestar=true" : ""}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte de detalle de compras Gracoil

  getReportDetailGracoil(idGroup: string, month: string, type: string) {
    return this.http.get(`${this.endpoint}/reports/gracoil/detail?group=${idGroup}&date=${month}${type === "bienestar" ? "&bienestar=true" : ""}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Margen Burgos

  getReportBurgosTad(idGroup: string, date: string) {
    return this.http.get(`${this.endpoint}/reports/margin_burgos/?group=${idGroup}&date=${date}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getReportBurgosHistoricalTad(idGroup: string, startDate: string, endDate: string, discount: number) {
    return this.http.get(`${this.endpoint}/reports/margin_burgos/historic/?group=${idGroup}&start_date=${startDate}&end_date=${endDate}&discount=${discount}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener reporte Inventario Burgos

  getReportBurgosStock(idGroup: string, date: string, closing: number) {
    return this.http.get(`${this.endpoint}/reports/inventories?group=${idGroup}&date=${date}&closing=${closing}`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener el reporte de margen de Burgos

  getReportBurgosMargin(group: string, date: string) {
    return this.http.get(`${this.endpoint}/reports/burgos/unweighted-margin?group=${group}&date=${date}`).pipe(
      map((x: any) => {
        return x
      }),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getReportBurgosTerminalPrice(group: string, startDate: string) {
    return this.http.get(`${this.endpoint}/reports/burgos/detailed-margin?group=${group}&start_date=${startDate}&end_date${startDate}`).pipe(
      map((x: any) => {
        return x
      }),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getSchedules(group: string) {
    return this.http.get(`${this.endpoint}/general/final_selling_prices/schedules?group=${group}`).pipe(
      map((x: any) => x?.data?.schedules),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getEntities() {
    if (this.entities) {
      return this.entities;
    } else {
      this.entities = this.http.get(`${this.endpoint}/general/entidades`).pipe(
        map((x: any) => x?.data?.entidades),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.entities;
    }
  }

  getTownsEntity(entity: string) {
    return this.http.get(`${this.endpoint}/general/municipios?entidad_id=${entity}`).pipe(
      map((x: any) => x?.data?.entidades),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getTownsZone(zone: string) {
    return this.http.get(`${this.endpoint}/general/municipios?zone_id=${zone}`).pipe(
      map((x: any) => x?.data?.entidades),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getRegions(group: string) {
    return this.http.get(`${this.endpoint}/general/regions?group=${group}`).pipe(
      map((x: any) => x?.data?.regions),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getZones(idGroup: string): Observable<any> {
    if (this.zones && idGroup === this.idGroup) {
      return this.zones;
    } else {
      this.idGroup = idGroup;
      this.zones = this.http.get(`${this.endpoint1}/crm/groups/${idGroup}/zones`).pipe(
        map((x: any) => x?.data?.zones),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.zones;
    }
  }

  getZonesIM(group: string, entity?: string) {
    if (entity) {
      return this.http.get(`${this.endpoint}/general/zones?group=${group}&entidad_id=${entity}`).pipe(
        map((x: any) => x?.data?.zones),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/general/zones?group=${group}`).pipe(
        map((x: any) => x?.data?.zones),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  getClusters(idGroup: string, clusterType: 'clusters' | 'clusters_2' | 'clusters_3' | 'clusters_4', zone?): Observable<any> {
    const cacheKey = `${clusterType}`;
    const idZone = zone && clusterType === 'clusters' ? `&zone=${zone}` : '';
    if (this.clustersCache[cacheKey] && idGroup === this.idGroup && clusterType !== 'clusters') {
      return this.clustersCache[cacheKey];
    } else {
      this.idGroup = idGroup;
      this.clustersCache[cacheKey] = this.http.get(`${this.endpoint}/general/${clusterType}?group=${idGroup}${idZone}`).pipe(
        map((x: any) => x?.data?.clusters),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.clustersCache[cacheKey];
    }
  }

  // Obtener semanas cronograma

  getWeeksSchedule() {
    if (this.weeks) {
      return this.weeks;
    } else {
      this.weeks = this.http.get(`${this.endpoint}/inventory_managment/weeks`).pipe(
        map((x: any) => x?.data?.weeks),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.weeks;
    }
  }

  getPxOptimo(price: string, type: string, product: string, idStation: string): Observable<any> {
    return this.http.get(`${this.endpoint}/sales/markets?station=${idStation}&product=${product}&variable=sales&days=180&simulation=1&price=${price}&type=${type === 'diff' ? 'difference' : 'selling_price'}`);
  }

  // Obtener datos cronograma

  getPipesSchedule(group: string, startDate: string, endDate: string, zone: string) {
    let idZone = '';
    if (zone !== '' && zone !== null) {
      idZone = `&zones[]=${zone}`
    }
    return this.http.get(`${this.endpoint}/inventory_managment/timeline?group=${group}&start_date=${startDate}&end_date=${endDate}${idZone}`).pipe(
      map((x: any) => x?.data?.dataFormatted),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Setear el arrastre

  setCarryOver(data: any) {
    return this.http.put(`${this.endpoint}/inventory_managment/tanks/set_carry_over`, data);
  }

  // Setear la capacidad del tanque

  setTanksCapacity(data: any) {
    return this.http.put(`${this.endpoint}/inventory_managment/tanks/set_capacity`, data);
  }

  // Setear las ventas objetivo

  setSalesGoal(data: any) {
    return this.http.post(`${this.endpoint}/sales/sales_goal`, data);
  }

  // Obtener datos cronograma

  getListPurchases(group: string, startDate: string, endDate: string, zone: string) {

    let idZone = '';
    if (zone !== '' && zone !== null) {
      idZone = `&zones[]=${zone}`
    }

    return this.http.get(`${this.endpoint}/inventory_managment/marketers/purchases?group=${group}&start_date=${startDate}&end_date=${endDate}${idZone}`).pipe(
      map((x: any) => x?.data?.purchases),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Agregar compra

  addPurchase(data: any) {
    return this.http.post(`${this.endpoint}/purchases/marketer`, data);
  }

  // Editar compra

  editPurchase(data: any) {
    return this.http.put(`${this.endpoint}/purchases/marketer/update`, data);
  }

  // Borrar compra

  deletePurchasePanel(idPurchase: string) {
    return this.http.delete<any>(`${this.endpoint}/purchases/marketer/${idPurchase}`)
      .pipe(map(resp => {
        return resp;
      })
      );
  }

  // Obtener comercializadoras

  getListMarketers() {
    return this.http.get(`${this.endpoint}/marketers`).pipe(
      map((x: any) => x?.data?.marketers),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Agregar comercializadora

  addMarketer(data: any) {
    return this.http.post(`${this.endpoint}/marketers`, data);
  }

  // Obtener turnos

  getListWorkShifts() {
    return this.http.get(`${this.endpoint}/admin/work_shifts`).pipe(
      map((x: any) => x?.data?.shifts),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener estatus pipps

  getListStatus() {
    return this.http.get(`${this.endpoint}/purchases/status`).pipe(
      map((x: any) => x?.data?.status),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener datos cronograma

  getInventoryManagment(group: string, zone: string, product: string) {
    if (product === 'total') {
      product = null;
    }

    let idZone = '';
    if (zone !== '' && zone !== null) {
      idZone = `&zones[]=${zone}`
    }
    return this.http.get(`${this.endpoint}/inventory_managment?group=${group}${idZone}&product=${product}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener Reporte Compras dia 1

  getPurchasesPlanning(idGroup: string) {
    return this.http.get(`${this.endpoint}/reports/purchases_planning?group=${idGroup}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener Reporte Programación de Combustibles

  getPurchasesPlanningShifts(idGroup: string) {
    return this.http.get(`${this.endpoint}/reports/purchases_planning/shifts?group=${idGroup}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Obtener Reporte Compras

  getStockReport(idGroup: string, date: string) {
    return this.http.get(`${this.endpoint}/reports/inventories/formulated?group=${idGroup}&date=${date}&closing=1`).pipe(
      map((x: any) => x?.data?.rows),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Setear la capacidad del tanque

  setLimitsVolume(data: any) {
    return this.http.put(`${this.endpoint}/inventory_managment/volume_stock/limits`, data);
  }

  // Inteligencia de Mercado

  // Obtener listado de marcas

  getBrands(group: string) {
    if (this.brands) {
      return this.brands;
    } else {
      this.brands = this.http.get(`${this.endpoint}/market_intelligence/brands?group=${group}`).pipe(
        map((x: any) => x?.data?.brands?.other),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.brands;
    }
  }

  getMarketIntelligence(markeType: string, national: any, entityId: string, townId: string, zoneId: string, brandC1: string, brandC2: string, brandC3: string, brandC4: string, brandC5: string, product: string, variable: string, group: string, own_stations?: any) {
    switch (national) {
      case '1':
        entityId = '';
        townId = '';
        zoneId = '';
        break;
      case '0':
        zoneId = '';
        break;
      default:
        break;
    }
    entityId = entityId === undefined ? '' : entityId;
    return this.http.get(`${this.endpoint}/market_intelligence/station_list?group=${group}&cluster=${markeType}&national=${national}&entidad_id=${entityId}&municipio_id=${townId}&zone_id=${zoneId}&comps=5&brands[]=${brandC1}&brands[]=${brandC2}&brands[]=${brandC3}&brands[]=${brandC4}&brands[]=${brandC5}&product=${product}&variable_x=${variable}&own_stations=${own_stations}`).pipe(
      map((x: any) => x?.data?.station_list),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMarketIntelligenceMetrics(markeType: string, national: any, entityId: string, townId: string, zoneId: string, brandC1: string, brandC2: string, brandC3: string, brandC4: string, brandC5: string, product: string, group: string, own_stations?: any) {
    switch (national) {
      case '1':
        entityId = '';
        townId = '';
        zoneId = '';
        break;
      case '0':
        zoneId = '';
        break;
      default:
        break;
    }
    entityId = entityId === undefined ? '' : entityId;
    return this.http.get(`${this.endpoint}/market_intelligence/metrics?group=${group}&cluster=${markeType}&national=${national}&entidad_id=${entityId}&municipio_id=${townId}&zone_id=${zoneId}&comps=5&brands[]=${brandC1}&brands[]=${brandC2}&brands[]=${brandC3}&brands[]=${brandC4}&brands[]=${brandC5}&product=${product}&own_stations=${own_stations}`).pipe(
      map((x: any) => x?.data?.summary),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMarketIntelligenceBrands(markeType: string, national: any, entityId: string, townId: string, zoneId: string, product: string, variable: string, limit: number, group: string) {
    switch (national) {
      case '1':
        entityId = '';
        townId = '';
        zoneId = '';
        break;
      case '0':
        zoneId = '';
        break;
      default:
        break;
    }
    entityId = entityId === undefined ? '' : entityId;
    return this.http.get(`${this.endpoint}/market_intelligence/metrics/brands?group=${group}&cluster=${markeType}&national=${national}&entidad_id=${entityId}&municipio_id=${townId}&zone_id=${zoneId}&product=${product}&variable=${variable}`).pipe(
      map((x: any) => x?.data?.summary),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMarketIntelligenceBrandsTable(markeType: string, national: any, entityId: string, townId: string, zoneId: string, product: string, variable: string, limit: number, group: string, own_stations?: boolean) {
    switch (national) {
      case '1':
        entityId = '';
        townId = '';
        zoneId = '';
        break;
      case '0':
        zoneId = '';
        break;
      default:
        break;
    }
    entityId = entityId === undefined ? '' : entityId;
    return this.http.get(`${this.endpoint}/market_intelligence/metrics/by_entidades?group=${group}&cluster=${markeType}&national=${national}&entidad_id=${entityId}&municipio_id=${townId}&zone_id=${zoneId}&product=${product}&variable=${variable}&limit_brands=${limit}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMarketIntelligenceEvolution(markeType: string, national: any, entityId: string, townId: string, zoneId: string, regionId: string, product: string, variable: string, secondVariable: string, typeValue: string, group: string, period: string, n: any, stations: number) {
    switch (national) {
      case '1':
        entityId = '';
        townId = '';
        zoneId = '';
        regionId = '';
        break;
      case 1:
        entityId = '';
        townId = '';
        zoneId = '';
        break;
      case '0':
        zoneId = '';
        regionId = '';
        break;
      case 0:
        regionId = '';
        break;
      default:
        break;
    }
    entityId = entityId === undefined ? '' : entityId;
    return this.http.get(`${this.endpoint}/market_intelligence/metrics/evolution?group=${group}&cluster=${markeType}&national=${national}&entidad_id=${entityId}&municipio_id=${townId}&zone_id=${zoneId}&region_id=${regionId}&product=${product}&variable=${variable}&second_variable=${secondVariable}&type_value=${typeValue}&period=${period}&n=${n}&limit_brands=${stations}&num_stations=1`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMarketIntelligenceEvolutionExcel(markeType: string, national: any, entityId: string, townId: string, zoneId: string, regionId: string, product: string, variable: string, secondVariable: string, typeValue: string, group: string, period: string, n: any, stations: number) {
    switch (national) {
      case '1':
        entityId = '';
        townId = '';
        zoneId = '';
        regionId = '';
        break;
      case 1:
        entityId = '';
        townId = '';
        zoneId = '';
        break;
      case '0':
        zoneId = '';
        regionId = '';
        break;
      case 0:
        regionId = '';
        break;
      default:
        break;
    }
    entityId = entityId === undefined ? '' : entityId;
    return this.http.get(`${this.endpoint}/market_intelligence/metrics/evolution/excel?group=${group}&cluster=${markeType}&national=${national}&entidad_id=${entityId}&municipio_id=${townId}&zone_id=${zoneId}&region_id=${regionId}&product=${product}&variable=${variable}&second_variable=${secondVariable}&type_value=${typeValue}&period=${period}&n=${n}&limit_brands=${stations}&num_stations=1`).pipe(
      map((x: any) => x?.response),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMarketIntelligenceActual(markeType: string, national: any, entityId: string, townId: string, zoneId: string, regionId: string, product: string, variable: string, group: string) {
    switch (national) {
      case '1':
        entityId = '';
        townId = '';
        zoneId = '';
        regionId = '';
        break;
      case 1:
        entityId = '';
        townId = '';
        zoneId = '';
        break;
      case '0':
        zoneId = '';
        regionId = '';
        break;
      case 0:
        regionId = '';
        break;
      default:
        break;
    }
    entityId = entityId === undefined ? '' : entityId;
    return this.http.get(`${this.endpoint}/market_intelligence/metrics/current?group=${group}&cluster=${markeType}&national=${national}&entidad_id=${entityId}&municipio_id=${townId}&zone_id=${zoneId}&region_id=${regionId}&product=${product}&variable=${variable}&num_stations=1`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  getMarketIntelligenceActualExcel(markeType: string, national: any, entityId: string, townId: string, zoneId: string, regionId: string, product: string, variable: string, group: string) {
    switch (national) {
      case '1':
        entityId = '';
        townId = '';
        zoneId = '';
        regionId = '';
        break;
      case 1:
        entityId = '';
        townId = '';
        zoneId = '';
        break;
      case '0':
        zoneId = '';
        regionId = '';
        break;
      case 0:
        regionId = '';
        break;
      default:
        break;
    }
    entityId = entityId === undefined ? '' : entityId;
    return this.http.get(`${this.endpoint}/market_intelligence/metrics/current/excel?group=${group}&cluster=${markeType}&national=${national}&entidad_id=${entityId}&municipio_id=${townId}&zone_id=${zoneId}&region_id=${regionId}&product=${product}&variable=${variable}&limit_brands=40&num_stations=1`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Seccion Ganancias

  // Venta y Ganancia

  getSaleEarning(daysAgo: any, month?: any) {
    if (month) {
      this.saleEarning = this.http.get(`${this.endpoint}/optimum_price/earning/sale_and_earning?station=${this.idStation}&month=${month}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.saleEarning;
    } else {
      this.saleEarning = this.http.get(`${this.endpoint}/optimum_price/earning/sale_and_earning?station=${this.idStation}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x),
        shareReplay(1),
        catchError(this.processError)
      );
      return this.saleEarning;
    }
  }

  // Venta y Ganancia excel

  getSaleEarningHistory(startDate?: any, endDate?: any) {
    return this.http.get(`${this.endpoint}/optimum_price/earning/sale_and_earning?station=${this.idStation}&startdate=${startDate}&lastdate=${endDate}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Venta por producto

  getSale(daysAgo: any, month?: any) {
    if (month) {
      return this.http.get(`${this.endpoint}/optimum_price/earning/sale?station=${this.idStation}&month=${month}`).pipe(
        map((x: any) => x?.sales),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/optimum_price/earning/sale?station=${this.idStation}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x?.sales),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Venta por producto excel

  getSaleHistory(startDate?: any, endDate?: any) {
    return this.http.get(`${this.endpoint}/optimum_price/earning/sale?station=${this.idStation}&startdate=${startDate}&lastdate=${endDate}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Ganancia por producto

  getEarning(daysAgo: any, month?: any) {
    if (month) {
      return this.http.get(`${this.endpoint}/optimum_price/earning/?station=${this.idStation}&month=${month}`).pipe(
        map((x: any) => x?.earning),
        shareReplay(1),
        catchError(this.processError)
      );
    } else {
      return this.http.get(`${this.endpoint}/optimum_price/earning/?station=${this.idStation}&days_ago=${daysAgo}`).pipe(
        map((x: any) => x?.earning),
        shareReplay(1),
        catchError(this.processError)
      );
    }
  }

  // Ganancia por producto excel

  getEarningHistory(startDate?: any, endDate?: any) {
    return this.http.get(`${this.endpoint}/optimum_price/earning/?station=${this.idStation}&startdate=${startDate}&lastdate=${endDate}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Margen Periodo

  getEarningComparative(indicator: any, period: any) {
    return this.http.get(`${this.endpoint}/optimum_price/earning/comparative?station=${this.idStation}&indicator=${indicator}&period=${period}`).pipe(
      map((x: any) => x?.comparative),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Ganancia por producto

  getSaleEarningPercent(startDate: any, endDate: any) {
    return this.http.get(`${this.endpoint}/optimum_price/earning/sale_earning_percent?station=${this.idStation}&startdate=${startDate}&lastdate=${endDate}`).pipe(
      map((x: any) => x),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  // Enviar comunicado

  sendCommunicatingRendichicas(file, zone, zoneId) {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('zone', zone);
    formData.append('zone_id', zoneId);

    return this.http.post<any>(`${this.endpoint}/reports/send_report/selling_price_communicating`, formData)
      .pipe(map(data => {
        return data;
      })
      );
  }

  // Enviar comunicado

  sendMarginRendichicas(file) {

    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<any>(`${this.endpoint}/reports/send_report/margin_communicating`, formData).pipe(
      catchError(error => {
        console.error('Error sending file: ', error);
        return throwError(() => new Error(error.message || 'An error occurred'));
      })
    );
  }

  // Subir cc rendichicas

  uploadCC(data: Record<string, unknown>): Observable<unknown> {
    return this.http.post(`${this.endpoint}/supply_price/supplies_rch`, data).pipe(
      map((cc) => cc),
      catchError((err) => {
        // Handle errors here
        console.error(err);
        throw err; // Rethrow the error to propagate it to the caller
      })
    );
  }

  // Subir Imagen

  uploadImage(image, name, type, size, dateTime, ImageHeight, ImageWidth, PixelXDimension, PixelYDimension, creId) {

    const formData = new FormData();
    formData.append('image', image);
    formData.append('name', name);
    formData.append('type', type);
    formData.append('size', size);
    formData.append('dateTime', dateTime);
    formData.append('ImageHeight', ImageHeight);
    formData.append('ImageWidth', ImageWidth);
    formData.append('PixelXDimension', PixelXDimension);
    formData.append('PixelYDimension', PixelYDimension);
    formData.append('cre_id', creId);

    return this.http.post<any>(`${this.endpoint1}/prices/images`, formData, { headers: { skip: 'skip' } })
      .pipe(map(data => {
        return data;
      })
      );
  }

  // Subir Imagen

  deleteImage(data) {
    return this.http.delete<any>(`${this.endpoint1}/prices/image`, { body: data })
      .pipe(map(data => {
        return data;
      })
      );
  }

  getProfitability(group: string, product: string, date: string, percent: string): Observable<{ data: Rentabilidad[], data_total: Rentabilidad[] }> {
    return this.http.get<{ data: { data: Rentabilidad[], data_total: Rentabilidad[] } }>(`${this.endpoint}/control_panel/profitability?group=${group}&product=${product}&date=${date}&percent=${percent}`)
      .pipe(map((data) => {
        return { data: data.data.data, data_total: data.data.data_total };
      }));
  }

  // Data Format

  parseDateTZ(date: string | Date) {
    return moment.tz(date, 'America/Mexico_City').format('DD MMMM HH:mm');
  }

  parseDateSlash(date: any) {
    return moment.tz(date, 'America/Mexico_City').format('DD/MMMM_HH:mm');
  }

  parseDateD(date: any) {
    return moment.tz(date, 'America/Mexico_City').format('(dd) DD-MMM');
  }

  parseDateDM(date: any) {
    return moment.tz(date, 'America/Mexico_City').format('DD MMM');
  }

  parseDateDay(date: any) {
    return moment.tz(date, 'America/Mexico_City').format('dddd');
  }

  parseDateM(date: any) {
    return moment.tz(date, 'America/Mexico_City').format('MMM YY');
  }

  parseDateY(date: any) {
    return moment.tz(date, 'America/Mexico_City').format('DD-MMM-YY');
  }

  parseDateYS(date: any) {
    return moment.tz(date, 'America/Mexico_City').format('DD/MM/YY');
  }

  parseDate(date: any) {
    return moment.tz(date, 'America/Mexico_City').format('DD/MM/YYYY');
  }

  parseDateComplete(date: any) {
    return moment.tz(date, 'America/Mexico_City').format('DD/MM/YYYY HH:mm');
  }

  parseHour(date: any) {
    return moment.tz(date, 'America/Mexico_City').format('HH:mm');
  }

  removeSADECV(str: string) {
    if (typeof str !== 'string') {
      return '';
    }
    str = this.capitalizeFirstLetter(str.toLowerCase());
    str = str.replace(/[\s,]+/g, ' ');
    str = str.replace(/(,? )?[Ss]\.?\s?[Aa]\.?\s?[Pp]\.?\s?[Ii]\.?\s? [Dd][Ee] [Cc]\.?\s?[Vv]\.?\s?/g, '');
    str = str.replace(/(,? )?[Ss]\.?\s? [Dd][Ee] [Rr]\.?\s?[Ll]\.?\s? [Dd][Ee] [Cc]\.?\s?[Vv]\.?\s?/g, '');
    str = str.replace(/(,? )?[Ss]\.?[Dd][Ee][Rr]\.?[Ll]\.?[Dd][Ee][Cc][Vv]?/g, '');
    str = str.replace(/(,? )?[Ss]\.?\s?[Aa]\.?\s? [Dd][Ee] [Cc]\.?\s?[Vv]\.?\s?/g, '');
    str = str.replace(/(,? )?[Ss]\.?\s?[Cc]\.?\s? [Dd][Ee] [Rr]\.?\s?[Ss]\.?\s?/g, '');
    str = str.replace(/(,? )?[Ss]\.?\s? [Dd][Ee] [Rr]\.?\s?[Ll]\.?\s?/g, '');
    str = str.replace(/(,? )?[Ss]\.?\s?[Aa]\.?\s? [Cc]\.?\s?[Vv]\.?\s?/g, '');
    str = str.replace(/(,? )?[Ss]\.\s?[Aa]\.?/g, '');
    return str;
  }

  capitalizeFirstLetter(text: any): string {
    if (typeof text !== 'string') {
      return '';
    }
    return text
      .toLowerCase()
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  capitalizeOnlyFirstLetter(text: any): string {
    if (typeof text !== 'string') {
      return '';
    }
    return text
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  changeNegative(num: any) {
    num = num.toString();
    return num.includes('-') ? num.replace('-', '-') : num;
  }

  changeNegativeAmount(num: number | string): string {
    const parsedNum = parseFloat(num.toString());
    if (isNaN(parsedNum)) {
      return '';
    }
    const formattedNum = +num < 0 ? '-$ ' : '$ ';
    return formattedNum + Math.abs(parsedNum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  changeNegativeAmountWithoutDecimals(num: number | string): string {
    const parsedNum = parseFloat(num.toString());
    if (isNaN(parsedNum)) {
      return '';
    }
    const formattedNum = +num < 0 ? '-$ ' : '$ ';
    return formattedNum + Math.abs(parsedNum).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  numberWithoutDecimals(x: any) {
    if (typeof x !== 'number' || isNaN(x)) {
      return '-';
    }
    x = x.toFixed(0);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  formatDistance(distance: number | string = 0): string {
    const distanceInMeters: any = typeof distance === 'string' && !distance.includes('+') ? parseFloat(distance) : distance;
    const distanceInKilometers = distanceInMeters / 1000;
    let distanceFormatted = distanceInKilometers >= 1 ? distanceInKilometers.toFixed(2) + ' km' : Math.round(distanceInMeters) + ' mts';
    if (isNaN(Number(distanceFormatted.split(' ')[0]))) {
      distanceFormatted = '+15 km';
    }
    return distanceFormatted;
  }

  processError(err: any) {
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    return throwError(() => {
      message;
    });
  }

  createColor(r, g, b, a) {
    const rgba = `rgba(${r}, ${g}, ${b}, ${a})`;
    return {
      backgroundColor: rgba,
      borderColor: rgba,
      pointBackgroundColor: rgba,
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: rgba,
      pointHoverBorderColor: rgba
    };
  }

  createColorBorder(r, g, b, a) {
    const rgba = `rgba(${r}, ${g}, ${b}, ${a})`;
    const rgb = `rgb(${r}, ${g}, ${b})`;
    return {
      backgroundColor: rgba,
      borderColor: rgb,
      borderWith: 1,
      pointBackgroundColor: rgba,
      pointBorderColor: rgb,
      pointHoverBackgroundColor: rgba,
      pointHoverBorderColor: rgba
    };
  }

  formatProductCell(product, value, fillColor, textColor?) {
    product.value = value.toUpperCase();
    product.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: fillColor },
      bgColor: { argb: 'FFFFFFFF' },
    };
    product.font = { color: { argb: textColor || 'FFFFFFFF' }, bold: true };
    product.alignment = { vertical: 'middle' }
    product.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  }

  formatProductCellCenter(product, value, fillColor, textColor?) {
    product.value = value.toUpperCase();
    product.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: fillColor },
      bgColor: { argb: 'FFFFFFFF' },
    };
    product.alignment = { vertical: 'middle', horizontal: 'center' }
    product.font = { color: { argb: textColor || 'FFFFFFFF' }, bold: true };
  }

  changeClientG(creId: string, stationId: string) {
    localStorage.setItem('creId', creId);
    localStorage.setItem('idStation', stationId);
    this.router.navigate(['/gasprecio/monitor']).then(() => {
      window.location.reload();
    });
  }

  displayMessage(messageType: 'success' | 'error' | 'warning', title: string, text?: string, options?: { allowEscapeKey?: boolean; allowEnterKey?: boolean; allowOutsideClick?: boolean; preConfirm?: () => void; }) {
    Swal.fire({
      title: title,
      text: text,
      icon: messageType,
      confirmButtonColor: '#00e07b',
      reverseButtons: true,
      confirmButtonText: 'Aceptar',
      ...options
    });
  }

  displayMessageHtml(title: string, html: any,) {
    Swal.fire({
      title: title,
      html: html,
      background: '#1e2228',
      confirmButtonColor: '#b82f26',
      confirmButtonText: 'Aceptar'
    });
  }

  showLoader(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
  }

  createWorksheet(workbook, title, ySplit, xSplit) {
    if (title === 'SALVADOR ALVARADO') {
      title = 'GUAMUCHIL'
    }
    return workbook.addWorksheet(title, {
      views: [
        { state: 'frozen', ySplit: ySplit, xSplit: xSplit }
      ]
    });
  }

  generateExcelFile(workbook, filename) {
    Swal.close();
    workbook.xlsx.writeBuffer().then((resp) => {
      const blob = new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, filename);
    });
  }

  addHeaderRow(worksheet: any, header: any, background?, black?: boolean, border?: boolean) {
    const headerRow = worksheet.addRow(header);
    return headerRow.eachCell((cell: any, index) => {
      if (background && !border) {
        this.formatHeaderCell(cell, background[index], black);
      } else if (background) {
        this.formatHeaderCell(cell, background[index], black);
      } else {
        this.formatHeaderCell(cell);
      }
    });
  }

  formatHeaderCell(cell, background?: string, black?: boolean, border?: boolean) {
    if (cell.value) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: background || 'FF002060' },
        bgColor: { argb: 'FF28da6d' }
      };
      cell.font = { color: { argb: black ? '00000000' : 'FFFFFFFF' }, bold: true };
      cell.border = { top: { style: border ? 'medium' : 'thin' }, left: { style: border ? 'medium' : 'thin' }, bottom: { style: border ? 'medium' : 'thin' }, right: { style: border ? 'medium' : 'thin' } };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    }
  }

  formatCell(cell, font, background?) {
    if (cell.value) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: background || 'FF002060' },
        bgColor: { argb: 'FF28da6d' }
      };
      cell.font = { color: { argb: font }, bold: true };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    }
  }

  numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  getColorString(color: { r: number, g: number, b: NumberFormat }): string {
    return `rgb(${color.r}, ${color.g}, ${color.b})`;
  }

  getColorRGBAString(color: { r: number, g: number, b: number }): string {
    return `rgba(${color.r}, ${color.g}, ${color.b}, .6)`;
  }

  getProductTypeIndex(productType) {
    return ['regular', 'premium', 'diesel', 'total'].indexOf(productType);
  }

  getExcelColumnLetter(columnNumber) {
    let columnLetter = '';
    while (columnNumber > 0) {
      let modulo = (columnNumber - 1) % 26;
      columnLetter = String.fromCharCode(65 + modulo) + columnLetter;
      columnNumber = Math.floor((columnNumber - modulo) / 26);
    }
    return columnLetter;
  }

  getHeatMapData(group: string, zone: string, variable: string, date: string) {
    // Realiza la llamada HTTP con parámetros dinámicos y encabezados
    return this.http.get(`${this.endpoint}/heat_maps/month?group=${group}&zone=${zone}&variable=${variable}&date=${date}`).pipe(
      map((x: any) => x?.data),
      shareReplay(1),
      catchError(this.processError)
    );
  }

  processDataForHeatmap(data: number[][]): { x: number; y: number; value: number; color: string }[] {
    const min = this.getArrRange(data, 'min', '');
    const max = this.getArrRange(data, 'max', '');
    return data.map(([x, y, value]) => ({ x, y, value, color: this.changeColor(value, min, max) }));
  }

  getArrRange(data, typeRange, dataType: string) {
    const values = dataType === 'object' ? data.map((item) => item['value']) : dataType === 'array' ? data.map((item) => item) : data.map((item) => item[2]);
    return typeRange === 'min' ? Math.min(...values) : Math.max(...values);
  }

  changeColor(value: number, minValue: number, maxValue: number, reverse?: boolean): string {
    const range = maxValue - minValue;
    const step = range / 5;
    const thresholds = reverse ? [
      { limit: minValue + step, color: '#E87B79' },
      { limit: minValue + step * 2, color: '#F5B855' },
      { limit: minValue + step * 3, color: '#CBD26B' },
      { limit: minValue + step * 4, color: '#9ECE9F' },
      { limit: maxValue, color: '#86CEB3' }
    ] : [
      { limit: minValue + step, color: '#86CEB3' },
      { limit: minValue + step * 2, color: '#9ECE9F' },
      { limit: minValue + step * 3, color: '#CBD26B' },
      { limit: minValue + step * 4, color: '#F5B855' },
      { limit: maxValue, color: '#E87B79' }
    ];
    for (const threshold of thresholds) {
      if (value <= threshold.limit) {
        return threshold.color;
      }
    }
    return '#E87B79';
  }
}
