import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environments';
import { applyPolyfills, defineCustomElements } from "@entropyx/epy-components/loader";

if (environment.production) {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  window.console.log = () => {};
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));

applyPolyfills().then(() => {
  defineCustomElements(window);
});

// For Hot Module Replacement as seen in:
// https://levelup.gitconnected.com/hmr-in-angular-cli-project-eb8512ef514b
bootstrap().catch(err => console.error(err))
