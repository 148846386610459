import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { SharedService } from "src/app/modules/gasprecio/services/shared.service";

@Component({
  selector: 'app-select-type-one-page',
  templateUrl: './select-type-one-page.component.html',
  styleUrls: ['./select-type-one-page.component.scss']
})
export class SelectTypeOnePageComponent implements OnDestroy {
  public items: any[] = [
    { value: "", name: "Tipo de Cálculo" },
    { value: "average", name: "Promedio" },
    { value: "accumulated", name: "Acumulado" },
  ];
  public typeSelected: any = "";
  typeSelected$: Subscription;
  constructor(private sharedService: SharedService) {
    this.typeSelected$ = sharedService.sharedTypeOnePage.subscribe(
      (typeSelected: string) => {
        if (this.typeSelected !== typeSelected) {
          this.typeSelected = typeSelected;
        }
      }
    );
  }

  ngOnDestroy() {
    this.typeSelected$.unsubscribe();
  }

  changeType(event: any) {
    this.typeSelected = event;
    this.sharedService.nextTypeOnePage = this.typeSelected;
  }
}
