import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HAnnotationsAdvanced from 'highcharts/modules/annotations-advanced';
import HAnnotations from 'highcharts/modules/annotations';
import HC_exportData from 'highcharts/modules/export-data';
import HC_drilldown from 'highcharts/modules/drilldown';
import HC_exportingOffline from 'highcharts/modules/offline-exporting';
import HC_exportingExporting from 'highcharts/modules/exporting';
import "chartjs-chart-box-and-violin-plot";
import { AngularDraggableModule } from 'angular2-draggable';
import { ChartComparativeDashboardComponent } from './components/chart-comparative-dashboard/chart-comparative-dashboard.component';
import { ChartDifPriceQuotientComponent } from './components/chart-dif-price-quotient/chart-dif-price-quotient.component';
import { ChartEarningComponent } from './components/chart-earning/chart-earning.component';
import { ChartIndexSaleComponent } from './components/chart-index-sale/chart-index-sale.component';
import { ChartMarginVolumenComponent } from './components/chart-margin-volumen/chart-margin-volumen.component';
import { ChartQuotientSaleComponent } from './components/chart-quotient-sale/chart-quotient-sale.component';
import { ChartSaleBuyComponent } from './components/chart-sale-buy/chart-sale-buy.component';
import { ChartSalePpcComponent } from './components/chart-sale-ppc/chart-sale-ppc.component';
import { ChartSalesPeriodComponent } from './components/chart-sales-period/chart-sales-period.component';
import { ChartSalesQuartilComponent } from './components/chart-sales-quartil/chart-sales-quartil.component';
import { ChartsModule } from 'ng2-charts';
import { ChartTarBuyComponent } from './components/chart-tar-buy/chart-tar-buy.component';
import { CommonModule } from '@angular/common';
import { CreateGroupsComponent } from './components/create-groups/create-groups.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { LastUpdateComponent } from './components/last-update/last-update.component';
import { MultiselectClustersComponent } from './components/multiselect-clusters/multiselect-clusters.component';
import { MultiselectFlagComponent } from './components/multiselect-flag/multiselect-flag.component';
import { MultiselectProductComponent } from './components/multiselect-product/multiselect-product.component';
import { MultiselectReasonComponent } from './components/multiselect-reason/multiselect-reason.component';
import { MultiselectScheduleComponent } from './components/multiselect-schedule/multiselect-schedule.component';
import { MultiselectVariablesComponent } from './components/multiselect-variables/multiselect-variables.component';
import { MultiselectZoneComponent } from './components/multiselect-zone/multiselect-zone.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSelectModule } from 'ngx-select-ex';
import { PriceRulesComponent } from './components/price-rules/price-rules.component';
import { QuotientChartComponent } from './components/quotient-chart/quotient-chart.component';
import { RouterModule } from '@angular/router';
import { SalesChartComponent } from './components/sales-chart/sales-chart.component';
import { SearchPipe } from './pipes/search.pipe';
import { SelectBrandsComponent } from './components/select-brands/select-brands.component';
import { SelectCentralTendencyComponent } from './components/select-central-tendency/select-central-tendency.component';
import { SelectDashboardDaysComponent } from './components/select-dashboard-days/select-dashboard-days.component';
import { SelectDashboardPeriodComponent } from './components/select-dashboard-period/select-dashboard-period.component';
import { SelectDashboardZoneComponent } from './components/select-dashboard-zone/select-dashboard-zone.component';
import { SelectDaysActualComponent } from './components/select-days-actual/select-days-actual.component';
import { SelectDaysChangeComponent } from './components/select-days-change/select-days-change.component';
import { SelectDaysComponent } from './components/select-days/select-days.component';
import { SelectDaysEvolutionComponent } from './components/select-days-evolution/select-days-evolution.component';
import { SelectDaysOptimumPriceComponent } from './components/select-days-optimum-price/select-days-optimum-price.component';
import { SelectDaysYesterdayComponent } from './components/select-days-yesterday/select-days-yesterday.component';
import { SelectEarningsComponent } from './components/select-earnings/select-earnings.component';
import { SelectEntityComponent } from './components/select-entity/select-entity.component';
import { SelectGroupComponent } from './components/select-group/select-group.component';
import { SelectLevelDiscountComponent } from './components/select-level-discount/select-level-discount.component';
import { SelectMarginTypeComponent } from './components/select-margin-type/select-margin-type.component';
import { SelectMarketComponent } from './components/select-market/select-market.component';
import { SelectMarketTypeComponent } from './components/select-market-type/select-market-type.component';
import { SelectMarketZoneComponent } from './components/select-market-zone/select-market-zone.component';
import { SelectMonthComponent } from './components/select-month/select-month.component';
import { SelectMonthHeatMapComponent } from './components/select-month-heat-map/select-month-heat-map.component';
import { SelectMonthsComponent } from './components/select-months/select-months.component';
import { SelectPeriodComparativeComponent } from './components/select-period-comparative/select-period-comparative.component';
import { SelectPeriodComponent } from './components/select-period/select-period.component';
import { SelectPeriodEarningComponent } from './components/select-period-earning/select-period-earning.component';
import { SelectPeriodIndexComponent } from './components/select-period-index/select-period-index.component';
import { SelectPillarComponent } from './components/select-pillar/select-pillar.component';
import { SelectProductComponent } from './components/select-product/select-product.component';
import { SelectProductTotalComponent } from './components/select-product-total/select-product-total.component';
import { SelectReasonComponent } from './components/select-reason/select-reason.component';
import { SelectRegionEvolutionComponent } from './components/select-region-evolution/select-region-evolution.component';
import { SelectScheduleComponent } from './components/select-schedule/select-schedule.component';
import { SelectStationComponent } from './components/select-station/select-station.component';
import { SelectStatisticsComponent } from './components/select-statistics/select-statistics.component';
import { SelectStatisticsEvolutionComponent } from './components/select-statistics-evolution/select-statistics-evolution.component';
import { SelectStatisticsTradeTrackingComponent } from './components/select-statistics-trade-tracking/select-statistics-trade-tracking.component';
import { SelectTownComponent } from './components/select-town/select-town.component';
import { SelectTypeNationalComponent } from './components/select-type-national/select-type-national.component';
import { SelectVariablesDashboardComponent } from './components/select-variables-dashboard/select-variables-dashboard.component';
import { SelectVariablesIntelligenceComponent } from './components/select-variables-intelligence/select-variables-intelligence.component';
import { SelectVariablesIntelligenceEvolutionComponent } from './components/select-variables-intelligence-evolution/select-variables-intelligence-evolution.component';
import { SelectVariablesIntelligenceMarketComponent } from './components/select-variables-intelligence-market/select-variables-intelligence-market.component';
import { SelectVariablesPriceIndexComponent } from './components/select-variables-price-index/select-variables-price-index.component';
import { SelectWeekComponent } from './components/select-week/select-week.component';
import { SelectWeeksComponent } from './components/select-weeks/select-weeks.component';
import { SellingPriceChartComponent } from './components/selling-price-chart/selling-price-chart.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TableAlertsComponent } from './components/table-alerts/table-alerts.component';
import { TableComparativeDashboardComponent } from './components/table-comparative-dashboard/table-comparative-dashboard.component';
import { TableLoaderComponent } from './components/table-loader/table-loader.component';
import { TrafficChartComponent } from './components/traffic-chart/traffic-chart.component';
import { TableSalesQuartilComponent } from './components/table-sales-quartil/table-sales-quartil.component';
import { SelectDaysPxOptimumComponent } from './components/select-days-px-optimum/select-days-px-optimum.component';
import { ChartQuotientSaleLevelComponent } from './components/chart-quotient-sale-level/chart-quotient-sale-level.component';
import { SelectVariablesSecondIntelligenceEvolutionComponent } from './components/select-variables-second-intelligence-evolution/select-variables-second-intelligence-evolution.component';
import { ChartTransactionsComponent } from './components/chart-transactions/chart-transactions.component';
import { ChartTicketsComponent } from './components/chart-tickets/chart-tickets.component';
import { SelectBudgetComponent } from './components/select-budget/select-budget.component';
import { SelectProductTypeComponent } from "./components/select-product-type/select-product-type.component";
import { SelectPeriodBudgetComponent } from './components/select-period-budget/select-period-budget.component';
import { SelectWeekBudgetComponent } from './components/select-week-budget/select-week-budget.component';
import { SelectMonthBudgetComponent } from './components/select-month-budget/select-month-budget.component';
import { SelectYearBudgetComponent } from './components/select-year-budget/select-year-budget.component';
import { SelectMarginComponent } from './components/select-margin/select-margin.component';
import { SelectPhotoStatusComponent } from './components/select-photo-status/select-photo-status.component';
import { SelectTopComponent } from './components/select-top/select-top.component';
import { SelectProductAllComponent } from './components/select-product-all/select-product-all.component';
import { SelectVariableCrossReportComponent } from './components/select-variable-cross-report/select-variable-cross-report.component';
import { SelectProductGasolineComponent } from './components/select-product-gasoline/select-product-gasoline.component';
import { SelectProductGasolinesComponent } from './components/select-product-gasolines/select-product-gasolines.component';
import { SelectStatisticsSalesComponent } from './components/select-statistics-sales/select-statistics-sales.component';
import { SelectVariableSecondCrossReportComponent } from './components/select-variable-second-cross-report/select-variable-second-cross-report.component';
import { SelectProductTypeSalesComponent } from './components/select-product-type-sales/select-product-type-sales.component';
import { SelectStatisticsBudgetComponent } from './components/select-statistics-budget/select-statistics-budget.component';
import { MultiselectTiersComponent } from './components/multiselect-tiers/multiselect-tiers.component';
import { SortDirective } from "../gasprecio/directives/sort.directive";
import { ChartSalesQuartilComparativeComponent } from './components/chart-sales-quartil-comparative/chart-sales-quartil-comparative.component';
import { TableSalesQuartilComparativeComponent } from './components/table-sales-quartil-comparative/table-sales-quartil-comparative.component';
import { HighchartsChartModule } from "highcharts-angular";
import { ChartDifMinPriceComponent } from './components/chart-dif-min-price/chart-dif-min-price.component';
import { TableDifMinPriceComponent } from './components/table-dif-min-price/table-dif-min-price.component';
import { TableDifMinPriceCompetitorsComponent } from './components/table-dif-min-price-competitors/table-dif-min-price-competitors.component';
import { ChartDifMaxPriceComponent } from './components/chart-dif-max-price/chart-dif-max-price.component';
import { TableDifMaxPriceComponent } from './components/table-dif-max-price/table-dif-max-price.component';
import { TableDifMaxPriceCompetitorsComponent } from './components/table-dif-max-price-competitors/table-dif-max-price-competitors.component';
import { SelectVariablePxOptComponent } from './components/select-variable-px-opt/select-variable-px-opt.component';
import { SelectVariableOnePageComponent } from './components/select-variable-one-page/select-variable-one-page.component';
import { SelectTopBottomVariableOnePageComponent } from './components/select-top-bottom-variable-one-page/select-top-bottom-variable-one-page.component';
import { SelectPeriodOnePageComponent } from './components/select-period-one-page/select-period-one-page.component';
import { SelectConcoDiscountComponent } from './components/select-conco-discount/select-conco-discount.component';
import { SelectPurchaseSourceComponent } from './components/select-purchase-source/select-purchase-source.component';
import { ChartSalesSeasonallyComponent } from './components/chart-sales-seasonally/chart-sales-seasonally.component';
import { SelectClusterComponent } from './components/select-cluster/select-cluster.component';
import { SelectCluster2Component } from './components/select-cluster2/select-cluster2.component';
import { SelectCluster3Component } from './components/select-cluster3/select-cluster3.component';
import { SelectCluster4Component } from './components/select-cluster4/select-cluster4.component';
import { MoneyParserPipe } from './pipes/money-parser.pipe';
import { SelectRankingComponent } from './components/select-ranking/select-ranking.component';
import { SelectPercentageComponent } from './components/select-percentage/select-percentage.component';
import { PercentageParserPipe } from './pipes/percentage-parser.pipe';
import { FormatLargeNumberPipe } from './pipes/format-large-number.pipe';
import { RoundDecimalsPipe } from './pipes/round-decimals.pipe';
import { ChartSalesDistributionComponent } from './components/chart-sales-distribution/chart-sales-distribution.component';
import { HeatMapByMonthComponent } from './components/heat-map-by-month/heat-map-by-month.component';
import { ScorecardByProductComponent } from './components/scorecard-by-product/scorecard-by-product.component';
import { HeatMapShiftComponent } from './components/heat-map-shift/heat-map-shift.component';
import { ChartSalesShiftComponent } from './components/chart-sales-shift/chart-sales-shift.component';
import { ChartSalesWeekComponent } from './components/chart-sales-week/chart-sales-week.component';
import { ChartSalesHourComponent } from './components/chart-sales-hour/chart-sales-hour.component';
import { SelectStationZoneComponent } from './components/select-station-zone/select-station-zone.component';
import { SelectTypeOnePageComponent } from './components/select-type-one-page/select-type-one-page.component';
import { SelectZoneOnePageComponent } from './components/select-zone-one-page/select-zone-one-page.component';
import { SelectDiscountHistoricalTadReportComponent } from './components/select-discount-historical-tad-report/select-discount-historical-tad-report.component';
import { RemoveSadecvPipe } from 'src/app/remove-sadecv.pipe';

HC_exporting(Highcharts);
HC_exportData(Highcharts);
HC_drilldown(Highcharts);
HC_exportingOffline(Highcharts);
HC_exportingExporting(Highcharts);
HAnnotations(Highcharts);
HAnnotationsAdvanced(Highcharts);

@NgModule({
    declarations: [
        ChartComparativeDashboardComponent,
        ChartDifPriceQuotientComponent,
        ChartEarningComponent,
        ChartIndexSaleComponent,
        ChartMarginVolumenComponent,
        ChartQuotientSaleComponent,
        ChartQuotientSaleLevelComponent,
        ChartSaleBuyComponent,
        ChartSalePpcComponent,
        ChartSalesPeriodComponent,
        ChartSalesQuartilComponent,
        ChartTarBuyComponent,
        ChartTicketsComponent,
        ChartTransactionsComponent,
        CreateGroupsComponent,
        HeaderComponent,
        LastUpdateComponent,
        MultiselectClustersComponent,
        MultiselectFlagComponent,
        MultiselectProductComponent,
        MultiselectReasonComponent,
        MultiselectScheduleComponent,
        MultiselectTiersComponent,
        MultiselectVariablesComponent,
        MultiselectZoneComponent,
        PriceRulesComponent,
        QuotientChartComponent,
        SalesChartComponent,
        SearchPipe,
        SelectBrandsComponent,
        SelectBudgetComponent,
        SelectCentralTendencyComponent,
        SelectDashboardDaysComponent,
        SelectDashboardPeriodComponent,
        SelectDashboardZoneComponent,
        SelectDaysActualComponent,
        SelectDaysChangeComponent,
        SelectDaysComponent,
        SelectDaysEvolutionComponent,
        SelectDaysOptimumPriceComponent,
        SelectDaysPxOptimumComponent,
        SelectDaysYesterdayComponent,
        SelectEarningsComponent,
        SelectEntityComponent,
        SelectGroupComponent,
        SelectLevelDiscountComponent,
        SelectMarginComponent,
        SelectMarginTypeComponent,
        SelectMarketComponent,
        SelectMarketTypeComponent,
        SelectMarketZoneComponent,
        SelectMonthBudgetComponent,
        SelectMonthComponent,
        SelectMonthHeatMapComponent,
        SelectMonthsComponent,
        SelectPeriodBudgetComponent,
        SelectPeriodComparativeComponent,
        SelectPeriodComponent,
        SelectPeriodEarningComponent,
        SelectPeriodIndexComponent,
        SelectPhotoStatusComponent,
        SelectPillarComponent,
        SelectProductAllComponent,
        SelectProductComponent,
        SelectProductGasolineComponent,
        SelectProductGasolinesComponent,
        SelectProductTotalComponent,
        SelectProductTypeComponent,
        SelectProductTypeSalesComponent,
        SelectReasonComponent,
        SelectRegionEvolutionComponent,
        SelectScheduleComponent,
        SelectStationComponent,
        SelectStatisticsBudgetComponent,
        SelectStatisticsComponent,
        SelectStatisticsEvolutionComponent,
        SelectStatisticsSalesComponent,
        SelectStatisticsTradeTrackingComponent,
        SelectTopComponent,
        SelectTownComponent,
        SelectTypeNationalComponent,
        SelectVariableCrossReportComponent,
        SelectVariablesDashboardComponent,
        SelectVariableSecondCrossReportComponent,
        SelectVariablesIntelligenceComponent,
        SelectVariablesIntelligenceEvolutionComponent,
        SelectVariablesIntelligenceMarketComponent,
        SelectVariablesPriceIndexComponent,
        SelectVariablesSecondIntelligenceEvolutionComponent,
        SelectWeekBudgetComponent,
        SelectWeekComponent,
        SelectWeeksComponent,
        SelectYearBudgetComponent,
        SellingPriceChartComponent,
        SidebarComponent,
        TableAlertsComponent,
        TableComparativeDashboardComponent,
        TableLoaderComponent,
        TableSalesQuartilComponent,
        TrafficChartComponent,
        SortDirective,
        ChartSalesQuartilComparativeComponent,
        TableSalesQuartilComparativeComponent,
        ChartDifMinPriceComponent,
        TableDifMinPriceComponent,
        TableDifMinPriceCompetitorsComponent,
        ChartDifMaxPriceComponent,
        TableDifMaxPriceComponent,
        TableDifMaxPriceCompetitorsComponent,
        SelectVariablePxOptComponent,
        SelectVariableOnePageComponent,
        SelectTopBottomVariableOnePageComponent,
        SelectPeriodOnePageComponent,
        SelectConcoDiscountComponent,
        SelectPurchaseSourceComponent,
        ChartSalesSeasonallyComponent,
        SelectClusterComponent,
        SelectCluster2Component,
        SelectCluster3Component,
        SelectCluster4Component,
        MoneyParserPipe,
        SelectRankingComponent,
        SelectPercentageComponent,
        PercentageParserPipe,
        FormatLargeNumberPipe,
        RoundDecimalsPipe,
        ChartSalesDistributionComponent,
        HeatMapByMonthComponent,
        ScorecardByProductComponent,
        HeatMapShiftComponent,
        ChartSalesShiftComponent,
        ChartSalesWeekComponent,
        ChartSalesHourComponent,
        SelectStationZoneComponent,
        SelectTypeOnePageComponent,
        SelectZoneOnePageComponent,
        SelectDiscountHistoricalTadReportComponent,
        RemoveSadecvPipe
    ],
    exports: [
        AngularDraggableModule,
        ChartComparativeDashboardComponent,
        ChartDifPriceQuotientComponent,
        ChartEarningComponent,
        ChartIndexSaleComponent,
        ChartMarginVolumenComponent,
        ChartQuotientSaleComponent,
        ChartQuotientSaleLevelComponent,
        ChartSaleBuyComponent,
        ChartSalesDistributionComponent,
        ChartSalesHourComponent,
        ChartSalePpcComponent,
        ChartSalesPeriodComponent,
        ChartSalesQuartilComponent,
        ChartSalesShiftComponent,
        ChartSalesWeekComponent,
        ChartTarBuyComponent,
        ChartTicketsComponent,
        ChartTransactionsComponent,
        CreateGroupsComponent,
        HeaderComponent,
        HeatMapByMonthComponent,
        HeatMapShiftComponent,
        LastUpdateComponent,
        MultiselectClustersComponent,
        MultiselectFlagComponent,
        MultiselectProductComponent,
        MultiselectReasonComponent,
        MultiselectScheduleComponent,
        MultiselectTiersComponent,
        MultiselectVariablesComponent,
        MultiselectZoneComponent,
        PriceRulesComponent,
        QuotientChartComponent,
        SalesChartComponent,
        ScorecardByProductComponent,
        SelectBrandsComponent,
        SelectBudgetComponent,
        SelectCentralTendencyComponent,
        SelectDashboardDaysComponent,
        SelectDashboardPeriodComponent,
        SelectDashboardZoneComponent,
        SelectDaysActualComponent,
        SelectDaysChangeComponent,
        SelectDaysComponent,
        SelectDaysEvolutionComponent,
        SelectDaysOptimumPriceComponent,
        SelectDaysPxOptimumComponent,
        SelectDaysYesterdayComponent,
        SelectEarningsComponent,
        SelectEntityComponent,
        SelectGroupComponent,
        SelectLevelDiscountComponent,
        SelectMarginComponent,
        SelectMarginTypeComponent,
        SelectMarketComponent,
        SelectMarketTypeComponent,
        SelectMarketZoneComponent,
        SelectMonthBudgetComponent,
        SelectMonthComponent,
        SelectMonthHeatMapComponent,
        SelectMonthsComponent,
        SelectPeriodBudgetComponent,
        SelectPeriodComparativeComponent,
        SelectPeriodComponent,
        SelectPeriodEarningComponent,
        SelectPeriodIndexComponent,
        SelectPhotoStatusComponent,
        SelectPillarComponent,
        SelectProductAllComponent,
        SelectProductComponent,
        SelectProductGasolineComponent,
        SelectProductGasolinesComponent,
        SelectProductTotalComponent,
        SelectProductTypeComponent,
        SelectProductTypeSalesComponent,
        SelectReasonComponent,
        SelectRegionEvolutionComponent,
        SelectScheduleComponent,
        SelectStationComponent,
        SelectStationZoneComponent,
        SelectTypeOnePageComponent,
        SelectStatisticsBudgetComponent,
        SelectStatisticsComponent,
        SelectStatisticsEvolutionComponent,
        SelectStatisticsSalesComponent,
        SelectStatisticsTradeTrackingComponent,
        SelectTopComponent,
        SelectTownComponent,
        SelectTypeNationalComponent,
        SelectVariableCrossReportComponent,
        SelectVariablesDashboardComponent,
        SelectVariableSecondCrossReportComponent,
        SelectVariablesIntelligenceComponent,
        SelectVariablesIntelligenceEvolutionComponent,
        SelectVariablesIntelligenceMarketComponent,
        SelectVariablesPriceIndexComponent,
        SelectVariablesSecondIntelligenceEvolutionComponent,
        SelectWeekBudgetComponent,
        SelectWeekComponent,
        SelectWeeksComponent,
        SelectYearBudgetComponent,
        SellingPriceChartComponent,
        SidebarComponent,
        TableAlertsComponent,
        TableComparativeDashboardComponent,
        TableLoaderComponent,
        TableSalesQuartilComponent,
        TrafficChartComponent,
        SortDirective,
        ChartSalesQuartilComparativeComponent,
        TableSalesQuartilComparativeComponent,
        ChartDifMinPriceComponent,
        TableDifMinPriceComponent,
        TableDifMinPriceCompetitorsComponent,
        ChartDifMaxPriceComponent,
        TableDifMaxPriceComponent,
        TableDifMaxPriceCompetitorsComponent,
        SelectVariablePxOptComponent,
        SelectVariableOnePageComponent,
        SelectTopBottomVariableOnePageComponent,
        SelectPeriodOnePageComponent,
        SelectConcoDiscountComponent,
        SelectPurchaseSourceComponent,
        ChartSalesSeasonallyComponent,
        SelectClusterComponent,
        SelectCluster2Component,
        SelectCluster3Component,
        SelectCluster4Component,
        MoneyParserPipe,
        SelectRankingComponent,
        SelectPercentageComponent,
        PercentageParserPipe,
        FormatLargeNumberPipe,
        RoundDecimalsPipe,
        SelectZoneOnePageComponent,
        SelectDiscountHistoricalTadReportComponent,
        RemoveSadecvPipe

    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CommonModule,
        AngularDraggableModule,
        NgxSelectModule,
        NgMultiSelectDropDownModule,
        ChartsModule,
        NgxDatatableModule,
        HighchartsChartModule,
    ],
    providers: [FormatLargeNumberPipe],    
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
    constructor() {
        // Establece las traducciones globales de Highcharts
        Highcharts.setOptions({
            lang: {
                months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                contextButtonTitle: 'Exportar gráfico',
                downloadPNG: 'Descargar como PNG',
                downloadJPEG: 'Descargar como JPEG',
                downloadPDF: 'Descargar como PDF',
                downloadSVG: 'Descargar como SVG',
                downloadCSV: 'Descargar como CSV',
                downloadXLS: 'Descargar como Excel',
                viewFullscreen: 'Ver en Pantalla Completa',
                viewData: 'Ver en Tabla',
                hideData: 'Esconder la Tabla',
                printChart: 'Imprimir la Gráfica',
                resetZoom: 'Restablecer zoom',
                resetZoomTitle: 'Restablecer zoom al nivel 1:1',
                exitFullscreen: 'Salir Pantalla Completa',
                thousandsSep: ",",
                decimalPoint: '.'
            }
        });
    }
}
