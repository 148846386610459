import { Component, OnInit } from '@angular/core';
import { ClickService } from 'src/app/services/click.service';
import { StationService } from 'src/app/modules/station/services/station.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  dios: boolean = false;
  id: any;
  idGroup: any;
  idStation: any;
  rol: any;
  showText = false;

  constructor(
    public clickService: ClickService,
    public stationService: StationService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '60afa99655f4ee369d1df409' : localStorage.getItem('group');
   }

  ngOnInit() {
    this.id = localStorage.getItem('id');
    this.idStation = localStorage.getItem('idStation');
    if (this.id == null) {
      this.getProfile();
    }
  }

  getProfile() {
    this.dios = this.rol == 'Dios' ? true : false;
    this.stationService.getProfile(this.dios, this.idGroup).subscribe(
      data => {
        this.id = data._id;
      }
    );
  }

  openSidebar() {
    this.showText = true;
  }

  closeSidebar() {
    this.showText = false;
  }

  clickPage(page: any) {
    this.clickService.clickPage(this.id, this.idStation, page, 'GasPremia')
      .pipe()
      .subscribe(
        (data) => {
          // console.log(data);
        });
  }

}
