import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-market',
  templateUrl: './select-market.component.html',
  styleUrls: ['./select-market.component.scss']
})
export class SelectMarketComponent implements OnInit, OnDestroy {

  @Output() groupChange = new EventEmitter<any>();

  public items: any[] = [{ value: '', name: 'Zonas' }];
  public idGroup: any;
  public zone: any = [];
  public ngxDisabled = true;
  id;
  idGroup$: Subscription;
  sharedZone$: Subscription;
  showRendichicas = false;
  showCombuexpress = false;
  showBurgos = false;
  showEnerser = false;
  showMobico = false;
  showMegagas = false;
  showRuta = false;
  rol;

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    this.rol = localStorage.getItem('rol');
    this.id = localStorage.getItem('id');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '60afa99655f4ee369d1df409' : localStorage.getItem('group');
    this.showRendichicas = this.idGroup === '615efe30b318ce63b8971986' ? true : false;
    this.showCombuexpress = this.idGroup === '63fd4c8f1a5bfd390727f392' ? true : false;
    this.showMobico = this.idGroup === '6697df2bfb7789085d7d82b4' ? true : false;
    this.showMegagas = this.idGroup === '666cb6528c251f2c5d6204b5' ? true : false;
    this.showRuta = this.idGroup === '668eaeceec578636ce2a789a' ? true : false;
    this.showEnerser = this.idGroup === '643f507358715227618de808' || this.idGroup === '649216f6edc3bad5497001f9' ? true : false;
    this.showBurgos = this.idGroup === '652089ad94a59a35e91437da' || this.idGroup === '65c2849672bf4238ffccbe4c' ? true : false;
    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        this.showRendichicas = this.idGroup === '615efe30b318ce63b8971986' ? true : false;
        this.showCombuexpress = this.idGroup === '63fd4c8f1a5bfd390727f392' ? true : false;
        this.showRuta = this.idGroup === '668eaeceec578636ce2a789a' ? true : false;
        this.showMobico = this.idGroup === '6697df2bfb7789085d7d82b4' ? true : false;
        this.showMegagas = this.idGroup === '666cb6528c251f2c5d6204b5' ? true : false;
        this.showEnerser = this.idGroup === '643f507358715227618de808' || this.idGroup === '649216f6edc3bad5497001f9' ? true : false;
        this.showBurgos = this.idGroup === '652089ad94a59a35e91437da' || this.idGroup === '65c2849672bf4238ffccbe4c' ? true : false;
      }
    });

    this.sharedZone$ = sharedService.sharedZone.subscribe((zone: any) => {
      if (this.showRendichicas && zone === null) {
        this.zone = '6178671b46818a57510e9218';
      } else if (this.showCombuexpress && zone === null && this.id !== '63fe38d41a5bfd390736314c' && this.id !== '63fe38d41a5bfd390736314e' && this.id !== '63fe38d41a5bfd3907363150') {
        this.zone = '63fea6de2df0863b8e7da412';
      } else if (this.showEnerser && zone === null && this.idGroup === '643f507358715227618de808') {
        this.zone = '651b4288b13c717f2fcd775b';
      } else if (this.showEnerser && zone === null && this.idGroup === '649216f6edc3bad5497001f9') {
        this.zone = '64d2d15f8bd81e4d428e7797';
      } else if (this.showBurgos && zone === null) {
        this.zone = '6525737f03da8cde44820771';
      } else if (this.showMobico && zone === null && this.id !== '672541829fdb7d79c82a6e86') {
        this.zone = '6697e639fb7789085d7f7012';
      } else if (this.showMegagas && zone === null) {
        this.zone = '666cb9c38c251f2c5d622283';
      } else if (this.showRuta && zone === null) {
        this.zone = '668eb65057ab5f397ef9f2df';
      } else if ((!this.showRendichicas && zone === null) || (!this.showCombuexpress && zone === null) || (!this.showCombuexpress && zone === null)) {
        this.zone = '';
      } else {
        this.zone = zone;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    this.getZones();
  }

  ngOnDestroy() {
    this.idGroup$.unsubscribe();
    this.sharedZone$.unsubscribe();
  }

  getZones() {
    this.gasprecioService.getZones(this.idGroup).subscribe((data: any) => {
      const zones: any = data;
      zones.forEach((element: any) => {
        if (element.zone_id !== '6178671b46818a57510e921b' && element.zone_id !== '6178671b46818a57510e921a') {
          if (element.name === 'Ciudad De México') {
            element.name = 'CDMX';
          }

          if (element.name === 'Estado De México' || element.name === 'ESTADO DE MEXICO') {
            element.name = 'Edo. Méx.';
          }

          if (element.name === 'Veracruz De Ignacio De La Llave') {
            element.name = 'Veracruz';
          }

          if (element.name === 'Baja California Sur') {
            element.name = 'B.C.S';
          }

          if (element.name === 'Coahuila De Zaragoza') {
            element.name = 'Coahuila';
          }

          if (element.name === 'Michoacán De Ocampo') {
            element.name = 'Michoacán';
          }

          if (element.name === 'San Miguel de Allende') {
            element.name = 'San Miguel de All';
          }
          this.items.push({ name: this.gasprecioService.capitalizeFirstLetter(element['name']), value: element['zone_id'] });
        }
      });
      this.ngxDisabled = false;
    });
  }

  changeMarket(event: any) {
    this.zone = event;
    this.sharedService.nextZone = this.zone;
    this.groupChange.emit(this.zone);
  }
}
