import { Component, Input, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from "src/app/modules/gasprecio/services/shared.service";

@Component({
  selector: 'app-select-zone-one-page',
  templateUrl: './select-zone-one-page.component.html',
  styleUrls: ['./select-zone-one-page.component.scss']
})
export class SelectZoneOnePageComponent implements OnDestroy {
  @Input() ngxDisabled: boolean = true;
  public idGroup: any;
  idGroup$: Subscription;
  public items: any[] = [{ value: '', name: 'Zonas' }];
  public zone: any = [];
  sharedZone$: Subscription;
  rol;

  constructor(
    private sharedService: SharedService,
    private gasprecioService: GasprecioService,
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '60afa99655f4ee369d1df409' : localStorage.getItem('group');
    this.sharedZone$ = sharedService.sharedZoneOnePage.subscribe(
      (sharedZone: string) => {
        if (this.zone !== sharedZone) {
          this.zone = sharedZone;
        }
      }
    );
  }

  ngOnInit(): void {
    this.getZones();
  }

  ngOnDestroy() {
    this.sharedZone$.unsubscribe();
  }

  getZones() {
    this.gasprecioService.getZones(this.idGroup).subscribe((data: any) => {
      const zones: any = data;
      zones.forEach((element: any) => {
        if (element.zone_id !== '6178671b46818a57510e921b' && element.zone_id !== '6178671b46818a57510e921a') {
          if (element.name === 'Ciudad De México') {
            element.name = 'CDMX';
          }

          if (element.name === 'Estado De México' || element.name === 'ESTADO DE MEXICO') {
            element.name = 'Edo. Méx.';
          }

          if (element.name === 'Veracruz De Ignacio De La Llave') {
            element.name = 'Veracruz';
          }

          if (element.name === 'Baja California Sur') {
            element.name = 'B.C.S';
          }

          if (element.name === 'Coahuila De Zaragoza') {
            element.name = 'Coahuila';
          }

          if (element.name === 'Michoacán De Ocampo') {
            element.name = 'Michoacán';
          }

          if (element.name === 'San Miguel de Allende') {
            element.name = 'San Miguel de All';
          }
          this.items.push({ name: this.gasprecioService.capitalizeFirstLetter(element['name']), value: element['zone_id'] });
        }
      });
      this.ngxDisabled = false;
    });
  }

  changeZone(event: any) {
    this.zone = event;
    this.sharedService.nextZoneOnePage = this.zone;
  }
}

