import Swal from 'sweetalert2';
import { AuthService } from '../../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { environment } from '../../../../environments/environments';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class PreciosService {

    // private endpoint1 = environment.developmentUrl + '/v1';
    private endpoint1 = environment.apiUrl + '/v1';
    public token;

    constructor(
        private http: HttpClient,
        public authenticationService: AuthService,
        public router: Router,
    ) {
        const token = this.authenticationService.getToken();
        this.token = token ? token : localStorage.getItem('tokenPrices');
    }

    // Obtener competidores de CorpoGas

    getMonitor(idStation: string) {
        if (!idStation || typeof idStation !== 'string' || idStation.trim().length === 0) {
            throw new Error('idStation debe ser una cadena no vacía');
        }

        return this.http.get(`${this.endpoint1}/prices/monitor?station=${idStation}`, { headers: { 'Authorization': `Bearer ${this.token}` }}).pipe(
            map((x: any) => x),
            shareReplay(1),
            catchError(this.processError)
        );
    }

    uploadImage(image, name, type, size, dateTime, ImageHeight, ImageWidth, PixelXDimension, PixelYDimension, creId) {

        const formData = new FormData();
        formData.append('image', image);
        formData.append('name', name);
        formData.append('type', type);
        formData.append('size', size);
        formData.append('dateTime', dateTime);
        formData.append('ImageHeight', ImageHeight);
        formData.append('ImageWidth', ImageWidth);
        formData.append('PixelXDimension', PixelXDimension);
        formData.append('PixelYDimension', PixelYDimension);
        formData.append('cre_id', creId);

        return this.http.post<any>(`${this.endpoint1}/prices/images`, formData, { headers: { 'Authorization': `Bearer ${this.token}` } })
            .pipe(map(data => {
                return data;
            })
            );
    }

    removeSADECV(str: string) {
        if (typeof str !== 'string') {
            return '';
        }
        str = this.capitalizeFirstLetter(str.toLowerCase());
        str = str.replace(/[\s,]+/g, ' ');
        str = str.replace(/(,? )?[Ss]\.?\s?[Aa]\.?\s?[Pp]\.?\s?[Ii]\.?\s? [Dd][Ee] [Cc]\.?\s?[Vv]\.?\s?/g, '');
        str = str.replace(/(,? )?[Ss]\.?\s? [Dd][Ee] [Rr]\.?\s?[Ll]\.?\s? [Dd][Ee] [Cc]\.?\s?[Vv]\.?\s?/g, '');
        str = str.replace(/(,? )?[Ss]\.?[Dd][Ee][Rr]\.?[Ll]\.?[Dd][Ee][Cc][Vv]?/g, '');
        str = str.replace(/(,? )?[Ss]\.?\s?[Aa]\.?\s? [Dd][Ee] [Cc]\.?\s?[Vv]\.?\s?/g, '');
        str = str.replace(/(,? )?[Ss]\.?\s?[Cc]\.?\s? [Dd][Ee] [Rr]\.?\s?[Ss]\.?\s?/g, '');
        str = str.replace(/(,? )?[Ss]\.?\s? [Dd][Ee] [Rr]\.?\s?[Ll]\.?\s?/g, '');
        str = str.replace(/(,? )?[Ss]\.?\s?[Aa]\.?\s? [Cc]\.?\s?[Vv]\.?\s?/g, '');
        str = str.replace(/(,? )?[Ss]\.\s?[Aa]\.?/g, '');
        return str;
    }

    capitalizeFirstLetter(text: any): string {
        if (typeof text !== 'string') {
            return '';
        }
        return text
            .toLowerCase()
            .split(' ')
            .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    capitalizeOnlyFirstLetter(text: any): string {
        if (typeof text !== 'string') {
            return '';
        }
        return text
            .split(' ')
            .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    displayMessage(messageType: 'success' | 'error' | 'warning', title: string, text?: string, options?: { allowEscapeKey?: boolean; allowEnterKey?: boolean; allowOutsideClick?: boolean; preConfirm?: () => void; }) {
        Swal.fire({
            title: title,
            text: text,
            icon: messageType,
            confirmButtonColor: '#00e07b',
            reverseButtons: true,
            confirmButtonText: 'Aceptar',
            ...options
        });
    }

    displayMessageHtml(title: string, html: any,) {
        Swal.fire({
            title: title,
            html: html,
            background: '#1e2228',
            confirmButtonColor: '#b82f26',
            confirmButtonText: 'Aceptar'
        });
    }

    showLoader(title: string, text: string) {
        Swal.fire({
            title: title,
            text: text,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
    }

    formatDistance(distance: number | string = 0): string {
        const distanceInMeters: any = typeof distance === 'string' && !distance.includes('+') ? parseFloat(distance) : distance;
        const distanceInKilometers = distanceInMeters / 1000;
        let distanceFormatted = distanceInKilometers >= 1 ? distanceInKilometers.toFixed(2) + ' km' : Math.round(distanceInMeters) + ' mts';
        if (isNaN(Number(distanceFormatted.split(' ')[0]))) {
            distanceFormatted = '+15 km';
        }
        return distanceFormatted;
    }

    processError(err: any) {
        let message = '';
        if (err.error instanceof ErrorEvent) {
            message = err.error.message;
        } else {
            message = `Error Code: ${err.status}\nMessage: ${err.message}`;
        }
        return throwError(() => {
            message;
        });
    }
}