import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { SharedService } from "src/app/modules/gasprecio/services/shared.service";

@Component({
  selector: "app-select-variable-one-page",
  templateUrl: "./select-variable-one-page.component.html",
  styleUrls: ["./select-variable-one-page.component.scss"],
})
export class SelectVariableOnePageComponent implements OnDestroy {
  public items: any[] = [
    // { value: "", name: "Indicador" },
    { value: "volume", name: "Volumen Venta lts" },
    { value: "earning", name: "Utilidad Bruta" },
  ];

  public variableSelected: any = "volume";
  variableSelected$: Subscription;
  constructor(private sharedService: SharedService) {
    this.variableSelected$ = sharedService.sharedVariableOnePage.subscribe(
      (variableSelected: string) => {
        if (this.variableSelected !== variableSelected) {
          this.variableSelected = variableSelected;
        }
      }
    );
  }

  ngOnDestroy() {
    this.variableSelected$.unsubscribe();
  }

  changeVariable(event: any) {
    this.variableSelected = event;
    this.sharedService.nextVariableOnePage = this.variableSelected;
  }
}
