import { Component, Input, OnDestroy } from "@angular/core";
import { combineLatest, Subject, Subscription, takeUntil } from "rxjs";
import { GasprecioService } from "../../../gasprecio/services/gasprecio.service";
import { SharedService } from "../../../gasprecio/services/shared.service";
import {
  Router,
  Event as NavigationEvent,
  NavigationEnd,
} from "@angular/router";
import Highcharts from "highcharts";
import { GroupIds } from "src/app/modules/gasprecio/enums/groups-ids.enum";
import moment from "moment";

@Component({
  selector: "app-chart-sales-week",
  templateUrl: "./chart-sales-week.component.html",
  styleUrls: ["./chart-sales-week.component.scss"],
})
export class ChartSalesWeekComponent implements OnDestroy {

  @Input() variable = "transactions";
  @Input() type = "Autos";
  @Input() station: any = false;
  chartOptions: Highcharts.Options;
  data = [];
  date = moment().month(10).date(1).format('YYYY-MM-DD');
  days = [];
  event$!: Subscription;
  Highcharts = Highcharts;
  idGroup: GroupIds | string = GroupIds.Pa;
  loading = false;
  private destroy$ = new Subject<void>();
  productType = "regular";
  reqst = 0;
  rol;
  route;
  stationId: any;
  period;
  zone;
  changeZone = false;

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router
  ) {
    this.rol = localStorage.getItem("rol");
    this.idGroup = this.rol === "Dios" ? localStorage.getItem("group") || GroupIds.Pa : localStorage.getItem("group");
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });

    combineLatest([
      this.sharedService.sharedGroup,
      this.sharedService.sharedZone,
      this.sharedService.sharedStationSelected,
      this.sharedService.sharedSelectedMonth,
      this.sharedService.sharedProductTypeTotal,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([idGroup, zone, stationId, period, productType]) => {
        if (idGroup !== this.idGroup) {
          this.idGroup = idGroup;
        }

        if (this.zone !== zone) {
          if (this.zone !== undefined) {
            this.changeZone = true;
          }
          this.zone = zone;
        }

        if (this.stationId !== stationId) {
          this.stationId = stationId;
        }

        if (period !== this.date) {
          this.date = period;
        }

        if (this.productType !== productType) {
          this.productType = productType;
        }

        if (!this.loading) {
          this.getHeatMapDayWeek();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getHeatMapDayWeek() {
    this.loading = true;
    this.reqst = 0;
    this.data = [];
    this.days = [];
    setTimeout(() => {
      const url = this.station ? this.gasprecioService.getHeatMapDayWeek(this.idGroup, this.stationId, this.variable, this.productType, this.date, true) : this.gasprecioService.getHeatMapDayWeek(this.idGroup, this.stationId, this.variable, this.productType, this.date);
      url.subscribe({
        next: (data: any) => {
          data.map((x: any) => {
            this.data.push(+x['value']?.toFixed(0));
            this.days.push(
              this.gasprecioService.capitalizeFirstLetter(x["day"])
            );
          });
          this.setData();
        },
        complete: () => {
          this.reqst = 1;
        },
        error: () => {
          this.loading = false;
          this.reqst = 2;
        },
      });
    }, 400);
  }

  setData() {
    const min = this.gasprecioService.getArrRange(this.data, 'min', 'array');
    const max = this.gasprecioService.getArrRange(this.data, 'max', 'array');
    const barColors = [];
    this.data.map((x: any) => {
      barColors.push(this.gasprecioService.changeColor(x, min, max, true))
    });  

    this.chartOptions = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "column",
        spacingTop: 10,
        spacingRight: 14,
        spacingBottom: 10,
        spacingLeft: 0
      },
      title: {
        text: `${this.type} por Día de la Semana`,
        style: {
          fontSize: '0'
        }
      },
      exporting: {
        filename:  `${this.type} por Día de la Semana`,
        sourceWidth: 1200,
        sourceHeight: 800,
      },
      navigation: {
        buttonOptions: {
          align: 'right',
          y: -10
        }
      },
      xAxis: {
        categories: this.days,
        gridLineWidth: 1,
        gridLineDashStyle: "LongDash",
        title: {
          text: "Días de la Semana",
          style: {
            fontSize: "0",
          },
        },
        labels: {
          style: {
            fontSize: "11px",
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Volumen de Venta",
          style: {
            fontSize: "0",
          },
        },
        labels: {
          format: this.type === "Autos" ? "{value:,.0f}" : "{value:,.0f} lts",
          style: {
            fontSize: "11px",
          },
        },
        tickAmount: 10,
        gridLineDashStyle: "LongDash",
      },
      tooltip: {
        pointFormat:
          this.type === "Autos"
            ? `${this.type}: <b>{point.y}</b>`
            : `${this.type}: <b>{point.y} lts</b>`,
      },
      legend: {
        layout: "horizontal",
        align: "center",
        verticalAlign: "top",
        itemDistance: 10,
        y: 1,
        padding: 1,
        itemMarginTop: 1,
        itemMarginBottom: 1,
        itemHoverStyle: {
          color: "#00E07B",
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          colorByPoint: true,
        },
      },
      series: [
        {
          type: "column",
          name: this.type,
          data: this.data, // Valores de ejemplo para cada día
          colorByPoint: true,
          colors: barColors 
        },
      ],
    };

    setTimeout(() => {
      this.loading = false;
    }, 100);
  }
}
