import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { SharedService } from "src/app/modules/gasprecio/services/shared.service";

@Component({
  selector: 'app-select-period-one-page',
  templateUrl: './select-period-one-page.component.html',
  styleUrls: ['./select-period-one-page.component.scss']
})
export class SelectPeriodOnePageComponent implements OnDestroy {
  public items: any[] = [
    // { value: "", name: "Periodo" },
    { value: "week", name: "Semanal" },
    { value: "month", name: "Mensual" },
  ];

  public periodSelected: any = "week";
  periodSelected$: Subscription;
  constructor(private sharedService: SharedService) {
    this.periodSelected$ = sharedService.sharedPeriodOnePage.subscribe(
      (variableSelected: string) => {
        if (this.periodSelected !== variableSelected) {
          this.periodSelected = variableSelected;
        }
      }
    );
  }

  ngOnDestroy() {
    this.periodSelected$.unsubscribe();
  }

  changeVariable(event: any) {
    this.periodSelected = event;
    this.sharedService.nextPeriodOnePage = this.periodSelected;
  }
}
