import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { SharedService } from "src/app/modules/gasprecio/services/shared.service";

@Component({
  selector: 'app-select-top-bottom-variable-one-page',
  templateUrl: './select-top-bottom-variable-one-page.component.html',
  styleUrls: ['./select-top-bottom-variable-one-page.component.scss']
})
export class SelectTopBottomVariableOnePageComponent implements OnDestroy {
  public items: any[] = [
    { value: "", name: "Ordenar Por" },
    { value: "period_1", name: "Sem -2" },
    { value: "period_2", name: "Sem -1" },
    { value: "difference", name: "Dif" },
    { value: "pct_difference", name: "Dif %" },
  ];

  public TopBottomVariableSelected: any = "";
  TopBottomVariableSelected$: Subscription;
  constructor(private sharedService: SharedService) {
    this.TopBottomVariableSelected$ = sharedService.sharedTopBottomVariableOnePage.subscribe(
      (TopBottomVariableSelected: string) => {
        if (this.TopBottomVariableSelected !== TopBottomVariableSelected) {
          this.TopBottomVariableSelected = TopBottomVariableSelected;
        }
      }
    );
  }

  ngOnDestroy() {
    this.TopBottomVariableSelected$.unsubscribe();
  }

  changeVariable(event: any) {
    this.TopBottomVariableSelected = event;
    this.sharedService.nextTopBottomVariableOnePage = this.TopBottomVariableSelected;
  }

}
